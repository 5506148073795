/* eslint-disable  */
import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Checkbox, Grid, IconButton, Typography } from '@mui/material';
import { useConfirm } from 'plugins/confirm';
import React from 'react';

const ColorLegend = styled('div')<{ color: string }>(props => ({
  width: '15px',
  height: '15px',
  backgroundColor: props.color,
  borderColor: 'black',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '50%',
  marginRight: '10px',
  marginTop: '13px'
}));

interface IROIItemProps {
  title: string;
  checked: boolean;
  color: string;
  hidden: boolean;
  onToggle: () => void;
  onCheck: () => void;
  onEdit: () => void;
  onDelete?: () => void;
  hasAction?: boolean;
}

export const ROIItem: React.FC<IROIItemProps> = ({
  title,
  checked,
  hidden,
  color,
  onToggle,
  onCheck,
  onEdit,
  onDelete,
  hasAction = true
}) => {
  const confirm = useConfirm();

  const handleDeleteClick = () => {
    confirm({
      title: 'Delete ROI item',
      content: 'Are you sure you want to delete this ROI item?'
    })
      .then(() => {
        onDelete && onDelete();
      })
      .catch(() => {
        // Do nothing
      });
  };

  return (
    <Grid container spacing={0} width={'100%'}>
      <Grid item xs={2}>
        <Checkbox
          checked={checked}
          onChange={onCheck}
          style={{ marginLeft: '-10px' }}
        />
      </Grid>
      <Grid
        item
        xs={5}
        style={{
          paddingTop: '7px'
        }}
      >
        <Typography variant="caption" fontWeight={'bold'}>
          {title}
        </Typography>
      </Grid>
      {hasAction && (
        <Grid
          item
          xs={5}
          display="flex"
          flexDirection={'row'}
          justifyContent={'right'}
        >
          <ColorLegend color={color} />
          <IconButton disableRipple>
            {hidden ? (
              <VisibilityOffIcon
                fontSize="small"
                color="primary"
                onClick={onToggle}
              />
            ) : (
              <RemoveRedEyeIcon
                fontSize="small"
                color="primary"
                onClick={onToggle}
              />
            )}
          </IconButton>
          <IconButton disableRipple>
            <EditIcon fontSize="small" color="primary" onClick={onEdit} />
          </IconButton>
          <IconButton disableRipple>
            <DeleteIcon
              fontSize="small"
              color="primary"
              onClick={handleDeleteClick}
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default ROIItem;
