/* eslint-disable  */
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Grid,
  IconButton,
  Slider,
  Stack,
  TextField,
  styled
} from '@mui/material';
import { Button, Typography } from 'components';
import React from 'react';

import { secondsToHms } from 'utils/date';

interface AddRoiActionProps {
  duration: number;
  playing: boolean;
  saveDisabled: boolean;
  onPlay: () => void;
  onPause: () => void;
  seek: (time: number) => void;
  onSave: () => void;
  onCancel: () => void;
  onReset: () => void;
  currentTime: number;
}

const StyledInput = styled(TextField)({
  width: '100px'
});

const AddRoiAction: React.FC<AddRoiActionProps> = ({
  playing,
  duration,
  onPlay,
  saveDisabled,
  onPause,
  seek,
  onSave,
  onCancel,
  onReset,
  currentTime: current
}) => {
  const [cursor, setCursor] = React.useState<'start' | 'end'>('start');
  const [currentTime, setCurrentTime] = React.useState<number>(0);

  const [end, setEnd] = React.useState<number>(duration - 1);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const val = newValue as number;
    setCurrentTime(val);
    seek(val);
  };

  React.useEffect(() => {
    setCurrentTime(current);
  }, [current]);

  const valuetext = (value: number) => {
    return secondsToHms(value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={2} direction={'row'} paddingTop={2} paddingRight={3}>
            <IconButton
              aria-label="play"
              onClick={() => (playing ? onPause() : onPlay())}
              disableRipple
            >
              {playing ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <Slider
              style={{ marginTop: '5px' }}
              getAriaLabel={() => 'Temperature range'}
              value={currentTime}
              min={0}
              max={duration}
              onChange={handleChange}
              valueLabelDisplay="auto"
              valueLabelFormat={valuetext}
              disableSwap
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack spacing={2} direction={'row'} paddingLeft={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body1">Start Time</Typography>
                  <StyledInput
                    value={secondsToHms(currentTime)}
                    onChange={() => {}}
                    disabled
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                <Button variant="outlined" version="light" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="contained" version="light" onClick={onReset}>
                  Reset
                </Button>
                <Button
                  variant="contained"
                  version="light"
                  onClick={onSave}
                  disabled={saveDisabled}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default AddRoiAction;
