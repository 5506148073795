/* eslint-disable  */
import { Checkbox, Grid, IconButton, Typography, styled } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useConfirm } from 'plugins/confirm';
// import styled from '@emotion/styled';

const ColorLegend = styled('div')<{ color: string }>(props => ({
  width: '15px',
  height: '15px',
  backgroundColor: props.color,
  borderColor: 'black',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '50%',
  marginRight: '10px',
  marginTop: '13px'
}));

interface IROIItemProps {
  title: string;
  checked: boolean;
  images: string;

  onCheck: () => void;

  onDelete?: () => void;
  hasAction?: boolean;
}
const ImageContainer = styled('div')(({ theme }) => ({
  height: 30,
  width: 30,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[200],
  float: 'left',
  marginRight: 10,
  marginBottom: 10,
  border: `1px solid ${theme.palette.grey[300]}`,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 5
  }
}));
export const BoundingBoxItem: React.FC<IROIItemProps> = ({
  title,
  checked,
  images,

  onCheck,

  onDelete,
  hasAction = true
}) => {
  const confirm = useConfirm();

  const handleDeleteClick = () => {
    confirm({
      title: 'Delete Cropped Item',
      content: 'Are you sure you want to delete this cropped item?'
    })
      .then(() => {
        onDelete && onDelete();
      })
      .catch(() => {
        // Do nothing
      });
  };

  return (
    <Grid container spacing={0} width={'100%'}>
      <Grid item xs={2}>
        <Checkbox
          checked={checked}
          onChange={onCheck}
          style={{ marginLeft: '-10px' }}
        />
      </Grid>
      <Grid
        item
        xs={5}
        display="flex"
        flexDirection={'row'}
        style={{
          paddingTop: '13px'
        }}
      >
        <Typography variant="caption" fontWeight={'bold'} mt={1}>
          {title}
        </Typography>
      </Grid>
      {hasAction && (
        <Grid
          item
          xs={5}
          display="flex"
          flexDirection={'row'}
          justifyContent={'right'}
        >
          <ImageContainer key={title} style={{ marginTop: '5px' }}>
            <img src={images || ''} draggable={false} />
          </ImageContainer>

          <IconButton disableRipple>
            {/* <EditIcon fontSize="small" color="primary" onClick={onEdit} /> */}
          </IconButton>
          <IconButton disableRipple>
            <DeleteIcon
              fontSize="small"
              color="primary"
              onClick={handleDeleteClick}
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default BoundingBoxItem;
