/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { SelectField } from 'components/Form';
import { Formik } from 'formik';
import LiveDashboard from './LiveDashboard';
import DailyDashboard from './DailyDashboard';
import HistoricalDashboard from './HistoricalDashboard';
import { useParams } from 'react-router-dom';
import { useGetDashboardDetails, useGetReportConfiguration } from 'api/sdk';
import { liveDashboardLayout } from './layout';
import GridSkeliton from 'components/Skeleton/GridSkeliton';
import { useSnack } from 'plugins/snack';
import { DashBoardConfigSchemaOutput } from 'schemas';
import errorImage from '../../assets/images/504_error.svg';

const DashboardConfiguration: React.FC = () => {
  const [getScreen, setScreen] = useState<string>('live');
  const { id } = useParams<{ id: string }>();
  const [getWidgetData, setWidgetData] = useState<
    DashBoardConfigSchemaOutput[]
  >([]);
  const [reportsData, setReportsData] = useState<string[]>([]);
  const snack = useSnack();

  function renderDashboard(data: string) {
    if (!reportsData || typeof reportsData === undefined) {
      return (
        <div style={{ height: '60vh', width: '100%' }}>
          <img src={errorImage} style={{ height: '100%', width: '100%' }} />
        </div>
      );
    } else if (data === 'live') {
      return (
        <LiveDashboard
          liveData={reportsData}
          getWidgetData={getWidgetData}
          getScreen={getScreen}
        />
      );
    } else if (data === 'daily') {
      return (
        <DailyDashboard
          liveData={reportsData}
          getWidgetData={getWidgetData}
          getScreen={getScreen}
        />
      );
    } else if (data === 'historical') {
      return (
        <HistoricalDashboard
          liveData={reportsData}
          getWidgetData={getWidgetData}
          getScreen={getScreen}
        />
      );
    }
  }

  // API Hook to get the positions of dashboard config at live, daily and history
  const { isLoading, isError, error, isSuccess, refetch } =
    useGetDashboardDetails(
      id || '', // Ensure 'id' is a string or provide a default value
      {
        query: {
          enabled: !!id // Ensure 'id' is truthy to enable the query
        }
      }
    );

  // API Hook to get the positions of dashboard config at live, daily and history
  const {
    isError: isErrorReport,
    error: reportError,
    isSuccess: isSuccessReport,
    refetch: reportRefetch
  } = useGetReportConfiguration(
    id || '', // Ensure 'id' is a string or provide a default value
    {
      query: {
        enabled: !!id // Ensure 'id' is truthy to enable the query
      }
    }
  );
  // function to match and populate reports data
  function matchReportsData(data: any) {
    let matchedData = [];
    if (getScreen === 'live') {
      matchedData = data.filter(
        (reports: any) => reports?.report_type === 1
      )?.[0];
    } else if (getScreen === 'daily') {
      matchedData = data.filter(
        (reports: any) => reports?.report_type === 2
      )?.[0];
    } else {
      matchedData = data.filter(
        (reports: any) => reports?.report_type === 3
      )?.[0];
    }
    setReportsData(matchedData?.reports);
  }

  // API Calls to load the sidebar and grid layouts consisting of layout and reports.
  useEffect(() => {
    if (id) {
      refetch().then(response => {
        response.data && setWidgetData(response?.data?.data);
      });
      reportRefetch().then(response => {
        response?.data && matchReportsData(response?.data?.data);
      });
    } else if (isError) {
      snack({
        message: `Failed to load layout, try after sometime.`,
        severity: 'error'
      });
    } else if (isErrorReport) {
      snack({
        message: `Can not load reports, please try after some time.`,
        severity: 'error'
      });
    }
  }, [getScreen]);

  return (
    <>
      <Grid spacing={2}>
        <Formik initialValues={{ getScreen: 'live' }} onSubmit={() => {}}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ m: 1 }} boxSizing={'border-box'} width={'15%'}>
                <Stack spacing={2}>
                  <SelectField
                    onChange={(data: string) => setScreen(data)}
                    name="getScreen"
                    defaultValue="live"
                    options={[
                      { value: 'live', label: 'Live Dashboard' },
                      { value: 'daily', label: 'Daily Dashboard' },
                      { value: 'historical', label: 'Historical Dashboard' }
                    ]}
                    variant="outlined"
                  />
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
        <Grid>
          {!isLoading && isSuccess ? (
            renderDashboard(getScreen)
          ) : (
            <GridSkeliton data={liveDashboardLayout} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardConfiguration;
