import React, { useEffect, useMemo, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useWorkflow } from 'hooks/config/useWorkflow';
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  Stack,
  styled,
  Tooltip
} from '@mui/material';
import DraggableItem from './DraggableItem';
import Droppable from './DroppableItem';
import SortableRow from './SortableRow';
import { Grid, List, ListItemText } from '@mui/material';
import {
  ActivitySchemaOutput,
  ModulesTrainingActivitiesSchemasActivitySchema,
  ModulesTrainingAnalysisSchemasWorkflow,
  ModulesTrainingAnalysisSchemasWorkflowDependency,
  ModulesTrainingAnalysisSchemasWorkflowSchemaOutput,
  Workflow,
  WorkflowDependencyItem,
  WorkflowSchema
} from 'schemas';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier
} from '@dnd-kit/core';
import { Card, Typography } from 'components';
import { v4 as uuid } from 'uuid';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Edge } from 'reactflow';
import DependancyGraph from './dependancyGraph';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface TableProps {
  selectedCameraID?: string;
  rows: Workflow[];
  activeId: string | null;
  setActiveId: React.Dispatch<React.SetStateAction<string | null>>;
  setRows: React.Dispatch<React.SetStateAction<WorkflowSchema | undefined>>;
  handleChangeDependency: (id: string, array: WorkflowDependencyItem) => void;
  addActivity: (data: Workflow) => void;
  activities?: ModulesTrainingActivitiesSchemasActivitySchema[];
  handleSort?: any;
}

const BasicTable: React.FC<TableProps> = ({
  selectedCameraID,
  rows,
  activeId,
  setActiveId,
  addActivity,
  setRows,
  handleChangeDependency,
  activities,
  handleSort
}) => {
  const unique_id = uuid();
  const [isOpen, setOpen] = useState<boolean>(false);
  const filteredRow = rows;

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[900]}`,
    borderTop: 'none',
    padding: '2px'
  }));

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const { id } = active;
    const stringID = id.toString();

    setActiveId(stringID);
  }
  const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[900]}`,
    padding: '2px'
  }));

  function handleDragEnd(event: DragEndEvent) {
    const { over } = event;
    if (over && over.id === 'main-grid-dropzone' && activeId) {
      const newData: Workflow = {
        id: unique_id,
        activity_id: activeId,
        dependency: null,
        mendatory: false
      };
      addActivity(newData);
    }
    setActiveId(null);
  }

  function handleSortEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over != null && active != null && active?.id !== over?.id) {
      setRows(items => {
        if (items === undefined) return;
        const newItems = JSON.parse(JSON.stringify(items));

        const oldIndex = newItems.data.findIndex(
          (item: any) => item?.id === active.id
        );
        const newIndex = newItems.data.findIndex(
          (item: any) => item?.id === over.id
        );

        if (oldIndex !== -1 && newIndex !== -1) {
          newItems.data = arrayMove(newItems.data, oldIndex, newIndex);
        }

        return {
          configuration_id: newItems?.configuration_id,
          data: newItems?.data
        };
      });
    }
  }

  const handleDelete = (id: string) => {
    setRows(prevRows => {
      return {
        configuration_id: id,
        data: prevRows?.data.filter(workflow => workflow.id !== id) || []
      };
    });
  };
  const nodes: any[] = useMemo(() => {
    return rows.map((item, index) => ({
      id: item?.id,
      data: {
        label:
          activities?.find(activity => activity.id === item?.activity_id)
            ?.activity ?? '',
        mendatory: item?.mendatory
      }, // You can customize the label as needed
      position: { x: 50 * index, y: 100 * index } // Example positioning, adjust as needed
    }));
  }, [rows, activities, isOpen]);

  const edges = useMemo(() => {
    return rows
      .filter(item => item?.dependency !== null)
      .map(item => ({
        id: `e-${item?.dependency}-${item?.activity_id}`,
        source: item?.dependency,
        target: item?.id
      }));
  }, [rows, isOpen]);

  const handleToggleMendatory = (rowId: string, value: boolean) => {
    setRows(prevRows => {
      return {
        configuration_id: rowId,
        data:
          prevRows?.data.map(workflow => {
            if (workflow.id === rowId) {
              return {
                ...workflow,
                mendatory: value
              };
            }
            return workflow;
          }) || []
      };
    });
  };

  return (
    <>
      {!isOpen ? (
        <>
          <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <Grid container spacing={2}>
              <Grid item lg={2} sm={4}>
                <Typography variant="subtitle1" style={{ fontWeight: '700' }}>
                  Activities{' '}
                  <Tooltip title="drag and drop to define workflow order">
                    <HelpOutlineIcon
                      style={{
                        fontSize: '14px',
                        marginTop: '3px'
                      }}
                    />
                  </Tooltip>
                </Typography>
                <List
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    maxHeight: '56vh',
                    overflowY: 'auto'
                  }}
                >
                  <StyledListItem
                    style={{
                      backgroundColor: '#102633',
                      borderTop: '1px solid #000000',
                      color: 'white'
                    }}
                    disableGutters
                  >
                    <Typography variant="caption">
                      <span style={{ fontWeight: 'bold' }}>List</span>
                    </Typography>
                  </StyledListItem>
                  {activities?.map(value => (
                    <DraggableItem key={value.id} id={value.id}>
                      <StyledListItem disableGutters>
                        {
                          <ListItemAvatar>
                            <Avatar
                              src={value?.clips?.[0]?.thumb_url ?? ''}
                              sx={{ borderRadius: 0 }}
                            />
                          </ListItemAvatar>
                        }
                        <Tooltip title={value.activity}>
                          <ListItemText
                            primary={value.activity}
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'scroll',
                              textOverflow: 'ellipsis'
                            }}
                          />
                        </Tooltip>
                      </StyledListItem>
                    </DraggableItem>
                  ))}
                </List>
              </Grid>
              <Grid item lg={6} sm={8} className="table-header-workflow">
                <Typography variant="subtitle1" style={{ fontWeight: '700' }}>
                  Define Workflow
                </Typography>
                <Droppable id="main-grid-dropzone" rows={rows}>
                  <TableContainer
                    component={Paper}
                    style={{ maxHeight: '56vh', overflowY: 'auto' }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableHeader
                            align="center"
                            sx={{ backgroundColor: '#102633' }} // Explicitly set background color here
                          >
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 'bold', color: 'white' }}
                            >
                              Sequence
                            </Typography>
                          </StyledTableHeader>
                          <StyledTableHeader
                            align="center"
                            sx={{ backgroundColor: '#102633' }} // Explicitly set background color here
                          >
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 'bold', color: 'white' }}
                            >
                              Dependency
                            </Typography>
                          </StyledTableHeader>
                          <StyledTableHeader
                            align="center"
                            sx={{ backgroundColor: '#102633' }} // Explicitly set background color here
                          >
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 'bold', color: 'white' }}
                            >
                              Mandatory
                            </Typography>
                          </StyledTableHeader>
                          <StyledTableHeader
                            align="center"
                            sx={{ backgroundColor: '#102633' }} // Explicitly set background color here
                          >
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 'bold', color: 'white' }}
                            >
                              Min
                            </Typography>
                          </StyledTableHeader>
                          <StyledTableHeader
                            align="center"
                            sx={{ backgroundColor: '#102633' }} // Explicitly set background color here
                          >
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 'bold', color: 'white' }}
                            >
                              Max
                            </Typography>
                          </StyledTableHeader>
                          <StyledTableHeader
                            align="center"
                            sx={{ backgroundColor: '#102633' }} // Explicitly set background color here
                          >
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 'bold', color: 'white' }}
                            >
                              Action
                            </Typography>
                          </StyledTableHeader>
                        </TableRow>
                      </TableHead>
                      <DndContext
                        onDragEnd={handleSortEnd}
                        modifiers={[restrictToVerticalAxis]}
                      >
                        <SortableContext
                          items={filteredRow?.map(item => item?.id)}
                        >
                          <TableBody>
                            {rows?.map((row, index) => (
                              <SortableRow
                                key={row?.id}
                                setRows={setRows}
                                id={row?.id}
                                dependency={row?.dependency}
                                row={row}
                                rows={rows}
                                index={index}
                                activity={activities || []}
                                handleChangeDependency={handleChangeDependency}
                                handleDelete={handleDelete}
                                handleToggleMendatory={handleToggleMendatory}
                              />
                            ))}
                          </TableBody>
                        </SortableContext>
                      </DndContext>
                    </Table>
                  </TableContainer>
                </Droppable>
                <DragOverlay>
                  {activeId ? (
                    <StyledListItem
                      sx={{ borderTop: '1px solid grey' }}
                      key={activeId}
                      disableGutters
                      secondaryAction={
                        <IconButton aria-label="comment"></IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={
                            activities?.find(act => act.id === activeId)
                              ?.clips?.[0]?.thumb_url ?? ''
                          }
                          sx={{ borderRadius: 0 }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          activities?.find(act => act.id === activeId)
                            ?.activity || ''
                        }
                      />
                    </StyledListItem>
                  ) : null}
                </DragOverlay>
              </Grid>
              <Grid item lg={4} sm={12}>
                <Typography variant="subtitle1" style={{ fontWeight: '700' }}>
                  Dependency Graph
                </Typography>

                <Card variant={'outlined'} style={{ height: '56vh' }}>
                  <IconButton onClick={() => setOpen(true)}>
                    <OpenInFullIcon />
                  </IconButton>
                  <DependancyGraph
                    isOpen={isOpen}
                    nodes={nodes}
                    edges={edges}
                  />
                </Card>
              </Grid>
            </Grid>
          </DndContext>
        </>
      ) : (
        <Card
          style={{
            width: '100%'
          }}
          variant={'outlined'}
        >
          <IconButton onClick={() => setOpen(false)}>
            <CloseFullscreenIcon />
          </IconButton>
          <DependancyGraph isOpen={isOpen} nodes={nodes} edges={edges} />
        </Card>
      )}
    </>
  );
};

export default BasicTable;
