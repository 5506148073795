import { createSlice } from '@reduxjs/toolkit';

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState: {
    rowsPerPage: 10 // Default value
  },
  reducers: {
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    }
  }
});

export const { setRowsPerPage } = paginationSlice.actions;
export default paginationSlice.reducer;
