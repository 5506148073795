// Dependency imports
import { Stack, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Delete, Edit, Help } from '@mui/icons-material';
import { useEffect, useState } from 'react';

// Local imports
import { DataGridComponent } from 'components/DataGrid';
import { Button, Modal, Typography } from 'components';
import { useListCameraDetails } from 'api/sdk';
import { useDeleteCameraData } from 'api/sdk';
import { useAddCameraData } from 'api/sdk';
import { useSnack } from 'plugins/snack';
import Swal from 'sweetalert2';
import AddCamera from './addCamera';
import EditCamera from './editCamera';

const Cameras = () => {
  const [row, setRows] = useState<any>([]);
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [openEditModel, setOpenEditModel] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [cameraId, setCameraId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const { isLoading, data, refetch } = useListCameraDetails();
  const { mutateAsync: deleteCamera } = useDeleteCameraData();
  const { mutateAsync: addCameras, isLoading: loadingAdd } = useAddCameraData();
  const snack = useSnack();

  useEffect(() => {
    setRows(data?.data);
  }, [data]);

  const ActionToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  }));

  const handleDeleteClick = async (cameraId: string) => {
    Swal.fire({
      title: 'Delete Camera',
      text: 'Are you sure you want to delete this camera?',
      allowOutsideClick: false,
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      confirmButtonColor: '#E33030',
      cancelButtonColor: '#102633',
      cancelButtonText: 'No, cancel!',
      focusCancel: true,
      animation: true,
      customClass: {
        title: 'swal2-title',
        container: 'swal2-html-container',
        actions: 'swal2-actions'
      }
    }).then(async result => {
      if (result.isConfirmed) {
        await deleteCamera({
          id: cameraId
        });
        snack({
          message: 'Camera deleted successfully',
          severity: 'success'
        });
        refetch();
      }
    });
  };

  const CameraAction = ({
    cameraId,
    rowValue
  }: {
    cameraId: string;
    rowValue: any;
  }) => {
    return (
      <Stack direction="row" alignItems="center">
        <div>
          <ActionToolTip title={'Edit'}>
            <IconButton
              onClick={() => {
                setName(rowValue.name);
                setUrl(rowValue.url);
                setCameraId(rowValue.id);
                setOpenEditModel(true);
              }}
              disabled={false}
            >
              <Edit />
            </IconButton>
          </ActionToolTip>
        </div>
        <div>
          <ActionToolTip title={'Delete'}>
            <IconButton
              onClick={() => {
                handleDeleteClick(cameraId);
              }}
            >
              <Delete />
            </IconButton>
          </ActionToolTip>
        </div>
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Camera Name',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (params: any) => {
        const record = params.row;
        return (
          <h2
            style={{
              fontSize: '14px',
              fontWeight: 400,
              margin: 0,
              padding: 0
            }}
          >
            {params.value?.toString()}
          </h2>
        );
      }
    },
    {
      field: 'url',
      headerName: 'URL',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (params: any) => {
        const record = params.row;
        return (
          <h2
            style={{
              fontSize: '14px',
              fontWeight: 400,
              margin: 0,
              padding: 0
            }}
          >
            {params.value?.toString()}
          </h2>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'super-app-theme--header',
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      flex: 0.6,
      renderCell: (params: GridCellParams) => {
        return <CameraAction cameraId={params.row.id} rowValue={params.row} />;
      }
    }
  ];

  const validateForm = () => {
    setError(null);

    if (name.trim().length === 0) {
      setError('Camera name is required!');
      return;
    }

    if (name.trim().length < 5) {
      setError('Camera Name should alteast have 5 characters');
      return;
    }

    if (name.trim().length > 20) {
      setError("Camera Name can't be more than 20 characters");
      return;
    }

    if (!name.match(/^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])[a-zA-Z0-9\W_]+$/)) {
      setError('Invalid characters. Camera Name should be Alphanumeric');
      return;
    }

    if (url.trim().length === 0) {
      setError('RTSP url cannot be empty');
      return;
    }

    if (
      !url.match(
        /^rtsp:\/\/(?:[a-zA-Z0-9_-]+\:[a-zA-Z0-9_-]+@)?[a-zA-Z0-9.-]+(?::\d+)?\/?.*$/
      )
    ) {
      setError('Wrong rtsp url format. Ex format: rtsp://example.com/stream');
      return;
    }

    if (error === null) {
      addCameraToConfig();
    }
  };

  const addCameraToConfig = async () => {
    await addCameras({
      data: {
        name: name,
        url: url
      }
    })
      .then((res: any) => {
        snack({
          message: res?.data?.message,
          severity: 'success'
        });
        setName('');
        setUrl('');
        setError(null);
        setOpenModel(false);
        refetch();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <Stack
        width={'100%'}
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Stack
          width={'100%'}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
          marginTop={'20px'}
        >
          <Tooltip
            title={
              <div>
                <h4 style={{ margin: 0, padding: 0, fontSize: '14px' }}>
                  Requirements
                </h4>
                <p>
                  RSTP camera ( either a camera that will route via. RTSP server
                  or IP webcam app (android only))
                </p>
                <p>
                  A model trained after the release with at least 20 videos for
                  300 epochs (if the model converges , it'll get saved and stop
                  training)
                </p>
                <p>live inference with the same setup as the training videos</p>
              </div>
            }
            sx={{ cursor: 'pointer' }}
          >
            <Help />
          </Tooltip>
          <Button
            onClick={() => {
              setName('');
              setUrl('');
              setOpenModel(true);
            }}
            style={{
              float: 'right'
            }}
          >
            <>
              <AddIcon sx={{ marginRight: '6px' }} />
              Camera
            </>
          </Button>
        </Stack>
        <Stack width={'100%'} marginTop={'20px'}>
          <DataGridComponent
            rows={row}
            columns={columns}
            checkboxSelection={false}
            disableRowSelectionOnClick={true}
          />
        </Stack>
      </Stack>
      <Modal
        open={openModel}
        onClose={() => {
          setOpenModel(false);
        }}
        size="md"
      >
        <AddCamera
          url={url}
          name={name}
          setName={setName}
          setUrl={setUrl}
          setOpenModel={setOpenModel}
          refetch={refetch}
        />
      </Modal>
      <Modal
        open={openEditModel}
        onClose={() => {
          setOpenEditModel(false);
        }}
        size="md"
      >
        <EditCamera
          url={url}
          name={name}
          setName={setName}
          setUrl={setUrl}
          cameraId={cameraId}
          setOpenEditModel={setOpenEditModel}
          refetch={refetch}
        />
      </Modal>
    </>
  );
};

export default Cameras;
