/* eslint-disable  */

import React, { useEffect, useState } from 'react';
import openArrow from '../../components/icons/openArrow.svg';

import { useSelector } from 'react-redux';
import {
  DailywigetRegistry,
  HistorywigetRegistry,
  LivewidgetRegistry
} from './layout';
import { CloseArrow } from './iconComponents';

interface ComponentBarTyle {
  open: boolean;
  setOpen: any;
  onAddComponent: any;
  availableComponents: any;
  getScreen: string;
  liveData: string[];
}

const ComponentSideBar: React.FC<ComponentBarTyle> = ({
  open,
  setOpen,
  onAddComponent,
  availableComponents,
  getScreen,
  liveData
}) => {
  const [getWidget, setWidget] = useState<any>(LivewidgetRegistry);

  useEffect(() => {
    if (getScreen === 'live') {
      setWidget(LivewidgetRegistry);
    } else if (getScreen === 'daily') {
      setWidget(DailywigetRegistry);
    } else {
      setWidget(HistorywigetRegistry);
    }
  }, [getScreen]);

  return !open ? (
    <div
      style={{
        backgroundColor: 'rgb(16, 38, 51)',
        width: '1%',
        alignContent: 'center',
        height: '80vh',
        position: 'fixed',
        paddingLeft: '0.2vw',
        right: 20,
        top: '12%'
      }}
    >
      <img
        src={openArrow}
        onClick={() => setOpen(true)}
        style={{ cursor: 'pointer' }}
      />
    </div>
  ) : (
    <div
      style={{
        backgroundColor: 'rgb(16, 38, 51)',
        width: '6%',
        height: '80vh',
        position: 'fixed',
        padding: '0.2vw',
        right: 20,
        display: 'flex',
        top: '12%',
        overflowY: 'auto'
      }}
    >
      <div
        style={{ alignContent: 'center', cursor: 'pointer' }}
        onClick={() => setOpen(false)}
      >
        <CloseArrow />
      </div>
      {/*Do Not Change the div Below */}
      <div style={{ width: '100%' }}>
        {availableComponents.map((component: any) => {
          if (component.type === 'separator') {
            return (
              <div
                style={{
                  height: '1px',
                  backgroundColor: 'white'
                }}
              />
            );
          } else if (component?.type === 'section') {
            return (
              <div
                style={{
                  fontSize: '1.2vh',
                  color: 'white',
                  textAlign: 'center',
                  margin: '20px 0px 20px 0px'
                }}
              >
                {component?.title}
              </div>
            );
          } else {
            const property = getWidget[component?.name];
            const Icomponent = property?.icon;
            return (
              <div
                key={property?.key}
                style={{
                  color:
                    property?.name === 'FloorPlan' ||
                    liveData?.includes(property?.name)
                      ? 'white'
                      : 'grey',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '20px 0px 20px 0px',
                  cursor: liveData?.includes(property?.name)
                    ? 'pointer'
                    : 'not-allowed'
                }}
                onClick={() => {
                  liveData?.includes(property?.name) &&
                    onAddComponent(property?.name);
                }}
              >
                {Icomponent && (
                  <Icomponent
                    color={
                      liveData?.includes(property?.name) ? 'white' : 'grey'
                    }
                  />
                )}
                <div style={{ fontSize: '1.2vh', textAlign: 'center' }}>
                  {component.title ?? property?.title}
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ComponentSideBar;
