/* eslint-disable */
import { Box, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { useSelector } from 'react-redux';
import { selectCurrentCamera } from 'store/config/configSlice';

interface VideoLoaderProps {
  width?: number;
  height: number;
  canvasRest: (value: any) => void;
}

const Spinner: React.FC<{ color: string }> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
    <rect
      fill={color}
      stroke="#FF156D"
      strokeWidth="15"
      width="30"
      height="30"
      x="25"
      y="85"
    >
      <animate
        attributeName="opacity"
        calcMode="spline"
        dur="2s"
        values="1;0;1;"
        keySplines=".5 0 .5 1;.5 0 .5 1"
        repeatCount="indefinite"
        begin="-.4s"
      />
    </rect>
    <rect
      fill="#FF156D"
      stroke="#FF156D"
      strokeWidth="15"
      width="30"
      height="30"
      x="85"
      y="85"
    >
      <animate
        attributeName="opacity"
        calcMode="spline"
        dur="2s"
        values="1;0;1;"
        keySplines=".5 0 .5 1;.5 0 .5 1"
        repeatCount="indefinite"
        begin="-.2s"
      />
    </rect>
    <rect
      fill="#FF156D"
      stroke="#FF156D"
      strokeWidth="15"
      width="30"
      height="30"
      x="145"
      y="85"
    >
      <animate
        attributeName="opacity"
        calcMode="spline"
        dur="2s"
        values="1;0;1;"
        keySplines=".5 0 .5 1;.5 0 .5 1"
        repeatCount="indefinite"
        begin="0s"
      />
    </rect>
  </svg>
);

/**
 * Video loader component.
 */
const VideoLoader: React.FC<VideoLoaderProps> = ({ height, canvasRest }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const currentCamera = useSelector(selectCurrentCamera);

  const defaultResolution = {
    width: currentCamera?.video_metadata?.resolution[0] ?? 1920,
    height: currentCamera?.video_metadata?.resolution[1] ?? 1080
  };

  const setContainerSize = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight =
        (containerWidth * defaultResolution.height) / defaultResolution.width;
      setSize({ width: containerWidth, height: containerHeight });
    }
  };

  useEffect(() => {
    setContainerSize();
    canvasRest((prev: number) => prev + 1);
    window.addEventListener('resize', setContainerSize);

    return () => {
      window.removeEventListener('resize', setContainerSize);
    };
  }, [currentCamera]);

  return (
    <Box
      width="100%"
      ref={containerRef}
      height={size.height}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
      }}
    >
      <Skeleton variant="rectangular" width="100%" height={size.height} />
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          transform: 'translate(-50%, -50%)'
        }}
      >
        <figure>
          <div className="dot white"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </figure>
      </Box>
    </Box>
  );
};

export default VideoLoader;
