/* eslint-disable  */

import React, { useState } from 'react';
import {
  DailywigetRegistry,
  HistorywigetRegistry,
  LivewidgetRegistry
} from './layout';
import ComponentSideBar from './dashSideBar';
import DraggableScreen from './draggableScreen';
import { Layout } from 'react-grid-layout';
import {
  AvailableComponentTypes,
  DefaultLayout
} from '../../schemas/dashboardConfigSchema';
import ButtonComponent from 'components/Button/GridButton';
import { useCreateDashboardConfiguration } from 'api/sdk';
import { useParams } from 'react-router-dom';
import { useSnack } from 'plugins/snack';
import emptyDashImage from '../../assets/images/504 Error Gateway Timeout-pana.svg';

interface DashCreatortypes {
  title: string;
  layout: any;
  setLayout: any;
  availableComponents: any;
  setGetScreen?: any;
  getScreen: string;
  getDashboardId?: string;
  liveData: string[];
}
const DashCreator: React.FC<DashCreatortypes> = ({
  title,
  layout,
  setLayout,
  availableComponents,
  getScreen,
  getDashboardId,
  liveData
}) => {
  const [open, setOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const snack = useSnack();
  const [getLayout, setLayouts] = useState<any>(() => {
    if (getScreen === 'live') {
      return LivewidgetRegistry;
    } else if (getScreen === 'daily') {
      return DailywigetRegistry;
    } else if (getScreen === 'historical') {
      return HistorywigetRegistry;
    }
  });

  const onAddComponent = (name: any) => {
    // if the component is not in the registry, return
    if (!getLayout[name]) {
      return;
    }
    // if the component is already in the layout, return
    if (layout?.find((item: any) => item.component === name)) {
      return;
    }
    const property = getLayout[name];
    const h = property.minH;
    const w = property.minW;
    const currentMaxRow = layout?.reduce((acc: any, item: any) => {
      return Math.max(acc, item.position.y + item.position.h);
    }, 0);

    const x = 0;
    const y = currentMaxRow ? currentMaxRow + 1 : 1;

    const newComponent = {
      component: name,
      position: { x: x, y: y, w: w, h: h }
    };
    setLayout((prev: any) =>
      Array.isArray(prev) ? [...prev, newComponent] : [newComponent]
    );
  };

  const handleChangeLayout = (newLayout: Layout[]) => {
    setLayout(
      newLayout.map((item: DefaultLayout) => {
        const property = getLayout[item.i];
        return {
          component: item.i,
          position: {
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h
          }
        };
      })
    );
  };
  const handleDelete = (name: any) => {
    setLayout((prev: any) =>
      prev.filter((item: any) => item.component !== name)
    );
  };
  const extraDescription = React.useMemo(() => {
    const result: { [key: string]: string } = {}; // Define the type of result as a dictionary of strings
    availableComponents.forEach((item: AvailableComponentTypes) => {
      if (item?.description && item?.name) {
        result[item?.name] = item.description;
      }
    });
    return result;
  }, [availableComponents]);

  // API Call to get the positions of dashboard config at live, daily and history
  const { mutateAsync, error, isError, isLoading, reset } =
    useCreateDashboardConfiguration();
  function sendGridLayout() {
    mutateAsync({
      configId: id || '',
      data: {
        configuration_id: id || '',
        id: getDashboardId || id || '',
        live_dashboard: getScreen === 'live' ? layout : null,
        daily_dashboard: getScreen === 'daily' ? layout : null,
        history_dashboard: getScreen === 'historical' ? layout : null
      }
    }).then(response => {
      if (response.status === 200) {
        snack({
          message: `Layout Saved Successfully.`,
          severity: 'success'
        });
      } else {
        snack({
          message: `Failed to save the layout. Retry after sometime.`,
          severity: 'error'
        });
      }
    });
  }

  return (
    <div style={{ margin: '2vh', height: '100%' }}>
      <div
        style={{
          fontSize: '1.5vh',
          fontFamily: 'sans-serif',
          fontWeight: 'bold'
        }}
      >
        {title}
      </div>
      <div
        style={{
          minHeight: '75.2vh',
          // display: "flex",
          justifyContent: 'space-between'
        }}
      >
        {/* Droppable / Draggable components  */}
        <div>
          <DraggableScreen
            getScreen={getScreen}
            layout={layout}
            onDelete={handleDelete}
            extraDescription={extraDescription}
            handleChangeLayout={handleChangeLayout}
          />
        </div>
        {/* <div style={{ textAlign: 'center' }}>
          <img src={emptyDashImage} />
        </div> */}
        {/* Sidebar to drop the components on screen */}
        <ComponentSideBar
          liveData={liveData}
          availableComponents={availableComponents}
          open={open}
          setOpen={setOpen}
          onAddComponent={onAddComponent}
          getScreen={getScreen}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div style={{ minWidth: '8vw', margin: '10px' }}>
          <ButtonComponent
            centerIcon={isLoading ? 'Saving...' : 'Save'}
            backgroundColor={'rgb(16, 38, 51)'}
            color={'white'}
            disabled={isLoading}
            border="1px solid rgb(16, 38, 51)"
            cursor={isLoading ? 'not-allowed' : 'pointer'}
            onClick={() => sendGridLayout()}
          />
        </div>
      </div>
    </div>
  );
};

export default DashCreator;
