/* eslint-disable */
import { Formik } from 'formik';
import React from 'react';
import { TextField, SelectField } from 'components/Form';
import { Button } from 'components';
import { Box, Grid, Stack } from '@mui/material';
import * as Yup from 'yup';

import { ConfigCameras } from 'store/config/schema';
import { useSyncObject } from 'api/sdk';
import { useSnack } from 'plugins/snack';
import { faker } from '@faker-js/faker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { CreateNewObject } from 'store/config/objects';
interface FormValues {
  new_name: string;
  category: string;
}

interface IUpdateObjectFormProps {
  onClose: () => void;
  onSaved: (values: FormValues) => void;
  onRefresh: () => void;
  currentCamera: ConfigCameras | undefined;
  selectedRois: {
    id: string;
    images: string;
    frames: string;
    boundingBox: number[];
    frameNumber: number;
    frameWidth: number;
    frameHeight: number;
  }[];
  object: any;
}

const validationSchema = Yup.object().shape({
  new_name: Yup.string()
    .trim()
    .required('Name is required')
    .max(120, 'Name must be at most 120 characters'),
  category: Yup.string().required('Category is required')
});

const CreateObjectForm: React.FC<IUpdateObjectFormProps> = ({
  onClose,
  onSaved,
  currentCamera,
  selectedRois,
  onRefresh,
  object
}) => {
  const initialValues: FormValues = {
    new_name: '',
    category: ''
  };
  const { mutateAsync, isLoading } = useSyncObject();
  const dispatch = useDispatch();
  const NewConfigID = useSelector((state: RootState) => state.configV2.id);
  const snack = useSnack();
  // const handleUpdateClick = (values: FormValues) => {
  //   if (!currentCamera || !object.objects) {
  //     console.error('Objects array is null or undefined');
  //     return;
  //   }

  //   // Prepare the data for the update
  //   const filteredNewImages = selectedRois.map(imagePath => ({
  //     id: imagePath.id, // Assuming image details ID is the same as the object ID
  //     image_path: imagePath.images,
  //     frame_path: imagePath.frames,
  //     frame_number: imagePath.frameNumber,
  //     frame_width: imagePath.frameWidth,
  //     frame_height: imagePath.frameHeight,
  //     bounding_box: imagePath.boundingBox,
  //     thumb_url: imagePath.images
  //   }));

  //   const newObject = {
  //     objectId: faker.string.uuid(),
  //     cameraId: currentCamera.id?.toString() ?? '',
  //     name: values.new_name, // Assuming classname is the new_name value
  //     category: values.category,
  //     images: filteredNewImages
  //   };

  //   // Dispatch the action to add the new object
  //   dispatch(CreateNewObject(newObject));
  // };
  const handleUpdateClick = (values: FormValues) => {
    if (!currentCamera || !object.objects) {
      console.error('Objects array is null or undefined');
      return;
    }
    // Prepare the data for the update
    const updatedObjects = object.objects.map((obj: any) => ({
      id: obj.id,
      classname: obj.name ?? '',
      camera_id: currentCamera.id?.toString() ?? '',
      category: obj.category,
      images: obj.images
        ? obj.images.map((imagePath: any) => ({
            id: imagePath.id,
            image_path: imagePath.image_path || null,
            frame_path: imagePath.frames || null,
            frame_number: imagePath.frameNumber || null,
            frame_width: imagePath.frameWidth || null,
            frame_height: imagePath.frameHeight || null,
            bounding_box: imagePath.boundingBox || null
          }))
        : null
    }));
    const filteredNewImages = selectedRois.map(imagePath => ({
      id: imagePath.id, // Assuming image details ID is the same as the object ID
      image_path: imagePath.images.trim(),
      frame_path: imagePath.frames.trim(),
      frame_number: imagePath.frameNumber,
      frame_width: imagePath.frameWidth,
      frame_height: imagePath.frameWidth,
      bounding_box: imagePath.boundingBox,
      thumb_url: ''
    }));
    // Prepare the data for the update
    const NewObjects = [
      {
        id: faker.string.uuid(),
        camera_id: currentCamera.id?.toString() ?? '',
        classname: values.new_name, // Assuming classname is the new_name value
        category: values.category,
        images: filteredNewImages
      }
    ];
    // Assuming updateObject is your existing array
    // Prepend NewObjects to updateObject
    updatedObjects.unshift(...NewObjects);

    mutateAsync({
      cameraId: currentCamera.id?.toString() ?? '',
      configId: NewConfigID?.toString() ?? '',
      data: updatedObjects
    }).then(() => {
      snack({
        message: 'Object Added successfully',
        severity: 'success'
      });
      setTimeout(() => {
        onRefresh();
      }, 5000);
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleUpdateClick(values);
        onSaved(values);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ m: 2 }} boxSizing={'border-box'}>
            <Stack spacing={3}>
              <TextField
                name="new_name"
                label="New Object Name"
                variant="outlined"
              />
              <SelectField
                name="category"
                label="Object Type"
                options={[
                  { value: 'People', label: 'People' },
                  { value: 'Moving Object', label: 'Moving Object' },
                  { value: 'Stationary Object', label: 'Stationary Object' }
                ]}
                variant="outlined"
              />
              <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
              >
                <Button
                  type="submit"
                  variant="contained"
                  version="light"
                  fullWidth
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" fullWidth>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateObjectForm;
