/* eslint-disable  */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface WidgetTypes {
  image: any;
  title: string;
  description: string;
  key?: any;
  deletable: boolean;
  onDelete: () => void;
}
export const WidgetCard = ({ children, deletable, onDelete }: any) => {
  return (
    <div style={{ position: 'relative', height: '100%' }}>
      {deletable && (
        <button
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            border: 'none',
            backgroundColor: 'transparent',
            color: '#c4c4c4',
            cursor: 'pointer',
            zIndex: 999999
          }}
          onClick={e => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <CloseIcon />
        </button>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 10px 0px 10px',
          backgroundColor: 'white',
          boxShadow: '0px 2px 5px grey',
          height: '100%',
          zIndex: 0
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const Widget: React.FC<WidgetTypes> = ({
  image,
  title,
  description,
  key,
  deletable,
  onDelete
}) => {
  return (
    <WidgetCard deletable={deletable} onDelete={onDelete}>
      {image && <img src={image} alt={key} />}
      {title && (
        <div
          style={{
            fontSize: '1.7vh',
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            marginBottom: '10px'
          }}
        >
          {title}
        </div>
      )}
      {description && (
        <div
          style={{
            fontSize: '1.3vh',
            fontFamily: 'sans-serif',
            color: '#c4c4c4',
            textAlign: 'center'
          }}
        >
          {description}
        </div>
      )}
    </WidgetCard>
  );
};
