/* eslint-disable  */
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Grid,
  IconButton,
  Slider,
  Stack,
  TextField,
  styled
} from '@mui/material';
import { Button, Typography } from 'components';
import React from 'react';

import { secondsToHms } from 'utils/date';

interface AddCropActionProps {
  duration: number;
  playing: boolean;
  initialValues?: { start: number; end: number };
  editIndex?: number;
  onPlay: () => void;
  onPause: () => void;
  seek: (time: number) => void;
  onSave: (start: number, end: number, itemIndex: number) => void;
  onCancel: () => void;
}

const StyledInput = styled(TextField)({
  width: '100px'
});

const AddCropAction: React.FC<AddCropActionProps> = ({
  playing,
  duration,
  initialValues,
  editIndex,
  onPlay,
  onPause,
  seek,
  onSave,
  onCancel
}) => {
  const [cursor, setCursor] = React.useState<'start' | 'end'>('start');
  const [start, setStart] = React.useState<number>(0);
  const [end, setEnd] = React.useState<number>(duration - 1);

  React.useEffect(() => {
    if (initialValues) {
      setStart(initialValues.start);
      setEnd(initialValues.end);
      seek(initialValues.start);
    }
  }, [initialValues]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const val = newValue as number[];

    if (val[0] === val[1]) return;

    if (val[0] === start) {
      setCursor('end');
      seek(val[1]);
    } else {
      setCursor('start');
      seek(val[0]);
    }

    setStart(val[0]);
    setEnd(val[1]);
  };

  const valuetext = (value: number) => {
    return secondsToHms(value);
  };

  const handleSave = () => {
    onSave(start, end, editIndex || -1);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={2} direction={'row'} paddingTop={2} paddingRight={3}>
            <IconButton
              aria-label="play"
              onClick={() => (playing ? onPause() : onPlay())}
              disableRipple
            >
              {playing ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <Slider
              style={{ marginTop: '5px' }}
              getAriaLabel={() => 'Temperature range'}
              value={[start, end]}
              min={0}
              max={duration}
              onChange={handleChange}
              valueLabelDisplay="auto"
              valueLabelFormat={valuetext}
              disableSwap
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack spacing={2} direction={'row'} paddingLeft={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body1">Start Time</Typography>
                  <StyledInput
                    value={secondsToHms(start)}
                    onChange={() => {}}
                    disabled
                    variant="outlined"
                    size="small"
                  />
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body1">End Time</Typography>
                  <StyledInput
                    value={secondsToHms(end)}
                    onChange={() => {}}
                    disabled
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                <Button variant="outlined" version="light" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  version="light"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default AddCropAction;
