import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ActivityResponseSchema, PipelineStatusResponse } from 'schemas';

import {
  ActivitiesState,
  ActivitySchema,
  ActivitySchemaClips,
  Clips
} from './schema';
import { faker } from '@faker-js/faker';
import { getVideoThumbnail, resizeImage } from 'utils/thumbnail';
import { RootState } from 'store/store';
import { requestImageUploadUrl } from 'api/sdk';
import { v4 } from 'uuid';

const initialState: ActivitiesState = {
  loaded: false,
  data: []
};

export const uploadClipThumbnail = createAsyncThunk<
  {
    activity_id: string;
    clip_id: string;
    url: string;
    path: string;
  },
  {
    activity_id: string;
    clip_id: string;
    camera_id: string;
    config_id: string;
    filename: string;
  }
>('clips/uploadClipThumbnail', async (args, thunkAPI) => {
  const { activity_id, clip_id, config_id, filename, camera_id } = args;

  const state = thunkAPI.getState() as RootState;

  const activity = state.activities.data.find(
    activity => activity.id === activity_id
  );

  if (!activity) {
    throw new Error('Activity not found');
  }

  const clip = activity.clips.find(clip => clip.id === clip_id);

  if (!clip) {
    throw new Error('Clip not found');
  }

  // get thumbnail base64
  const thumbnail = clip.thumb_url;

  // convert base64 to blob
  const blob = await fetch(thumbnail).then(res => res.blob());

  const {
    data: { file_name, file_type, path, signed_url, url }
  } = await requestImageUploadUrl({
    config_id,
    filename,
    type: 'clips',
    camera_id: camera_id
  });

  // upload image to s3
  const response = await fetch(signed_url, {
    method: 'PUT',
    body: blob
  });

  if (!response.ok) {
    throw new Error('Failed to upload thumbnail');
  }

  return { activity_id, clip_id, url, path };
});

export const fetchThumbnail = createAsyncThunk<
  {
    thumbnail: string;
    activity_id: string;
    clip_id: string;
  },
  {
    activity_id: string;
    clip_id: string;
    start_time: number;
    end_time: number;
    videoUrl: string;
  }
>(
  'clips/fetchThumbnail',
  async (
    { activity_id, clip_id, start_time, end_time, videoUrl },
    thunkAPI
  ) => {
    const clipTime = start_time + (end_time - start_time) / 2;
    let thumbnail = await getVideoThumbnail(videoUrl, clipTime);
    thumbnail = await resizeImage(thumbnail, 150, 150);
    return { thumbnail, activity_id, clip_id };
  }
);

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    addActivity: (state, action: PayloadAction<ActivityResponseSchema[]>) => {
      const data = action.payload;

      const activities: ActivitySchema[] = [];

      data?.forEach(d => {
        d.activity_details.forEach(activity => {
          activities.push({
            activity: activity.activity,
            clips:
              activity.clips?.map(clip => ({
                id: clip.id,
                camera_id: clip.camera_id,
                thumbnail_path: clip.thumbnail_path,
                start_time: parseFloat(clip.start_time),
                end_time: parseFloat(clip.end_time),
                thumb_url: clip.thumb_url ?? 'https://placehold.jp/150x150.png',
                upload_status: 'uploaded'
              })) ?? [],
            id: activity.id,
            default: false,
            new: false
          });
        });
      });
      state.data = activities;
      state.loaded = true;
    },

    createActivity: (
      state,
      action: PayloadAction<{
        name: string;
        currentCameraId: string;
      }>
    ) => {
      const { name, currentCameraId } = action.payload;

      const lastActivity = state.data[state.data.length - 1];

      const cameraClips = lastActivity?.clips?.filter(
        clip => clip.camera_id === currentCameraId
      );

      const lastClip =
        (cameraClips?.length || 0) > 0
          ? cameraClips[cameraClips.length - 1]
          : null;

      const start_time = lastClip ? lastClip.end_time : 0;

      const last_clip_duration = lastClip
        ? lastClip.end_time - lastClip.start_time
        : 5;

      const end_time = start_time + last_clip_duration;

      const newActivity: ActivitySchema = {
        activity: name,
        clips: [
          {
            camera_id: currentCameraId,
            id: v4(),
            start_time: start_time,
            end_time: end_time,
            thumbnail_path: '',
            thumb_url: 'https://placehold.jp/150x150.png',
            upload_status: 'uploaded'
          }
        ],
        id: v4(),
        default: false,
        new: true
      };

      state.data = [...state.data, newActivity];
    },

    deleteActivity: (
      state,
      action: PayloadAction<{
        activityId: string;
      }>
    ) => {
      const { activityId } = action.payload;

      state.data = state.data.filter(activity => activity.id !== activityId);
    },

    updatePipelineStatus: (
      state,
      action: PayloadAction<PipelineStatusResponse[]>
    ) => {},

    updateActivityName: (
      state,
      action: PayloadAction<{
        ActivityId: string;
        name: string;
      }>
    ) => {
      const { ActivityId, name } = action.payload;

      const activity = state.data.find(activity => activity.id === ActivityId);

      activity!.activity = name;
    },

    mergeActivities: (
      state,
      action: PayloadAction<{
        cameraId: string;
        srcId: string[];
        destId: string;
        name: string;
      }>
    ) => {},

    deleteAllClips: (
      state,
      action: PayloadAction<{
        cameraId: string;
        activityId: string;
      }>
    ) => {},

    deleteSelectedClips: (
      state,
      action: PayloadAction<{
        cameraId: string;
        activityId: string;
        clips: string[];
      }>
    ) => {},

    moveImages: (
      state,
      action: PayloadAction<{
        cameraId: string;
        srcActivity: string;
        destActivity: string | null;
        clips: string[];
      }>
    ) => {},

    consumeWebsocketActivityMessage: (
      state,
      action: PayloadAction<{
        type: string;
        data: {
          config_id: string;
          camera_id: string;
          progress?: number;
          activity_assist?: ActivitySchema[];
        };
      }>
    ) => {},

    addClip: (
      state,
      action: PayloadAction<{
        clip_id: string;
        cameraId: string;
        start_time: number;
        end_time: number;
        selectedActivity: string;
        activityName: string;
        videoUrl: string;
        newActivityId?: string;
        max_duration?: number;
      }>
    ) => {
      const {
        start_time,
        end_time,
        selectedActivity,
        activityName,
        cameraId,
        videoUrl,
        clip_id,
        newActivityId
      } = action.payload;

      const clip: ActivitySchemaClips = {
        id: clip_id,
        thumbnail_path: '',
        start_time: start_time,
        end_time: end_time,
        thumb_url: 'https://placehold.jp/150x150.png',
        camera_id: cameraId,
        upload_status: 'uploading'
      };

      let selected = selectedActivity;

      const cameraClips: ActivitySchemaClips[] = [];

      state.data.forEach(activity => {
        cameraClips.push(
          ...activity.clips.filter(clip => clip.camera_id === cameraId)
        );
      });

      if (cameraClips.length > 0) {
        const lastClip = cameraClips[cameraClips.length - 1];

        const lastClipDuration = lastClip.end_time - lastClip.start_time;

        clip.start_time = start_time;
        clip.end_time = action.payload.max_duration || 0;

        // if (action.payload.max_duration) {
        //   if (clip.end_time > action.payload.max_duration) {
        //     clip.end_time = action.payload.max_duration;
        //   }
        // }
      }

      if (!selected && newActivityId) {
        const newActivity = {
          id: newActivityId,
          activity: activityName,
          default: false,
          new: true,
          clips: [clip]
        };
        state.data.push(newActivity);
      } else {
        const activity = state.data.find(
          activity => activity.id === selectedActivity
        );

        selected = activity?.id || '';

        if (activity) {
          activity.clips = [...activity.clips, clip];
        }
      }
    },

    updateClip: (
      state,
      action: PayloadAction<{
        activityId: string;
        clipId: string;
        start_time: number;
        end_time: number;
      }>
    ) => {
      const { activityId, clipId, start_time, end_time } = action.payload;

      const activity = state.data.find(activity => activity.id === activityId);

      if (!activity) {
        return;
      }

      const clip = activity.clips.find(clip => clip.id === clipId);

      if (!clip) {
        return;
      }

      clip.start_time = start_time;
      clip.end_time = end_time;
    },

    deleteClip: (
      state,
      action: PayloadAction<{
        activityId: string;
        clipId: string;
      }>
    ) => {
      const { activityId, clipId } = action.payload;

      const activity = state.data.find(activity => activity.id === activityId);

      if (!activity) {
        return;
      }

      activity.clips = activity.clips.filter(clip => clip.id !== clipId);

      if (activity.clips.length === 0) {
        state.data = state.data.filter(activity => activity.id !== activityId);
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchThumbnail.fulfilled, (state, action) => {
        const { thumbnail, activity_id, clip_id } = action.payload;

        const activity = state.data.find(
          activity => activity.id === activity_id
        );

        if (!activity) {
          return;
        }

        const clip = activity.clips.find(clip => clip.id === clip_id);

        if (!clip) {
          return;
        }

        clip.thumb_url = thumbnail;
        clip.upload_status = 'local';
      })
      .addCase(fetchThumbnail.rejected, (state, action) => {
        const { activity_id, clip_id } = action.meta.arg;

        const activity = state.data.find(
          activity => activity.id === activity_id
        );

        if (!activity) {
          return;
        }

        const clip = activity.clips.find(clip => clip.id === clip_id);

        if (!clip) {
          return;
        }

        clip.upload_status = 'failed';
      })
      .addCase(uploadClipThumbnail.fulfilled, (state, action) => {
        const { activity_id, clip_id, url, path } = action.payload;

        const activity = state.data.find(
          activity => activity.id === activity_id
        );

        if (!activity) {
          return;
        }

        const clip = activity.clips.find(clip => clip.id === clip_id);

        if (!clip) {
          return;
        }

        clip.thumb_url = url;
        clip.thumbnail_path = path;
        clip.upload_status = 'uploaded';
      })
      .addCase(uploadClipThumbnail.rejected, (state, action) => {
        const { activity_id, clip_id } = action.meta.arg;

        const activity = state.data.find(
          activity => activity.id === activity_id
        );

        if (!activity) {
          return;
        }

        const clip = activity.clips.find(clip => clip.id === clip_id);

        if (!clip) {
          return;
        }

        clip.upload_status = 'failed';
      });
  }
});

export const {
  consumeWebsocketActivityMessage,
  addActivity,
  updateActivityName,
  mergeActivities,
  deleteAllClips,
  updatePipelineStatus,
  deleteSelectedClips,
  moveImages,
  createActivity,
  deleteActivity,
  addClip,
  deleteClip,
  updateClip
} = activitiesSlice.actions;

export const selectactivities = (state: { activities: ActivitiesState }) =>
  state.activities;

export const addActivityMiddleware =
  (store: any) => (next: any) => (action: any) => {
    const result = next(action);
    // return result;
    // const state: RootState = store.getState();

    return result;
  };

export default activitiesSlice.reducer;
