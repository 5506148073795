/* eslint-disable  */

import React, { useEffect, useState } from 'react';
import DashCreator from '../dashCreator';
import { liveDashboardAvailableComponents } from '../layout';
import { ComponentLayout } from '../../../schemas/dashboardConfigSchema';
import { useParams } from 'react-router-dom';
import { useSnack } from 'plugins/snack';
import { DashBoardConfigSchemaOutput } from 'schemas';

interface DashboardGrid {
  getScreen: string;
  getWidgetData: any;
  liveData: any;
}

const LiveDashboard: React.FC<DashboardGrid> = ({
  getScreen,
  getWidgetData,
  liveData
}) => {
  const [layout, setLayout] = useState<ComponentLayout[]>([]);
  const { id } = useParams<{ id: string }>();
  const snack = useSnack();

  useEffect(() => {
    liveData &&
      setLayout(
        getWidgetData[0]?.live_dashboard?.filter((item: ComponentLayout) =>
          liveData?.includes(item?.component)
        )
      );
  }, [liveData]);

  return (
    <DashCreator
      liveData={liveData}
      getDashboardId={getWidgetData[0]?.id}
      title={'Live Dashboard'}
      layout={layout}
      setLayout={setLayout}
      availableComponents={liveDashboardAvailableComponents}
      getScreen={getScreen}
    />
  );
};

export default LiveDashboard;
