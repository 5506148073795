// Dependency imports
import {
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  SwitchProps
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { createEnumParam } from 'use-query-params';

// Local imports
import { PageTitle } from 'components';
import { SelectInput } from 'components/Form';
import ConfigurationCreatePage from 'pages/configuration/create';
import SearchSelect from 'components/SearchSelect';
import {
  selectCameras,
  setCurrentCameraId,
  uploadVideos
} from 'store/config/configSlice';
import { Button, Card, Typography } from 'components';
import { useListConfigurations } from 'api/sdk';
import { useSnack } from 'plugins/snack';
import { useAppSelector, useAppDispatch } from 'hooks';
import { ConfigSteps } from 'types';
import { AppDispatch, RootState } from 'store';
import SelectConfig from '../../assets/images/selectConfig.svg';
import { updateConfigId } from 'store/inference';
import { useConfig } from 'hooks/config';
import { Search } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const TrainPage: React.FC = () => {
  const { data, isLoading, isError, refetch, error } = useListConfigurations();
  const currentConfigId = useAppSelector(
    (state: RootState) => state.inference.currentConfigId
  );
  const [alteredData, setAlteredData] = useState<any>([]);
  useEffect(() => {
    setAlteredData([]);
    data?.data.map((curData: any) =>
      setAlteredData((prevData: any) => [...prevData, curData])
    );
  }, [data]);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorLoading, setError] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const appDispatch = useAppDispatch();
  const [allowAdd, setAllowAdd] = useState<string>('');
  const [isAutoSave, setAutoSave] = useState(false);
  const { step: checkStep, changeStep } = useConfig(true);
  const snack = useSnack();

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    transition: 'background-color 300ms, transform 300ms', // Ensure transition applies to both background and transform
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms', // Transition duration for the thumb
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          transition: theme.transitions.create(['background-color'], {
            duration: 500 // Transition for background color
          }),
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45'
          })
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      transition: theme.transitions.create(['transform'], {
        duration: 300 // Transition for the thumb's movement
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: 'grey',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500 // Ensure transition duration for background color
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D'
      })
    }
  }));

  function handleAutoSave(e: React.ChangeEvent<HTMLInputElement>) {
    // setAutoSave(e.target.checked);
    setAutoSave(e.target.checked);
    snack({
      message: e.target.checked
        ? 'Auto Save Activated'
        : 'Auto Save Deactivated',
      severity: e.target.checked ? 'success' : 'warning'
    });

    console.log(e.target.checked, 'Autosave');
  }

  useEffect(() => {
    if (!id && currentConfigId) {
      navigate(`/train/${currentConfigId}`);
    }
  }, [id, currentConfigId]);

  /**
   * Handle step navigation
   */
  const StepEnumParam = createEnumParam(Object.values(ConfigSteps));

  const setCurrentCamera = (cameraId: string) => {
    dispatch(setCurrentCameraId(cameraId));
  };

  const currentCameraId = useSelector(
    (state: RootState) => state.configV2.currentCameraId
  );

  const cameras = useSelector(selectCameras);

  if (isError) {
    return (
      <>
        <PageTitle title="Train" />
        <Card>
          <Typography variant="body1" color="error">
            Error: {error.message}
          </Typography>
        </Card>
      </>
    );
  }

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleChangeConfig = (configId: string) => {
    appDispatch(updateConfigId(configId));
    navigate(`/train/${configId}`);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (currentConfigId === 'Select Configuration') {
      setError('Please select configuration');
      return;
    }

    setError('');

    let files = event.target.files;

    if (!files) return;

    dispatch(
      uploadVideos({
        configuration_id: currentConfigId || '',
        files,
        showDuplicateError
      })
    );

    event.target.value = '';
  };

  const showDuplicateError = (message: string) => {
    snack({
      message: message,
      severity: 'error'
    });
  };

  return (
    <>
      {isLoading ? (
        <Stack
          sx={{ height: '650px' }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          {/* <AppLoading /> */}
        </Stack>
      ) : (
        <>
          <Grid container>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Stack
                spacing={1}
                direction={'row'}
                justifyContent={'left'}
                alignItems={'center'}
              >
                <SearchSelect
                  data={[
                    ...(data?.data.map((config: any) => ({
                      value: config.id,
                      label: config.name
                    })) || [])
                  ]}
                  onChange={handleChangeConfig}
                  label="Select Configuration"
                  selectValue={currentConfigId || ''}
                />
                {checkStep === ConfigSteps.ACTIVITY_ANNOTATION && (
                  <SearchSelect
                    label="Select Video"
                    data={
                      cameras?.map((camera: any, index: number) => ({
                        label: camera.name,
                        value: camera.id
                      })) || []
                    }
                    selectValue={String(currentCameraId)}
                    onChange={(value: any) => setCurrentCamera(value)}
                  />
                )}
                {checkStep === ConfigSteps.ACTIVITY_ANNOTATION && (
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked={false}
                        checked={isAutoSave}
                        onChange={e => handleAutoSave(e)}
                      />
                    }
                    label="Auto Save"
                  />
                )}
              </Stack>
              {checkStep === 'GENERAL_CAMERA_AND_VIDEO' &&
                currentConfigId !== 'Select Configuration' && (
                  <Button
                    onClick={handleButtonClick}
                    style={{
                      float: 'right'
                    }}
                  >
                    <>
                      <AddIcon />
                      Video
                    </>
                  </Button>
                )}
              <input
                type="file"
                ref={fileInputRef}
                accept="video/*"
                multiple
                capture="user"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Stack>

            <Grid item xs={12} marginTop={'10px'}>
              {currentConfigId !== 'Select Configuration' ? (
                <ConfigurationCreatePage
                  isAutoSave={isAutoSave}
                  setAllowAdd={setAllowAdd}
                  handleButtonClick={handleButtonClick}
                />
              ) : (
                <Card>
                  <Stack
                    sx={{ height: '650px' }}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                  >
                    <img
                      src={SelectConfig}
                      alt="select_config"
                      style={{ width: '260px' }}
                    />
                    <Typography sx={{ fontSize: '24px', color: '#707070' }}>
                      Select configuration type to view Train
                    </Typography>
                  </Stack>
                </Card>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
