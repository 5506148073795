/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import { Layer } from 'react-konva';
import PolygonAnnotation from './PolygonAnnotation';
import { Point } from 'schemas';
import Konva from 'konva';

interface CanvasLayerProps {
  initialPoints: Point[];
  color: string;
  isVisible: boolean;
  isLocked: boolean;
  isEditing: boolean;
  scale: { x: number; y: number };
  version?: number;
  setTempRoiPoints?: (points: Point[]) => void;
  // stage: Konva.Stage | null;
}

/**
 * Canvas component for drawing polygons on an image.
 */
const CanvasLayer: React.FC<CanvasLayerProps> = ({
  initialPoints,
  color,
  isVisible,
  isLocked,
  isEditing,
  scale,
  version,
  setTempRoiPoints
  // stage
}) => {
  const layerRef = useRef<Konva.Layer>(null);

  const [points, setPoints] = useState<number[][]>(() => {
    return initialPoints?.map(p => [p.x, p.y]);
  });

  const [flattenedPoints, setFlattenedPoints] = useState<number[]>();
  const [position, setPosition] = useState<number[]>([0, 0]);
  const [isMouseOverPoint, setMouseOverPoint] = useState<boolean>(false);
  const [isPolyComplete, setPolyComplete] = useState<boolean>(
    initialPoints?.length > 2
  );

  const [scaleX, setScalex] = useState<number>(1);
  const [scaleY, setScaley] = useState<number>(1);

  useEffect(() => {
    setPoints(initialPoints?.map(p => [p.x, p.y]));
    setPolyComplete(initialPoints?.length > 2);
    setMouseOverPoint(false);
  }, [version]);

  useEffect(() => {
    setScalex(scale.x);
    setScaley(scale.y);
  }, [scale.x, scale.y]);

  // Get mouse position on the stage
  const getMousePos = (s: any) => {
    return [
      s.getPointerPosition().x / scaleX,
      s.getPointerPosition().y / scaleY
    ];
  };

  // When cursor is over the start point
  const handleMouseOverStartPoint = (e: any) => {
    if (!isEditing) return;
    if (isPolyComplete || points?.length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
  };

  // When cursor is out of the start point
  const handleMouseOutStartPoint = (e: any) => {
    if (!isEditing) return;
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };

  // Event handler for point drag move
  const handlePointDragMove = (e: any) => {
    if (!isEditing) return;
    if (!e.target) return;

    const stage = e.target.getStage();
    const index = e.target.index - 1;

    const pos = [e.target.x(), e.target.y()];
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;

    const stageWidth = stage.width() / scale.x;
    const stageHeight = stage.height() / scale.y;

    if (pos[0] > stageWidth) pos[0] = stageWidth;
    if (pos[1] > stageHeight) pos[1] = stageHeight;

    setPoints([...points?.slice(0, index), pos, ...points?.slice(index + 1)]);
    setPosition(points[points?.length - 1]);
  };

  useEffect(() => {
    setFlattenedPoints(
      points
        ?.concat(isPolyComplete ? [] : position)
        ?.reduce((a, b) => a.concat(b), [])
    );
  }, [points, isPolyComplete, position]);

  useEffect(() => {
    if (setTempRoiPoints) {
      setTempRoiPoints(
        points?.map(point => {
          return { x: point[0], y: point[1] };
        })
      );
    }
  }, [points, setTempRoiPoints]);

  // Undo last point
  const undo = () => {
    setPoints(points?.slice(0, -1));
    setPolyComplete(false);
    setPosition(points[points?.length - 1]);
  };

  // Reset points
  const reset = () => {
    return;
    setPoints(initialPoints?.map(point => [point?.x, point?.y]));
    setPolyComplete(false);
  };

  // Clear points
  const clear = () => {
    setPoints([]);
    setPolyComplete(false);
  };

  // Event handler for group drag end
  const handleGroupDragEnd = (e: any) => {
    if (!isEditing) return;
    if (e.target.name() === 'polygon') {
      let result: number[][] = [];
      let copyPoints = [...points];
      copyPoints?.map(point =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      );
      e.target.position({ x: 0, y: 0 });
      setPoints(result);
    }
  };

  // Event handler for mouse down
  const handleMouseDown = (e: any) => {
    if (isPolyComplete) return;
    let stage = e.target;

    if (!(stage instanceof Konva.Stage)) {
      stage = stage.getStage();
    }

    // let isMouseOverStartPoint = false;
    const mousePos = getMousePos(stage);

    if (isMouseOverPoint && points?.length >= 3) {
      setPolyComplete(true);
    } else {
      setPoints(prevPoints => [...prevPoints, mousePos]);
    }
  };

  // Event handler for mouse move
  const handleMouseMove = (e: any) => {
    let stage = e.target;

    if (!(stage instanceof Konva.Stage)) {
      stage = stage.getStage();
    }

    const mousePos = getMousePos(stage);
    setPosition(mousePos);
  };

  // useEffect(() => {
  //   alert(points);
  // }, []);

  useEffect(() => {
    if (!isEditing) return;
    if (layerRef.current) {
      const st = layerRef.current.getStage();
      if (st) {
        st.on('mousemove', handleMouseMove);
        st.on('mousedown', handleMouseDown);
      }
    }
    return () => {
      if (!isEditing) return;
      if (layerRef.current) {
        const st = layerRef.current.getStage();
        if (st) {
          st.off('mousemove', handleMouseMove);
          st.off('mousedown', handleMouseDown);
        }
      }
    };
  }, [
    layerRef.current,
    isPolyComplete,
    points,
    isEditing,
    version,
    isMouseOverPoint
  ]);

  return isVisible ? (
    <Layer ref={layerRef}>
      <PolygonAnnotation
        points={points}
        flattenedPoints={flattenedPoints || []}
        scale={scale}
        color={color}
        handlePointDragMove={handlePointDragMove}
        handleGroupDragEnd={handleGroupDragEnd}
        handleMouseOverStartPoint={handleMouseOverStartPoint}
        handleMouseOutStartPoint={handleMouseOutStartPoint}
        isFinished={isPolyComplete}
        isEditing={isEditing}
      />
    </Layer>
  ) : (
    <></>
  );
};

export default CanvasLayer;
