/* eslint-disable  */
import { DependencyList, useEffect } from 'react';

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps: DependencyList | undefined
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn();
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}
// import { DependencyList, useEffect } from 'react';

// export function useDebounceEffect(
//   fn: () => void,
//   waitTime: number,
//   deps: DependencyList | undefined
// ) {
//   useEffect(() => {
//     const timeoutId = setTimeout(fn, waitTime);

//     return () => {
//       clearTimeout(timeoutId);
//     };
//   }, deps);
// }
