/* eslint-disable  */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { CircularProgress, Grid, IconButton, Stack } from '@mui/material';
import { ListBox } from './cardlistbox';
import { Button, Card, Modal, VideoLoader } from 'components';
import { BoundingBoxList } from './BoundingBoxList';
import useVideo from 'hooks/video';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Forward5Icon from '@mui/icons-material/Forward5';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Replay5Icon from '@mui/icons-material/Replay5';
import { SelectInput } from 'components/Form';
import { useActivityConfiguration } from 'hooks/config/activityConfig';
import AddCropAction from './videoActions';
import {
  Timeline,
  TimelineEffect,
  TimelineState
} from '@xzdarcy/react-timeline-editor';
import { useAppSelector } from 'hooks';
import { mockData } from './mock';
import { CustomRender0 } from './timelineRenderer';
import { ActivitySchema, ActivitySchemaClips } from 'store/config/schema';
import {
  convertSecondsToDuration,
  hrsToMnsToSecToMlSec,
  secondsToFriendlyTime,
  secondsToHms,
  secondsToHmsMs
} from 'utils';
import TimelinePlayer from './TimelineActions';
import './style.css';
import NewActivityForm from './newActivityForm';
import {
  addActivity,
  addClip,
  deleteActivity,
  updateActivityName,
  updateClip
} from 'store/config/activities';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'store';
import { DeleteSweep as DeleteSweepIcon } from '@mui/icons-material';
import { useConfirm } from 'plugins/confirm';
import { useSnack } from 'plugins/snack';
import AddActivityForm from './addClipForm';
import ErrorIcon from '@mui/icons-material/Error';
import { useConfigWebsocket } from 'hooks/config/useConfigWebsocket';
import { useGetConfiguration } from 'api/sdk';
import LoaderSpinner from '../../../assets/images/loader.svg';

const VideoPlayer = styled('video')<{ disableControl: boolean }>(
  ({ disableControl }) => {
    return {
      '&::-webkit-media-controls': {
        display: disableControl ? 'none' : 'initial'
      }
    };
  }
);

const VideoPreview = styled('video')<{ disableControl: boolean }>(
  ({ disableControl }) => {
    return {
      '&::-webkit-media-controls': {
        display: disableControl ? 'none' : 'initial'
      }
    };
  }
);

// background: `url(${background})`
export const mockEffect: Record<string, TimelineEffect> = {
  effect0: {
    id: 'effect0',
    name: '效果0'
  },
  effect1: {
    id: 'effect1',
    name: '效果1'
  }
};

const TimelineWrapper = styled('div')<{
  background?: string;
  scaleWidth?: number;
  selected?: boolean;
}>(({ theme, background, scaleWidth }) => ({
  overflow: 'scroll',
  width: '100%',
  '& .timeline-editor-edit-row:has(>.timeline-editor-action)': {
    background: `url('${background}') !important`,
    backgroundSize: `${scaleWidth}px 70% !important`,
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: '20px center !important',
    '& .timeline-editor-action': {
      background: 'rgb(255 0 0 / 20%) !important',
      // border: '2px solid rgb(255 0 0) !important',
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)'
    }
  }
}));

interface IActivityAnnotationProps {
  onNext?: () => void;
  onBack?: () => void;
  isAutoSave: boolean;
}
const ActivityAnnotationPage: React.FC<IActivityAnnotationProps> = ({
  onNext,
  onBack,
  isAutoSave
}) => {
  const timelineState = useRef<TimelineState>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const domRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = React.useState(false);

  const timelineWrapperRef = useRef<HTMLDivElement>(null);
  const [time, setTime] = React.useState(0);
  const [stopCondition, setStopCondition] = React.useState<number | null>();
  const [activityData, setActivityData] = useState<any>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [zoomCount, setZoomCOunt] = useState(0);
  const [showText, setShowText] = useState(false);

  const currentConfigId = useAppSelector(
    (state: RootState) => state.inference?.currentConfigId
  );
  const { refetch } = useGetConfiguration(
    {
      config_id: currentConfigId || ''
    },
    {
      query: {
        enabled: !!currentConfigId
      }
    }
  );

  const { messages } = useConfigWebsocket(currentConfigId || '');
  const [activityDataPointer, setActivityDataPointer] = useState<number>(
    activityData.length - 1
  );

  const {
    playing,
    duration,
    seek,
    reset,
    loading: loadingVideo,
    setVideoLoading,
    currentTime,
    videoRef
  } = useVideo();

  const [loading, setLoading] = useState<boolean>(false);
  const [lastTime, setLastTime] = useState(videoRef.current?.currentTime);
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const snack = useSnack();

  const {
    cameras,
    currentCameraId,
    setCurrentCamera,
    activity,
    currentCamera,
    videoContainerRef,
    syncActivity,
    isSyncLoading,
    isLoadingListConfig
  } = useActivityConfiguration();

  useEffect(() => {
    if (currentCamera) {
      refetch();
      if (currentCamera.lowres_url) {
        console.log('current camera lowres url');
        reset(currentCamera?.lowres_url);
        setShowText(false);
        // reset('/loading.mp4');
      } else {
        setShowText(true);
      }
    }
  }, [currentCamera]);

  useEffect(() => {
    if (messages) {
      const video_data: any = JSON.parse(messages)?.video_url;
      if (video_data.camera_id === currentCameraId) {
        reset(video_data.small);
        setShowText(false);
      }
    }
  }, [messages]);

  useEffect(() => {
    if (isAutoSave) {
      const handler = setTimeout(() => {
        syncActivity(activityData[activityDataPointer])
          .then(() => console.log('saved'))
          .catch(() => {
            snack({
              message: 'Something went wrong, Could not saved the changes.',
              severity: 'error'
            });
          });
      }, 500);

      // Cleanup the timeout if activityData changes before 5 seconds
      return () => {
        clearTimeout(handler);
      };
    }
  }, [activityData, activityDataPointer]);

  useEffect(() => {
    if (currentCameraId) {
      timelineState.current?.setTime(0);
    }
  }, [currentCameraId]);

  const clips = useEffect(() => {
    const clips: ActivitySchemaClips[] = [];

    activity?.forEach((activity: any) => {
      activity.clips.forEach((clip: any) => {
        if (clip.camera_id === currentCameraId) {
          clips.push(clip);
        }
      });
    });
  }, [activity, currentCameraId]);
  const [timelineBackground, setTimeline] = useState('');
  const scale = useMemo(() => {
    let devisor = zoomCount === 0 ? 10 : zoomCount === 1 ? 20 : 40;
    let image =
      zoomCount === 0
        ? `${currentCamera?.video_url}_thumbnail_strip.jpg`
        : zoomCount === 1
        ? `${currentCamera?.video_url}_thumbnail_strip_2x.jpg`
        : `${currentCamera?.video_url}_thumbnail_strip_4x.jpg`;
    setTimeline(image);
    return (videoRef.current?.duration || 0) / devisor;
  }, [duration, zoomCount]);

  const handleUpdateClip = ({
    activityId,
    clipId,
    start,
    end
  }: {
    activityId: string;
    clipId: string;
    start: number;
    end: number;
  }) => {
    dispatch(
      updateClip({ activityId, clipId, start_time: start, end_time: end })
    );
  };

  const handleDeleteActivityClick = (activityId: string) => {
    confirm({
      title: (
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ marginRight: '10px' }}>Delete Activity</div>
          <ErrorIcon />
        </div>
      ),
      content:
        'Deleting this activity will delete it from everywhere. Are you sure you want to delete this activity?'
    }).then(() => {
      dispatch(deleteActivity({ activityId }));
    });
  };
  const [getCropState, setCropState] = useState({
    action_id: '',
    row_id: '',
    start: 0
  });

  const handlePlay = () => {
    if (!timelineState.current) {
      return;
    } else {
      videoRef.current?.play();
      setIsPlaying(true);
      timelineState.current.play({ autoEnd: true });
    }
  };

  const handlePause = () => {
    if (!timelineState.current) {
      return;
    } else {
      videoRef.current?.pause();
      setLoading(false);
      setIsPlaying(false);
      timelineState.current.pause();
    }
  };

  const handleForward = () => {
    if (
      videoRef?.current?.currentTime &&
      videoRef?.current?.currentTime < videoRef.current?.duration
    ) {
      setIsPlaying(false);
      videoRef.current.pause();
      videoRef.current.currentTime = videoRef?.current?.currentTime + 5;
      timelineState.current?.setTime(
        videoRef?.current?.currentTime + 5 < videoRef?.current?.duration
          ? videoRef?.current?.currentTime
          : videoRef?.current?.duration
      );
    }
  };

  const handleBackward = () => {
    if (videoRef?.current?.currentTime && videoRef?.current?.currentTime > 0) {
      setIsPlaying(false);
      videoRef.current.pause();
      videoRef.current.currentTime = videoRef?.current?.currentTime - 5;
      timelineState.current?.setTime(
        videoRef?.current?.currentTime - 5 < 0
          ? 0
          : videoRef?.current?.currentTime
      );
    }
  };
  const [isCropDisable, setCropDisable] = useState<boolean>(true);

  const playerPanel = useRef<HTMLDivElement>(null);

  const handlePlayerPlay = () => {
    timelineState.current?.play({ autoEnd: false });
  };

  const handlePlayerPause = () => {
    timelineState.current?.pause();
  };

  const handlePlayerEnd = () => {
    timelineState.current?.setTime(0);
    timelineState.current?.pause();
  };

  useEffect(() => {
    if (!videoRef.current) return;
    setIsPlaying(false);

    videoRef.current.addEventListener('play', handlePlayerPlay);
    videoRef.current.addEventListener('pause', handlePlayerPause);
    videoRef.current.addEventListener('ended', handlePlayerEnd);

    return () => {
      videoRef.current?.removeEventListener('play', handlePlayerPlay);
      videoRef.current?.removeEventListener('pause', handlePlayerPause);
      videoRef.current?.removeEventListener('ended', handlePlayerEnd);
    };
  }, [videoRef.current?.duration]);

  useEffect(() => {
    if (!videoRef.current) return;

    // Define the update function
    const updateTimeline = () => {
      if (!timelineState.current || !videoRef.current) return;

      // Get the current time and duration
      const currentTime = videoRef.current.currentTime;
      const duration = videoRef.current.duration;

      // Calculate the scroll position based on the current time
      if (timelineRef.current) {
        const timelineWidth = timelineRef.current.scrollWidth;
        const visibleWidth = timelineRef.current.clientWidth;
        const scaleFactor = timelineWidth / duration;
        const scrollPosition = currentTime * scaleFactor;

        // Ensure the cursor is centered within the visible area
        const scrollLeft = Math.max(scrollPosition - visibleWidth / 2, 0);

        timelineRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'smooth' // Can be 'auto' or 'smooth'
        });
      }

      // Set the time in the timeline editor
      timelineState.current.setTime(currentTime);
    };

    // Add event listener for timeupdate
    videoRef.current.addEventListener('timeupdate', updateTimeline);

    // Clean up event listener
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', updateTimeline);
      }
    };
  }, [zoomCount, duration]);

  const maxEndtime = useMemo(() => {
    return Math.max(
      ...activity
        .map((activity: ActivitySchema) => activity.clips)
        .flat()
        .map((clip: any) => clip.end_time)
    );
  }, [activity]);

  const [currentVideo, setCurrentVideo] = useState(currentCameraId);

  const editorData = useMemo(() => {
    const tempActivities: ActivitySchema[] = [];

    activity?.forEach((activity: any) => {
      const activityClips = activity.clips.filter(
        (c: any) => c.camera_id === currentCameraId
      );

      if (activityClips.length > 0) {
        tempActivities.push({
          ...activity,
          clips: activityClips
        });
      }
    });

    if (currentVideo !== currentCameraId) {
      setCurrentVideo(currentCameraId);
      setActivityData(() => [tempActivities]);
      setActivityDataPointer(0);
    }

    return tempActivities;
  }, [activity, currentCameraId]);

  useMemo(() => {
    if (currentVideo !== currentCameraId) {
      setCurrentVideo(currentCameraId);
    } else {
      setActivityData((data: any) => [...data, editorData]);
      setActivityDataPointer(activityData.length);
    }
  }, [editorData]);

  useEffect(() => {
    const updateTimeline = () => {
      if (loadingVideo && timelineState?.current && videoRef?.current) {
        timelineState?.current?.setTime(videoRef?.current?.currentTime);
        setTime(videoRef?.current?.currentTime);
      }
    };

    const interval = setInterval(updateTimeline, 50);

    // Cleanup function to wait for the API call before unmount
    return () => {
      clearInterval(interval); // Clear the interval after the API call is finished
    };
  }, []);

  const scaleWidth = timelineRef.current?.clientWidth
    ? zoomCount === 0
      ? timelineRef.current.clientWidth - 248
      : zoomCount === 1
      ? timelineRef.current.clientWidth * 2 - 248
      : timelineRef.current.clientWidth * 4 - 248
    : 1;

  useMemo(() => {
    if (!timelineState.current || !videoRef?.current || !timelineRef.current)
      return;

    const autoScrollFrom = zoomCount === 2 ? 900 : 1025;
    const left =
      videoRef.current.currentTime *
        (scaleWidth / (scale * (zoomCount === 2 ? 50 : 25))) +
      20 -
      autoScrollFrom;

    if (zoomCount > 0 && timelineState.current) {
      timelineState.current.setScrollLeft(left);
    }

    return left;
  }, [videoRef?.current?.currentTime, scale, zoomCount, scaleWidth]);

  useEffect(() => {
    const checkBuffering = () => {
      const video = videoRef.current;
      if (video) {
        if (isPlaying && video.currentTime === lastTime) {
          setLoading(true); // Show loader if currentTime hasn't changed
        }
        if (video.currentTime >= videoRef.current.duration) {
          setLoading(false);
          setIsPlaying(false);
        } else {
          setLoading(false); // Hide loader if currentTime has changed
          setLastTime(video.currentTime);
        }
      }
    };

    const interval = setInterval(checkBuffering, 500); // Check every 500ms

    return () => {
      clearInterval(interval);
    };
  }, [lastTime, isPlaying]);

  function findNewlyAddedClips(prevEditorData: any, editorData: any): any {
    // If previous editorData is undefined, treat it as empty
    prevEditorData = prevEditorData || [];

    let newlyAddedClips: any = [];

    // Loop through each row of editorData and compare the clips
    editorData.forEach((row: any) => {
      // Find the matching row in prevEditorData
      const prevRow = prevEditorData?.find((prev: any) => prev.id === row.id);

      if (prevRow) {
        // If row exists, find clips that are in the current row but not in the previous row
        const newClips = row.clips
          .filter(
            (clip: any) =>
              !prevRow.clips.some((prevClip: any) => prevClip.id === clip.id)
          )
          .map((clip: any) => ({ rowId: row.id, clipId: clip.id, ...clip }));

        newlyAddedClips = [...newlyAddedClips, ...newClips];
      } else {
        // If the row is new, all clips in this row are new, add the rowId as well
        const newClips = row.clips.map((clip: any) => ({
          rowId: row.id,
          clipId: clip.id,
          ...clip
        }));
        newlyAddedClips = [...newlyAddedClips, ...newClips];
      }
    });

    if (newlyAddedClips.length === 1) {
      console.log(newlyAddedClips, 'newelyadasdsdded');
      setCropState({
        action_id: newlyAddedClips[0]?.clipId,
        row_id: newlyAddedClips[0]?.rowId,
        start: newlyAddedClips[0]?.start_time
      });
    }

    return newlyAddedClips;
  }

  const prevEditorDataRef = useRef<any>(undefined); // Initialize useRef with type

  useMemo(() => {
    const prevEditorData = prevEditorDataRef.current;
    findNewlyAddedClips(prevEditorData, editorData);
    prevEditorDataRef.current = editorData;
  }, [activityData]);

  useEffect(() => {
    if (domRef.current) {
      domRef.current.scrollTop = domRef.current.scrollHeight;
    }
  }, [editorData.length]);

  useEffect(() => {
    const syncScroll = () => {
      if (domRef.current && timelineWrapperRef.current) {
        domRef.current.scrollTop = timelineWrapperRef.current.scrollTop;
      }
    };

    function fwdBckwd(event: any) {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        return syncActivity(activityData[activityDataPointer]).then(() => {
          snack({
            message: 'Activities saved successfully',
            severity: 'success'
          });
        });
      }
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        return handleBackward();
      } else if (event.key === 'ArrowRight') {
        event.preventDefault();
        return handleForward();
      }
    }

    document.addEventListener('keydown', fwdBckwd);
    if (timelineWrapperRef.current) {
      timelineWrapperRef.current.addEventListener('scroll', syncScroll);
    }

    return () => {
      if (timelineWrapperRef.current) {
        timelineWrapperRef.current.removeEventListener('scroll', syncScroll);
      }
      document.removeEventListener('keydown', fwdBckwd);
    };
  }, [activityData]);

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          overflowY: 'auto'
        }}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={12}></Grid>
            <Grid item lg={6} md={12}>
              <Stack spacing={2} direction={'column'}>
                <div
                  id="videoContainer"
                  ref={videoContainerRef}
                  style={{
                    position: 'relative'
                  }}
                >
                  {loadingVideo && <VideoLoader height={400} />}
                  {showText && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '46%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2000,
                        gap: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '800px',
                        width: '1000px'
                      }}
                    >
                      <img
                        src={LoaderSpinner}
                        alt="loader"
                        style={{ width: '80px', height: '80px' }}
                      />
                      <h3
                        style={{
                          color: 'white',
                          fontSize: 18,
                          margin: 0,
                          padding: 0
                        }}
                      >
                        Processing the video. This will take some time, please
                        wait.
                      </h3>
                    </div>
                  )}
                  {showText ? (
                    <VideoPreview
                      disableControl={true}
                      id="video"
                      style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '0vh'
                      }}
                    />
                  ) : (
                    <VideoPlayer
                      id="video"
                      ref={videoRef}
                      controls={false}
                      style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '50vh'
                      }}
                      controlsList="nodownload"
                      disablePictureInPicture
                      muted
                      hidden={loadingVideo}
                      disableControl={true}
                    />
                  )}

                  {loading &&
                    videoRef.current &&
                    videoRef.current?.currentTime > 0 && (
                      <CircularProgress
                        size={60}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)'
                        }}
                      />
                    )}

                  <div
                    className="player-panel"
                    id="player-ground-1"
                    ref={playerPanel}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Replay5Icon
                      style={{
                        cursor: 'pointer'
                      }}
                      onClick={handleBackward}
                    />
                    <PlayArrowIcon
                      style={{
                        cursor: 'pointer',
                        display: !isPlaying ? 'block' : 'none'
                      }}
                      onClick={handlePlay}
                    />
                    <PauseIcon
                      style={{
                        cursor: 'pointer',
                        display: isPlaying ? 'block' : 'none'
                      }}
                      onClick={handlePause}
                    />
                    <Forward5Icon
                      style={{
                        cursor: 'pointer'
                      }}
                      onClick={handleForward}
                    />
                  </div>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {!isLoadingListConfig ? (
                <div className="timeline-editor-engine" ref={timelineRef}>
                  <Card
                    variant="outlined"
                    padded={false}
                    style={{
                      paddingRight: 10
                    }}
                    sx={{ boxShadow: 2 }}
                  >
                    <TimelinePlayer
                      time={time}
                      getCropState={getCropState}
                      loadingVideo={loadingVideo}
                      showText={showText}
                      zoomCount={zoomCount}
                      setZoomCOunt={setZoomCOunt}
                      timelineState={timelineState}
                      handlePlay={handlePlay}
                      activityDataPointer={activityDataPointer}
                      setActivityDataPointer={setActivityDataPointer}
                      handlePause={handlePause}
                      activityData={activityData}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      videoRef={videoRef}
                      onClickAdd={() => {
                        setOpenModal(true);
                        timelineState.current?.pause();
                        videoRef?.current?.pause();
                      }}
                      onClickCrop={() => {
                        handlePause();
                        handleUpdateClip({
                          activityId: getCropState?.row_id,
                          clipId: getCropState?.action_id,
                          start: getCropState?.start,
                          end: videoRef?.current?.currentTime || 5
                        });
                      }}
                      disableCrop={isCropDisable}
                      maxEndtime={maxEndtime}
                    />

                    <div
                      className="timeline-editor-example7"
                      style={{
                        maxHeight: 160,
                        overflowY: 'scroll'
                      }}
                    >
                      <div
                        ref={domRef}
                        style={{
                          overflow: 'overlay'
                        }}
                        onScroll={e => {
                          const target = e.target as HTMLDivElement;
                          timelineState.current?.setScrollTop(target.scrollTop);
                        }}
                        className={'timeline-list'}
                      >
                        {activityData[activityDataPointer]?.map((a: any) => (
                          <div
                            className="timeline-list-item"
                            key={a.id}
                            ref={inputRef}
                          >
                            <div className="timeline-list-item-action">
                              <input
                                type="text"
                                className="text"
                                value={a.activity}
                                onKeyDown={event => event.stopPropagation()}
                                onChange={e => {
                                  const value = e.target.value;
                                  dispatch(
                                    updateActivityName({
                                      name: value || '',
                                      ActivityId: a.id
                                    })
                                  );
                                }}
                              />
                              <IconButton
                                disableRipple
                                style={{
                                  padding: 0
                                }}
                                onClick={() => {
                                  handleDeleteActivityClick(a.id);
                                }}
                              >
                                <DeleteSweepIcon
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                      </div>

                      {activityData[activityDataPointer]?.length > 0 && (
                        <TimelineWrapper
                          ref={timelineWrapperRef}
                          scaleWidth={scaleWidth}
                          background={timelineBackground}
                          style={{
                            padding: 0
                          }}
                        >
                          {activityData[activityDataPointer] && (
                            <Timeline
                              onScroll={e => {
                                if (domRef.current) {
                                  domRef.current.scrollTop = e.scrollTop;
                                }
                              }}
                              ref={timelineState}
                              autoScroll={true}
                              editorData={[
                                ...(activityData[activityDataPointer].map(
                                  (activity: any) => ({
                                    id: activity.id,
                                    actions: activity.clips.map(
                                      (clip: any) => ({
                                        id: clip.id,
                                        start: Number(clip.start_time),
                                        end: Number(clip.end_time),
                                        effectId: 'effect1',
                                        data: {
                                          src: clip.thumb_url,
                                          name: activity.activity
                                        }
                                      })
                                    )
                                  })
                                ) || [])
                              ]}
                              effects={mockEffect}
                              style={{
                                width: '100%',
                                height: '100%'
                              }}
                              onChange={() => {}}
                              getActionRender={(action, row) => {
                                return (
                                  <CustomRender0
                                    action={action}
                                    start={Number(action.start)}
                                    end={Number(action.end)}
                                    selected={
                                      getCropState?.row_id === row.id &&
                                      getCropState?.action_id === action?.id
                                    }
                                    row={row}
                                    width={
                                      timelineWrapperRef.current?.clientWidth ||
                                      0
                                    }
                                  />
                                );
                              }}
                              scaleSplitCount={
                                zoomCount === 0 ? 8 : zoomCount === 1 ? 4 : 2
                              }
                              getScaleRender={scale =>
                                hrsToMnsToSecToMlSec(scale)
                              }
                              scale={scale}
                              maxScaleCount={duration / scale || 1}
                              minScaleCount={duration / scale || 1}
                              scaleWidth={
                                timelineRef.current?.clientWidth
                                  ? timelineRef.current?.clientWidth / 10 - 25
                                  : 1
                              }
                              dragLine={true}
                              hideCursor={false}
                              rowHeight={40}
                              onActionMoveEnd={({
                                action,
                                row,
                                start,
                                end
                              }) => {
                                handleUpdateClip({
                                  activityId: row.id,
                                  clipId: action.id,
                                  start,
                                  end
                                });
                                setCropState({
                                  action_id: action?.id,
                                  row_id: row?.id,
                                  start: action.start
                                });
                              }}
                              onDoubleClickAction={(
                                e,
                                { action, row, time }
                              ) => {
                                handleUpdateClip({
                                  activityId: row.id,
                                  clipId: action.id,
                                  start: action.start,
                                  end: action.end
                                });
                                setCropState({
                                  action_id: action?.id,
                                  row_id: row?.id,
                                  start: action.start
                                });
                              }}
                              onActionResizeEnd={({
                                action,
                                row,
                                start,
                                end
                              }) => {
                                handleUpdateClip({
                                  activityId: row.id,
                                  clipId: action.id,
                                  start,
                                  end
                                });
                                setCropState({
                                  action_id: action?.id,
                                  row_id: row?.id,
                                  start: action.start
                                });
                              }}
                              startLeft={20}
                              onClickAction={(e, { action, row }) => {
                                setIsPlaying(false);
                                videoRef.current?.pause();
                                setIsPlaying(false);
                                setStopCondition(
                                  Math.floor(
                                    (action.start + action.end) / 2 - 5
                                  ) || 0
                                );
                                setCropState({
                                  action_id: action?.id,
                                  row_id: row?.id,
                                  start: action.start
                                });
                                setCropDisable(false);
                                timelineState.current?.setTime(action.start);
                                seek(action.start);
                                // videoRef.current?.play();
                                // timelineState.current?.play({ autoEnd: false });
                              }}
                            />
                          )}
                        </TimelineWrapper>
                      )}
                    </div>
                  </Card>
                </div>
              ) : (
                <VideoLoader height={400} />
              )}
            </Grid>
            <Stack
              direction="row"
              spacing={2}
              width={'100%'}
              justifyContent={'center'}
              marginTop={'14px'}
            >
              <Button
                variant="outlined"
                version="light"
                disabled={false}
                onClick={onBack}
              >
                Back
              </Button>

              <Button
                variant="outlined"
                version="dark"
                disabled={!activityData[activityDataPointer]?.length}
                onClick={() => {
                  syncActivity(activityData[activityDataPointer]).then(() => {
                    snack({
                      message: 'Activities saved successfully',
                      severity: 'success'
                    });
                  });
                }}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                version="dark"
                disabled={!activityData[activityDataPointer]?.length}
                onClick={() => {
                  syncActivity(activityData[activityDataPointer]).then(() => {
                    onNext && onNext();
                  });
                }}
              >
                Continue
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Add Activity"
      >
        <AddActivityForm
          onSaved={(selectedActivity: string, newActivity: string) => {
            const activityId = selectedActivity || uuidv4();
            // const clipData = ;

            dispatch(
              addClip({
                clip_id: uuidv4(),
                cameraId: currentCameraId || '',
                activityName: newActivity,
                selectedActivity,
                newActivityId: selectedActivity ? undefined : activityId,
                videoUrl: currentCamera?.video_url || '',
                start_time: videoRef.current?.currentTime || 0,
                end_time: videoRef.current?.duration || 0,
                max_duration: videoRef.current?.duration || 0
              })
            );
            setOpenModal(false);
          }}
          activities={activity}
        />
      </Modal>
    </>
  );
};

export default ActivityAnnotationPage;
