/* eslint-disable  */
import { SafetyGearMonitor } from 'components/icons';
import {
  Alerts,
  EmployeeProductivityBar,
  EmployeeProductivityGantt,
  EmployeeProductivityLine,
  EmployeeProductivityPie,
  FloorPlan,
  ImportLiveStream,
  InventoryMonitor,
  LiveInference,
  ProductCycleBoxPlot,
  ProductCycleGanttChart,
  ProductThroughPut,
  ProductThroughPutHeatMap,
  ProgressBar,
  SafetyGearMonitor2,
  SecurityMonitor,
  WorkFlowManagement,
  WorkFLowManagementBarGraph,
  WorkFlowManagementPieChart
} from './dashboardComponents';
import {
  AlertSvgComponent,
  BarAndLineGraphSvgComponent,
  BoxPloySvgComponent,
  FloorPlanSvg,
  GanttSvgComponent,
  HeatMapSvgComponent,
  InferenceSvgComponent,
  LineGraphSvgComponent,
  PieSvgComponent,
  ProductivityBarSvgComponent,
  ProgressSvgComponent
} from './iconComponents';
import {
  AvailableComponentLayout,
  ComponentLayout,
  LivewidgetTypes
} from '../../schemas/dashboardConfigSchema';

export const liveDashboardLayout: ComponentLayout[] = [
  {
    component: 'ImportLiveStream',
    position: { x: 0, y: 0, w: 1, h: 3 }
  },
  {
    component: 'FloorPlan',
    position: { x: 1, y: 0, w: 2, h: 3 }
  },
  {
    component: 'ProgressBar',
    position: { x: 4, y: 0, w: 1, h: 3 }
  },
  {
    component: 'LiveInference',
    position: { x: 0, y: 1, w: 2, h: 8 }
  },
  {
    component: 'Alerts',
    position: { x: 2, y: 1, w: 2, h: 5 }
  },
  {
    component: 'ProductThroughputBar',
    position: { x: 3, y: 1, w: 1, h: 4 }
  },
  {
    component: 'EmployeeProductivityBar',
    position: { x: 2, y: 2, w: 1, h: 4 }
  },
  {
    component: 'WorkFlowManagement',
    position: { x: 4, y: 1, w: 1, h: 4 }
  },
  {
    component: 'WorkFlowManagementPieChart',
    position: { x: 4, y: 1, w: 1, h: 4 }
  },
  {
    component: 'WorkFLowManagementBarGraph',
    position: { x: 4, y: 1, w: 1, h: 4 }
  },
  {
    component: 'EmployeeProductivityPie',
    position: { x: 1, y: 3, w: 1, h: 4 }
  },
  {
    component: 'EmployeeProductivityGantt',
    position: { x: 5, y: 4, w: 1, h: 4 }
  }
];

export const liveDashboardAvailableComponents: AvailableComponentLayout[] = [
  {
    type: 'component',
    name: 'FloorPlan'
  },
  {
    type: 'component',
    name: 'ProgressBar'
  },
  {
    type: 'separator'
  },
  {
    type: 'component',
    name: 'Alerts'
  },
  {
    type: 'component',
    name: 'LiveInference'
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Employee Productivity'
  },
  {
    type: 'component',
    name: 'EmployeeProductivityBar',
    title: 'Bar Graph',
    icon: <EmployeeProductivityBar />
  },
  {
    type: 'component',
    name: 'EmployeeProductivityPie',
    title: 'Pie Chart',
    icon: <EmployeeProductivityPie />
  },
  {
    type: 'component',
    name: 'EmployeeProductivityGantt',
    title: 'Gantt Chart',
    icon: <EmployeeProductivityGantt />
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Product Throughput'
  },
  {
    type: 'component',
    name: 'ProductThroughputBar',
    title: 'Bar and Line Graph'
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Workflow Management'
  },
  {
    type: 'component',
    name: 'WorkFLowManagementBarGraph',
    title: 'Gantt Chart',
    icon: <GanttSvgComponent />
  },
  {
    type: 'separator'
  }
];

export const LivewidgetRegistry: LivewidgetTypes = {
  "ImportLiveStream": {
    name: 'ImportLiveStream',
    title: 'Import Live Stream',
    key: 'z',
    component: ImportLiveStream,
    minH: 1,
    maxH: 4,
    maxW: 4,
    minW: 2,
    static: true
  },
  "FloorPlan": {
    name: 'FloorPlan',
    title: 'Floor Plan',
    key: 'n',
    icon: FloorPlanSvg,
    component: FloorPlan,
    minH: 3,
    maxH: 3,
    minW: 1,
    maxW: 2,
    static: false
  },
  "ProgressBar": {
    name: 'ProgressBar',
    title: 'Progress',
    key: 'o',
    icon: ProgressSvgComponent,
    component: ProgressBar,
    minH: 3,
    maxH: 3,
    minW: 1,
    maxW: 2,
    static: false
  },
  "Alerts": {
    name: 'Alerts',
    title: 'Alerts',
    icon: AlertSvgComponent,
    key: 'a',
    component: Alerts,
    minH: 5,
    maxH: 9,
    minW: 2,
    maxW: 2,
    static: false
  },
  "LiveInference": {
    name: 'LiveInference',
    title: 'Inference',
    key: 'p',
    icon: InferenceSvgComponent,
    component: LiveInference,
    minH: 3,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "EmployeeProductivityBar": {
    name: 'EmployeeProductivityBar',
    title: 'Employee Productivity',
    key: 'b',
    icon: ProductivityBarSvgComponent,
    component: EmployeeProductivityBar,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "EmployeeProductivityPie": {
    name: 'EmployeeProductivityPie',
    title: 'Employee Productivity Pie',
    key: 'b',
    icon: PieSvgComponent,
    component: EmployeeProductivityPie,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "EmployeeProductivityGantt": {
    name: 'EmployeeProductivityGantt',
    title: 'Employee Productivity Gantt',
    key: 'x',
    icon: GanttSvgComponent,
    component: EmployeeProductivityGantt,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "ProductThroughputBar": {
    name: 'ProductThroughputBar',
    title: 'Product Throughput',
    key: 'c',
    icon: BarAndLineGraphSvgComponent,
    component: ProductThroughPut,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "WorkFLowManagementBarGraph": {
    name: 'WorkFLowManagementBarGraph',
    title: 'WorkFLow Management Bar Graph',
    icon: GanttSvgComponent,
    key: 'i',
    component: WorkFLowManagementBarGraph,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "ProductThroughputHeatMap": {
    name: 'ProductThroughputHeatMap',
    title: 'Product Throughput HeatMap',
    key: 'd',
    icon: HeatMapSvgComponent,
    component: ProductThroughPutHeatMap,
    minH: 3,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "ProductCycleGanttChart": {
    name: 'ProductCycleGanttChart',
    title: 'Product Cycle Gantt Chart',
    key: 'e',
    icon: GanttSvgComponent,
    component: ProductCycleGanttChart,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "ProductCycleBoxPlot": {
    name: 'ProductCycleBoxPlot',
    title: 'Product Cycle Box Plot',
    key: 'f',
    icon: BoxPloySvgComponent,
    component: ProductCycleBoxPlot,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "SafetyGearMonitor": {
    name: 'SafetyGearMonitor',
    title: 'Safety Gear Monitor',
    icon: GanttSvgComponent,
    key: 'g',
    component: SafetyGearMonitor,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "WorkFlowManagement": {
    name: 'WorkFlowManagement',
    title: 'WorkFLow Management',
    icon: GanttSvgComponent,
    key: 'w',
    component: WorkFlowManagement,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "WorkFlowManagementPieChart": {
    name: 'WorkFlowManagementPieChart',
    title: 'WorkFLow Management Pie Chart',
    icon: PieSvgComponent,
    key: 'h',
    component: WorkFlowManagementPieChart,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3,
    static: false
  },
  "SafetyGearMonitor2": {
    name: 'SafetyGearMonitor2',
    title: 'Safety Gear Monitor 2',
    key: 'j',
    component: SafetyGearMonitor2,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  "InventoryMonitor": {
    name: 'InventoryMonitor',
    title: 'Inventory Monitor',
    icon: BoxPloySvgComponent,
    key: 'k',
    component: InventoryMonitor,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  "SecurityMonitor": {
    name: 'SecurityMonitor',
    title: 'Security Monitor',
    key: 'm',
    icon: GanttSvgComponent,
    component: SecurityMonitor,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  }
};

export const DailywigetRegistry = {
  Alerts: {
    name: 'Alerts',
    title: 'Alerts',
    icon: AlertSvgComponent,
    key: 'a',
    component: Alerts,
    minH: 5,
    maxH: 9,
    minW: 2,
    maxW: 2
  },
  EmployeeProductivityBar: {
    name: 'EmployeeProductivityBar',
    title: 'Employee Productivity',
    key: 'b',
    icon: ProductivityBarSvgComponent,
    component: EmployeeProductivityBar,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  EmployeeProductivityPie: {
    name: 'EmployeeProductivityPie',
    title: 'Employee Productivity Pie',
    key: 'b',
    icon: PieSvgComponent,
    component: EmployeeProductivityPie,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  ProductThroughputBar: {
    name: 'ProductThroughputBar',
    title: 'Product Throughput',
    key: 'c',
    icon: BarAndLineGraphSvgComponent,
    component: ProductThroughPut,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  ProductThroughputHeatMap: {
    name: 'ProductThroughputHeatMap',
    title: 'Product Throughput HeatMap',
    key: 'd',
    icon: HeatMapSvgComponent,
    component: ProductThroughPutHeatMap,
    minH: 3,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  WorkFLowManagementBarGraph: {
    name: 'WorkFLowManagementBarGraph',
    title: 'WorkFLow Management Bar Graph',
    icon: GanttSvgComponent,
    key: 'i',
    component: WorkFLowManagementBarGraph,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  ProductCycleGanttChart: {
    name: 'ProductCycleGanttChart',
    title: 'Product Cycle Gantt Chart',
    key: 'e',
    icon: GanttSvgComponent,
    component: ProductCycleGanttChart,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  ProductCycleBoxPlot: {
    name: 'ProductCycleBoxPlot',
    title: 'Product Cycle Box Plot',
    key: 'f',
    icon: BoxPloySvgComponent,
    component: ProductCycleBoxPlot,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  SafetyGearMonitor: {
    name: 'SafetyGearMonitor',
    title: 'Safety Gear Monitor',
    icon: GanttSvgComponent,
    key: 'g',
    component: SafetyGearMonitor,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  WorkFlowManagement: {
    name: 'WorkFlowManagement',
    title: 'WorkFLow Management',
    icon: GanttSvgComponent,
    key: 'w',
    component: WorkFlowManagement,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  WorkFlowManagementPieChart: {
    name: 'WorkFlowManagementPieChart',
    title: 'WorkFLow Management Pie Chart',
    icon: PieSvgComponent,
    key: 'h',
    component: WorkFlowManagementPieChart,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },

  SafetyGearMonitor2: {
    name: 'SafetyGearMonitor2',
    title: 'Safety Gear Monitor 2',
    key: 'j',
    component: SafetyGearMonitor2,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  InventoryMonitor: {
    name: 'InventoryMonitor',
    title: 'Inventory Monitor',
    icon: LineGraphSvgComponent,
    key: 'k',
    component: InventoryMonitor,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  SecurityMonitor: {
    name: 'SecurityMonitor',
    title: 'Security Monitor',
    key: 'm',
    icon: GanttSvgComponent,
    component: SecurityMonitor,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  }
};

export const dailyDashboardAvailableComponents: AvailableComponentLayout[] = [
  {
    type: 'component',
    name: 'Alerts'
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Employee Productivity'
  },
  {
    type: 'component',
    name: 'EmployeeProductivityPie',
    title: 'Pie Chart',
    description:
      'Pie chart of each activity per workstation/camera, per person.'
  },
  {
    type: 'component',
    name: 'EmployeeProductivityBar',
    title: 'Bar Graph',
    description: 'Bar plot along time per workstation/camera, per person'
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Product Throughput'
  },
  {
    type: 'component',
    name: 'ProductThroughputBar',
    title: 'Column & Line Graph',
    description:
      'hourly throughput plot along the time per workstation/camera, per product type'
  },
  {
    type: 'component',
    name: 'ProductThroughputHeatMap',
    title: 'Heat Map',
    description: 'Heat map to show detail and highlight low throughput'
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Product Life Cycle'
  },
  {
    type: 'component',
    name: 'ProductCycleGanttChart',
    title: 'Gantt Chart',
    description:
      'Gantt chart to show the detail product entry-exit time at each workstation or stage'
  },
  {
    type: 'component',
    name: 'ProductCycleBoxPlot',
    title: 'Box Plot',
    description:
      'Box plot to help find the outlier and able to play video clip on the max duration moments.'
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Workflow Management'
  },
  {
    type: 'component',
    name: 'WorkFlowManagement',
    title: 'Gantt Chart',
    description:
      'Box plot to help find the outlier and able to play video clip on the max duration moments.'
  },
  {
    type: 'component',
    name: 'WorkFlowManagementPieChart',
    title: 'Pie Chart',
    description: 'Pie chart of each activity per workstation/camera, per person'
  },
  {
    type: 'component',
    name: 'WorkFLowManagementBarGraph',
    title: 'Bar Graph',
    description: 'Option to change to bar plot if activity number is many'
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Safety Gear Monitor'
  },
  {
    type: 'component',
    name: 'SafetyGearMonitor',
    title: 'Gantt Chart',
    description: 'Gantt chart (X: time in one day, Y: camera)'
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Inventory Monitor'
  },
  {
    type: 'component',
    name: 'InventoryMonitor',
    title: 'Line Graph',
    description: 'Line plot (X: time in one day, Y: inventory number)'
  },
  {
    type: 'separator'
  },
  {
    type: 'section',
    title: 'Security Monitor'
  },
  {
    type: 'component',
    name: 'SecurityMonitor',
    title: 'Gantt Chart'
  },
  {
    type: 'separator'
  }
];

export const dailyDashboardBarLayout: ComponentLayout[] = [
  {
    component: 'Alerts',
    position: { x: 0, y: 0, w: 2, h: 5 }
  },
  {
    component: 'EmployeeProductivityPie',
    position: { x: 1, y: 0, w: 1, h: 4 }
  },
  {
    component: 'EmployeeProductivityBar',
    position: { x: 2, y: 0, w: 1, h: 4 }
  },
  {
    component: 'ProductThroughputBar',
    position: { x: 3, y: 0, w: 1, h: 4 }
  },
  {
    component: 'ProductThroughputHeatMap',
    position: { x: 0, y: 1, w: 1, h: 4 }
  },
  {
    component: 'ProductCycleGanttChart',
    position: { x: 1, y: 1, w: 1, h: 4 }
  },
  {
    component: 'ProductCycleBoxPlot',
    position: { x: 2, y: 1, w: 1, h: 4 }
  },
  {
    component: 'WorkFlowManagement',
    position: { x: 3, y: 1, w: 1, h: 4 }
  },
  {
    component: 'WorkFlowManagementPieChart',
    position: { x: 0, y: 2, w: 1, h: 4 }
  },
  {
    component: 'WorkFLowManagementBarGraph',
    position: { x: 1, y: 2, w: 1, h: 4 }
  },
  {
    component: 'SafetyGearMonitor2',
    position: { x: 2, y: 2, w: 1, h: 4 }
  },
  {
    component: 'InventoryMonitor',
    position: { x: 3, y: 2, w: 1, h: 4 }
  },
  {
    component: 'SecurityMonitor',
    position: { x: 0, y: 3, w: 1, h: 4 }
  }
];

export const HistorywigetRegistry = {
  Alerts: {
    name: 'Alerts',
    title: 'Alerts',
    icon: AlertSvgComponent,
    key: 'a',
    component: Alerts,
    minH: 5,
    maxH: 9,
    minW: 2,
    maxW: 2
  },
  EmployeeProductivityColumn: {
    name: 'EmployeeProductivityColumn',
    title: 'Employee Productivity Gantt',
    key: 'b',
    icon: BarAndLineGraphSvgComponent,
    component: EmployeeProductivityLine,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  EmployeeProductivityPie: {
    name: 'EmployeeProductivityPie',
    title: 'Employee Productivity Pie',
    key: 'b',
    icon: PieSvgComponent,
    component: EmployeeProductivityPie,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  EmployeeProductivityBar: {
    name: 'EmployeeProductivityBar',
    title: 'Employee Productivity Bar',
    key: 'b',
    icon: LineGraphSvgComponent,
    component: EmployeeProductivityBar,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  ProductThroughputBar: {
    name: 'ProductThroughputBar',
    title: 'Product Throughput',
    key: 'c',
    icon: BarAndLineGraphSvgComponent,
    component: ProductThroughPut,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  ProductThroughputHeatMap: {
    name: 'ProductThroughputHeatMap',
    title: 'Product Throughput HeatMap',
    key: 'd',
    icon: HeatMapSvgComponent,
    component: ProductThroughPutHeatMap,
    minH: 3,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  ProductCycleGanttChart: {
    name: 'ProductCycleGanttChart',
    title: 'Product Cycle Gantt Chart',
    key: 'e',
    icon: GanttSvgComponent,
    component: ProductCycleGanttChart,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  ProductCycleBoxPlot: {
    name: 'ProductCycleBoxPlot',
    title: 'Product Cycle Box Plot',
    key: 'f',
    icon: BoxPloySvgComponent,
    component: ProductCycleBoxPlot,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  SafetyGearMonitor: {
    name: 'SafetyGearMonitor',
    title: 'Safety Gear Monitor',
    icon: LineGraphSvgComponent,
    key: 'g',
    component: SafetyGearMonitor,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  WorkFlowManagement: {
    name: 'WorkFlowManagement',
    title: 'WorkFLow Management',
    icon: GanttSvgComponent,
    key: 'w',
    component: WorkFlowManagement,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  WorkFlowManagementPieChart: {
    name: 'WorkFlowManagementPieChart',
    title: 'WorkFLow Management Pie Chart',
    icon: PieSvgComponent,
    key: 'h',
    component: WorkFlowManagementPieChart,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  WorkFLowManagementBarGraph: {
    name: 'WorkFLowManagementBarGraph',
    title: 'WorkFLow Management Bar Graph',
    icon: BarAndLineGraphSvgComponent,
    key: 'i',
    component: WorkFLowManagementBarGraph,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  EmployeeProductivityGantt: {
    name: 'EmployeeProductivityGantt',
    title: 'Employee Productivity Gantt',
    key: 'x',
    icon: GanttSvgComponent,
    component: EmployeeProductivityGantt,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  SafetyGearMonitor2: {
    name: 'SafetyGearMonitor2',
    title: 'Safety Gear Monitor 2',
    key: 'j',
    component: SafetyGearMonitor2,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  InventoryMonitor: {
    name: 'InventoryMonitor',
    title: 'Inventory Monitor',
    icon: BoxPloySvgComponent,
    key: 'k',
    component: InventoryMonitor,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  },
  SecurityMonitor: {
    name: 'SecurityMonitor',
    title: 'Security Monitor',
    key: 'm',
    icon: LineGraphSvgComponent,
    component: SecurityMonitor,
    minH: 4,
    maxH: 9,
    minW: 1,
    maxW: 3
  }
};

export const historicalDashboardBarLayout: ComponentLayout[] = [
  {
    component: 'Alerts',
    position: { x: 0, y: 0, w: 2, h: 5 }
  },
  {
    component: 'EmployeeProductivityColumn',
    position: { x: 0, y: 4, w: 1, h: 4 }
  },
  {
    component: 'EmployeeProductivityBar',
    position: { x: 2, y: 0, w: 1, h: 4 }
  },
  {
    component: 'EmployeeProductivityPie',
    position: { x: 1, y: 0, w: 1, h: 4 }
  },
  {
    component: 'ProductThroughputBar',
    position: { x: 3, y: 0, w: 1, h: 4 }
  },
  {
    component: 'ProductThroughputHeatMap',
    position: { x: 0, y: 1, w: 1, h: 4 }
  },
  {
    component: 'ProductCycleGanttChart',
    position: { x: 1, y: 1, w: 1, h: 4 }
  },
  {
    component: 'ProductCycleBoxPlot',
    position: { x: 2, y: 1, w: 1, h: 4 }
  },
  {
    component: 'WorkFLowManagement',
    position: { x: 3, y: 1, w: 1, h: 4 }
  },
  {
    component: 'WorkFLowManagementPieChart',
    position: { x: 0, y: 2, w: 1, h: 4 }
  },
  {
    component: 'WorkFLowManagementBarGraph',
    position: { x: 1, y: 2, w: 1, h: 4 }
  },
  {
    component: 'SafetyGearMonitor2',
    position: { x: 2, y: 2, w: 1, h: 4 }
  },
  {
    component: 'InventoryMonitor',
    position: { x: 3, y: 2, w: 1, h: 4 }
  },
  {
    component: 'SecurityMonitor',
    position: { x: 0, y: 3, w: 1, h: 4 }
  },
];

export const historicalDashboardAvailableComponents: AvailableComponentLayout[] =
  [
    {
      type: 'component',
      name: 'Alerts'
    },
    {
      type: 'separator'
    },
    {
      type: 'section',
      title: 'Employee Productivity',
      description:
        'Column & line comb along date To interactively open the daily report from historical report'
    },
    {
      type: 'component',
      name: 'EmployeeProductivityColumn',
      title: 'Column & Line Graph',
      description: 'Column plot along time per workstation/camera, per person.'
    },
    {
      type: 'section',
      title: 'Average Employee Productivity'
    },
    {
      type: 'component',
      name: 'EmployeeProductivityPie',
      title: 'Pie chart',
      description:
        'Pie chart of each activity per workstation/camera, per person.'
    },
    {
      type: 'component',
      name: 'EmployeeProductivityBar',
      title: 'Bar Graph'
    },
    {
      type: 'separator'
    },
    {
      type: 'section',
      title: 'Product Throughput'
    },
    {
      type: 'component',
      name: 'ProductThroughputBar',
      title: 'Column & Line Graph',
      description: 'Daily throughput plot along the date per workstation'
    },
    {
      type: 'component',
      name: 'ProductThroughputHeatMap',
      title: 'Heat Map',
      description: 'Daily throughput plot along the date per workstation'
    },
    {
      type: 'section',
      title: 'Product Life Cycle'
    },
    {
      type: 'component',
      name: 'ProductCycleBoxPlot',
      title: 'Box Plot',
      description:
        'Box plot to help find the outlier and able to play video clip on the max duration moments.'
    },
    {
      type: 'separator'
    },
    {
      type: 'section',
      title: 'Workflow Management'
    },
    {
      type: 'component',
      name: 'WorkFLowManagementPieChart',
      title: 'Pie Chart',
      description:
        'Pie chart of each activity per workstation/camera, per person'
    },
    {
      type: 'component',
      name: 'WorkFLowManagementBarGraph',
      title: 'Bar Graph',
      description: 'Option to change to bar plot if activity number is many'
    },
    {
      type: 'separator'
    },
    {
      type: 'section',
      title: 'Safety Gear Monitor'
    },
    {
      type: 'component',
      name: 'SafetyGearMonitor',
      title: 'Line Graph'
    },
    {
      type: 'separator'
    },
    {
      type: 'section',
      title: 'Inventory Monitor'
    },
    {
      type: 'component',
      name: 'InventoryMonitor',
      title: 'Box Plot'
    },
    {
      type: 'separator'
    },
    {
      type: 'section',
      title: 'Security Monitor'
    },
    {
      type: 'component',
      name: 'SecurityMonitor',
      title: 'Line Graph'
    },
    {
      type: 'separator'
    }
  ];
