/* eslint-disable */
import { Box, Grid, Stack, styled } from '@mui/material';
import {
  useAddToLabelAssist,
  useUpdateCamera as useUpdateCameraApi
} from 'api/sdk';
import { Button, Card } from 'components';
import VideoLoader from 'components/VideoLoader/VideoLoaderRoi';
import { error } from 'console';
import { useCropStep } from 'hooks/config';
import useVideo from 'hooks/video';
import { get } from 'http';
import { duration } from 'moment';
import { useSnack } from 'plugins/snack';
import React, { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { Point } from 'schemas/point';
import {
  ConfigCameras,
  addCropItem,
  addRoiItem,
  editCropItem,
  resetLabelAssistStatus,
  selectCameras,
  selectCurrentCamera,
  selectCurrentCameraIndex,
  setCurrentCamera
} from 'store/configSlice';
import { disableBackNavigation, getRandomColor } from 'utils';

import { RootState } from '../../../store';
import Canvas, { ROI } from './Canvas';
import PolygonCanvas from './PolygonCanvas';
import Actions from './actions';
import AddCropAction from './addCropAction';
import AddRoiAction from './roiAction';
import VideoEditorAccordion from './videoEditorAccordion';
import SelectModeModal from './SelectMode';

interface SelectRoiStepProps {
  onNext: () => void;
}

interface Crop {
  start: number;
  end: number;
}
interface ActionState {
  state: 'initial' | 'crop' | 'roi';
  type?: 'crop' | 'roi';
  itemIndex?: number;
  data?: {
    start: number;
    end: number;
  };
}

const VideoPlayer = styled('video')<{ disableControl: boolean }>(
  ({ disableControl }) => {
    return {
      '&::-webkit-media-controls': {
        display: disableControl ? 'none' : 'initial'
      }
    };
  }
);

const SelectRoiStep: React.FC<SelectRoiStepProps> = ({ onNext }) => {
  const {
    cameras,
    currentCamera,
    videoResolution,
    actionState,
    video,
    videoContainerRef,
    canvasVersion,
    tempRoi,
    tempRoiPoints,
    setTempRoiPoints,
    setActionState,
    changeCamera,
    handleAddRoiClick,
    editRoiClick,
    resetRoiAction,
    cancelRoiAction,
    saveRoiAction,
    handleDeleteRoi,
    loadingPipeline,
    handleUpdateClick,
    handleAddCropClick,
    handleCancelCropClick,
    handleSaveCropClick,
    handleEditCropClick,
    handleDeleteCrop,
    handleApplyToAll,
    setCanvasVersion
  } = useCropStep(onNext);
  const { loading: loadingVideo, videoRef, playing, seek, duration } = video;

  const [size, setSize] = React.useState<{ width: number; height: number }>({
    width: 0,
    height: 0
  });
  const [getOpen, setOpen] = useState<boolean>(false);

  const defaultResolution = {
    width: currentCamera?.video_metadata?.resolution[0] ?? 0,
    height: currentCamera?.video_metadata?.resolution[1] ?? 0
  };

  const setContainerSize = () => {
    if (videoContainerRef.current) {
      const containerWidth = videoContainerRef.current.clientWidth;
      const containerHeight =
        (containerWidth * defaultResolution.height) / defaultResolution.width;
      setSize({ width: containerWidth, height: containerHeight });
    }
  };
  useEffect(() => {
    setContainerSize();
    setCanvasVersion(prev => prev + 1);
    window.addEventListener('resize', setContainerSize);

    return () => {
      window.removeEventListener('resize', setContainerSize);
    };
  }, [currentCamera]);
  // const enableSaveButton = useMemo(() => {
  //   return cameras?.every(
  //     camera => camera?.roi?.length > 0 && camera?.crops?.length > 0
  //   );
  // }, [cameras]);

  const { mutateAsync, isLoading } = useUpdateCameraApi();

  return (
    <Grid container spacing={2}>
      <Grid item lg={8} md={12}>
        <Stack spacing={2} direction={'column'}>
          <div
            id="videoContainer"
            ref={videoContainerRef}
            style={{
              position: 'relative'
            }}
          >
            {loadingVideo && (
              <VideoLoader canvasRest={setCanvasVersion} height={400} />
            )}
            <VideoPlayer
              id="video"
              ref={videoRef}
              controls
              style={{ width: '100%', height: 'auto' }}
              controlsList="nodownload"
              disablePictureInPicture
              muted
              autoPlay
              disableControl={actionState.state !== 'initial'}
              hidden={loadingVideo}
            ></VideoPlayer>
            {currentCamera && !loadingVideo && (
              <>
                {tempRoi && tempRoiPoints ? (
                  <Canvas
                    resolution={videoResolution}
                    size={size}
                    setTempRoiPoints={setTempRoiPoints}
                    version={canvasVersion}
                    rois={[
                      {
                        points: tempRoi?.points,
                        color: tempRoi?.color,
                        isVisible: tempRoi?.isVisible,
                        isLocked: tempRoi?.isLocked,
                        isEditing: true
                      }
                    ]}
                  />
                ) : (
                  <Canvas
                    resolution={videoResolution}
                    size={size}
                    rois={currentCamera.roi.map(roi => {
                      return {
                        points: roi?.points,
                        color: roi?.color,
                        isVisible: roi?.isVisible,
                        isLocked: roi?.isLocked,
                        isEditing: false
                      };
                    })}
                  />
                )}
              </>
            )}
          </div>

          {currentCamera && (
            <Card>
              {
                {
                  initial: (
                    <Actions
                      onAddCrop={() =>
                        setActionState({ state: 'crop', itemIndex: -1 })
                      }
                      onAddRoi={handleAddRoiClick}
                    />
                  ),
                  crop: (
                    <AddCropAction
                      duration={duration}
                      playing={playing}
                      onPlay={() => videoRef.current?.play()}
                      onPause={() => videoRef.current?.pause()}
                      seek={seek}
                      onSave={handleSaveCropClick}
                      onCancel={() => {
                        setActionState({ state: 'initial' });
                      }}
                      editIndex={actionState.itemIndex}
                      initialValues={
                        actionState.state === 'crop' &&
                        actionState.data &&
                        actionState.itemIndex !== undefined &&
                        actionState.itemIndex > -1
                          ? actionState.data
                          : undefined
                      }
                    />
                  ),
                  roi: (
                    <AddRoiAction
                      duration={duration}
                      playing={playing}
                      onPlay={() => videoRef.current?.play()}
                      onPause={() => videoRef.current?.pause()}
                      seek={seek}
                      onSave={saveRoiAction}
                      onCancel={cancelRoiAction}
                      saveDisabled={tempRoiPoints.length < 3}
                      onReset={resetRoiAction}
                      currentTime={videoRef.current?.currentTime || 0}
                    />
                  )
                }[actionState.state]
              }
            </Card>
          )}
        </Stack>
      </Grid>
      <Grid item lg={4} md={12}>
        <Stack
          spacing={2}
          style={{
            overflowY: 'auto',
            height: 'calc(100vh - 250px)',
            minHeight: size.height
          }}
        >
          {cameras?.map((item, index) => (
            <VideoEditorAccordion
              camera={item}
              camerasLen={cameras?.length}
              tempRoi={tempRoi}
              expanded={currentCamera?.id === item.id}
              onChange={expanded => changeCamera(item.id)}
              onCropEdit={handleEditCropClick}
              deleteRoiItem={handleDeleteRoi}
              actionState={actionState.state as string}
              onRoiEdit={editRoiClick}
              deleteCropItem={handleDeleteCrop}
            />
          ))}
        </Stack>
        <Box mt={2}>
          <Button
            fullWidth
            disabled={isLoading || loadingPipeline}
            onClick={() => setOpen(true)}
          >
            {isLoading || loadingPipeline ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Grid>
      <SelectModeModal
        handleUpdateClick={handleUpdateClick}
        getOpen={getOpen}
        setOpen={setOpen}
        onNext={onNext}
      />
    </Grid>
  );
};

export default SelectRoiStep;
