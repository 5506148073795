/* eslint-disable  */
// Dependency imports
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import Swal from 'sweetalert2';

// Local imports
import { ITableHeadColumn, Table, ChatMessage, Card, Button } from 'components';
import { FORMAT_DATE_TIME, dateToString } from 'utils';
import { CreateConfigModal } from './createConfigModal';
import { useListConfigurations } from 'api/sdk';
import { useNavigate } from 'react-router-dom';
import { useSnack } from 'plugins/snack';
import { useDeleteConfiguration } from 'api/sdk';
import AddconfigIcon from 'components/icons/Addconfig';
import { ConfigurationCameraResponseSchema } from 'schemas';
import EditName from './editName';
import { DropdownList } from './dropdownlist';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'store';
import { updateConfigId } from 'store/inference';

export const ConfigurationListPage: React.FC = () => {
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [searchVal, setSearchVal] = useState<any>('');
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const currentConfigId = useAppSelector(
    (state: RootState) => state.inference.currentConfigId
  );
  const navigate = useNavigate();
  const snack = useSnack();
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<any>();
  const [columns, setColumns] = React.useState<any>([]);
  const [isRowEditing, setIsRowEditing] = useState<boolean>(false);
  const { data, isLoading, isError, refetch } = useListConfigurations();
  const {
    mutateAsync,
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete
  } = useDeleteConfiguration();

  const handleDeleteClick = (recordId: string, recordName: string) => {
    Swal.fire({
      title: 'Delete Configuration',
      text: 'Are you sure you want to delete this configuration?',
      allowOutsideClick: false,
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      confirmButtonColor: '#E33030',
      cancelButtonColor: '#102633',
      cancelButtonText: 'No, cancel!',
      focusCancel: true,
      animation: true,
      customClass: {
        title: 'swal2-title',
        container: 'swal2-html-container',
        actions: 'swal2-actions'
      }
    }).then(result => {
      if (result.isConfirmed) {
        handleDelete(recordId); // Call handleDelete if the user confirms
      }
    });
  };

  const handleDelete = async (config: string) => {
    const configId = config?.toString() ?? '';
    setRows([]);
    mutateAsync({
      params: { config_id: configId }
    })
      .then(res => {
        snack({
          message: 'Configuration deleted successfully',
          severity: 'success'
        });
        if (configId === currentConfigId) {
          dispatch(updateConfigId('Select Configuration'));
        }
        setSearchVal('');
        setIsRowEditing(false);
        setExpandedRowIndex(null);
        refetch();
      })
      .catch(err => {
        snack({
          message: 'Failed to  delete Configuration',
          severity: 'error'
        });
      });
  };

  useEffect(() => {
    const updatedColumns: ITableHeadColumn[] = [
      {
        value: 'Name',
        key: 'name',
        render: ({ value, record }) => {
          if (typeof value === 'string') {
            return (
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                <EditName
                  value={value}
                  configId={record}
                  isRowEditing={isRowEditing}
                  setIsRowEditing={setIsRowEditing}
                />
              </Typography>
            );
          }
          return null; // Or handle other types appropriately
        }
      },
      {
        value: 'Creator',
        key: 'creator',
        render: ({ value }) => {
          return (
            <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
              {value as string}
            </Typography>
          );
        }
      },
      {
        value: 'Date Created',
        key: 'date-modified',
        render: ({ value }) => {
          return (
            <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
              {moment
                .utc(value || Date.now())
                .local()
                .format('MMM Do YYYY, h:mm a')}
            </Typography>
          );
        }
      },
      {
        value: 'Actions',
        key: 'actions',
        render: ({ value, record }) => {
          return (
            <IconButton
              onClick={() =>
                record &&
                typeof record?.id === 'string' &&
                typeof record?.name === 'string' &&
                handleDeleteClick(record.id, record?.name)
              }
            >
              <DeleteIcon fontSize="small" color="primary" />
            </IconButton>
          );
        }
      }
    ];
    setColumns(updatedColumns);

    const updatedRows = data?.data.map(item => ({
      id: item.id,
      name: item.name,
      creator: item?.created_by_name ? item?.created_by_name : 'John Doe',
      'date-modified': item.updated_at,
      'no-of-cameras': item.cameras.length,
      scene: item.scene,
      cameras: item.cameras,
      Analytics: item.analytics,
      site_id: item.site_id
    }));
    setRows(updatedRows);
  }, [data, isRowEditing]);

  let DataCount = data && data?.data?.length;

  const [messages, setMessages] = React.useState<ChatMessage[]>([
    {
      message: 'Hello',
      timestamp: '2021-10-10',
      photoURL: 'https://via.placeholder.com/150',
      displayName: 'John Doe',
      avatarDisp: true,
      position: 'left'
    },
    {
      message: 'Hi, I am fine, thank you!',
      timestamp: '2021-10-10',
      photoURL: 'https://via.placeholder.com/150',
      displayName: 'Jane Doe',
      avatarDisp: true,
      position: 'right'
    }
  ]);

  const onSendMessage = (message: string) => {
    const newMessage: ChatMessage = {
      message,
      timestamp: dateToString(new Date(), FORMAT_DATE_TIME),
      photoURL: 'https://via.placeholder.com/150',
      displayName: 'John Doe',
      avatarDisp: true,
      position: 'right'
    };
    setMessages([...messages, newMessage]);
  };

  return (
    <>
      <Grid container spacing={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          marginBottom={2}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              lineHeight: 2.5
            }}
          >
            Configuration Details
          </Typography>
          <Button onClick={() => setCreateModalOpen(true)}>
            <AddIcon sx={{ marginRight: '6px' }} /> Configuration
          </Button>
        </Stack>
        <Grid item md={12}>
          <Card>
            <Table
              columns={columns}
              rows={rows}
              setIsRowEditing={setIsRowEditing}
              expandedRowIndex={expandedRowIndex}
              setExpandedRowIndex={setExpandedRowIndex}
              searchVal={searchVal}
              setSearchVal={setSearchVal}
              expandedRowRender={record => {
                return (
                  <DropdownList
                    record={
                      record.cameras as ConfigurationCameraResponseSchema[]
                    }
                    siteId={record.site_id as string}
                    configId={record.id as string}
                  />
                );
              }}
              striped={true}
              loading={false}
              stickyHeader
            />
            {DataCount === 0 && (
              <Grid container xs={12} style={{ height: '50vh' }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Stack direction="column">
                    <IconButton
                      style={{ backgroundColor: 'transparent' }}
                      onClick={() => setCreateModalOpen(true)}
                    >
                      <AddconfigIcon />
                    </IconButton>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: 'bold' }}
                    >
                      ADD CONFIGURATION
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
      <CreateConfigModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSucess={() => {
          setCreateModalOpen(false);
          refetch();
        }}
      />
    </>
  );
};
