/* eslint-disable  */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashCreator from '../dashCreator';
import { dailyDashboardAvailableComponents } from '../layout';
import { ComponentLayout } from '../../../schemas/dashboardConfigSchema';

const DailyDashboard = ({ getScreen, getWidgetData, liveData }: any) => {
  const [layout, setLayout] = useState<any>([]);

  useEffect(() => {
    liveData &&
      setLayout(
        getWidgetData[0]?.daily_dashboard?.filter((item: ComponentLayout) =>
          liveData?.includes(item?.component)
        )
      );
  }, [getScreen]);

  return (
    <DashCreator
      liveData={liveData}
      getDashboardId={getWidgetData[0]?.id}
      title="Daily Dashboard"
      layout={layout}
      setLayout={setLayout}
      availableComponents={dailyDashboardAvailableComponents}
      getScreen={getScreen}
    />
  );
};

export default DailyDashboard;
