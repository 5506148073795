// Dependency imports
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Local imports
import { Modal } from 'components';
import { NotificationList } from 'pages/workFlowAnnotation/notificationList';

interface InferenceVideoProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  start: number;
  end: number;
  videoRef: React.RefObject<HTMLVideoElement>;
  getVideo: string;
  videoQuery: any;
  setStart: any;
  seek: any;
  videoUrl: string;
  videoName: string;
}

const InferenceVideo: React.FC<InferenceVideoProps> = ({
  modalOpen,
  setModalOpen,
  start,
  end,
  videoRef,
  getVideo,
  videoQuery,
  setStart,
  seek,
  videoUrl,
  videoName
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [currentTime, setCurrentTime] = useState<number>(start);
  const [videoDuration, setVideoDuration] = useState<number>(end - start);
  const videoInitialized = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastTime, setLastTime] = useState(0);

  useEffect(() => {
    const checkBuffering = () => {
      const video = videoRef.current;
      if (video) {
        if (end !== 0 && video.currentTime > end) {
          setModalOpen(false);
        } else if (video.currentTime > videoDuration) {
          setLoading(false);
        } else if (
          video.currentTime === lastTime &&
          video.currentTime !== videoDuration
        ) {
          setLoading(true);
        } else {
          setLoading(false);
        }
        setLastTime(video.currentTime);
      }
    };

    const interval = setInterval(checkBuffering, 500); // Check every 500ms

    return () => {
      clearInterval(interval);
    };
  }, [lastTime]);

  // Function to update current time on video time update
  const handleVideoTimeUpdate = (
    event: SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    setCurrentTime(event.currentTarget.currentTime);
    setLoading(false);
  };

  useEffect(() => {
    if (!modalOpen && videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
      videoInitialized.current = false;
    }
  }, [modalOpen, videoRef]);

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = start;
      setVideoDuration(videoRef.current.duration);
    }
  };

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)} size="xl">
      <Stack>
        <Stack
          marginBottom={2}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography sx={{ fontSize: 22, fontWeight: 600 }}>
            {videoName}
          </Typography>
        </Stack>
        {videoUrl && (
          <video
            ref={videoRef}
            disablePictureInPicture
            src={`${videoUrl}#t=${start},${end}`}
            style={{ maxWidth: '100%', maxHeight: '70vh' }}
            autoPlay
            onWaiting={() => {
              setLoading(true);
            }}
            onTimeUpdate={handleVideoTimeUpdate}
            onLoadedMetadata={handleLoadedMetadata}
            onEnded={() => setModalOpen(false)}
          />
        )}
        {loading && (
          <CircularProgress
            size={60}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        )}
      </Stack>
      <Stack>
        <NotificationList
          videoQuery={videoQuery}
          selectedConfig={getVideo}
          getVideoCurrentTime={currentTime}
          setVicdeoCurrentTime={setCurrentTime}
          seek={seek}
          start={start}
        />
      </Stack>
    </Modal>
  );
};

export default InferenceVideo;
