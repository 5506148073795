/* eslint-disable  */
import { Box, Checkbox, Grid, IconButton, Stack, styled } from '@mui/material';
import { Card, Typography, Modal, Button } from 'components';
import React, { useDebugValue, useMemo } from 'react';

interface IActivityCategoryCardProps {
  object: any;
  checked: boolean;
  onCheck: (id: string) => void;
}

const Title = styled(Typography)({
  fontWeight: 'bold !important',
  fontSize: 13
});

const StyledCheckbox = styled(Checkbox)<{ overlap: boolean }>(
  ({ theme, overlap }) => ({
    padding: 0,
    ...(overlap && {
      position: 'absolute',
      marginTop: 2,
      marginLeft: 2,
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 0
    })
  })
);

const ImageContainer = styled('div')(({ theme }) => ({
  height: 50,
  width: 50,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[200],
  float: 'left',
  marginRight: 10,
  marginBottom: 10,
  border: `1px solid ${theme.palette.grey[300]}`,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 5
  }
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: 5,
  borderRadius: 4,
  border: `1px solid ${theme.palette.primary.main}`,
  '& svg': {
    maxWidth: 13,
    maxHeight: 13
  }
}));

const ActivityCategoryCard: React.FC<IActivityCategoryCardProps> = ({
  object,
  checked,

  onCheck
}) => {
  const { id, name, category, images } = object;

  const renderedImages = useMemo(() => {
    if (!images) return [];
    if ((images?.length || 0) <= 3) {
      return images || [];
    }
    return images?.slice(-3)?.reverse();
  }, [images]);

  return (
    <>
      <Card>
        <Grid container>
          <Grid item xs={8}>
            <Stack direction="row" alignContent={'center'}>
              <StyledCheckbox
                disableRipple
                checked={checked}
                overlap={false}
                onChange={() => onCheck(object.id)}
              />
              &nbsp;
              <Title> {name}</Title>
            </Stack>
            <Typography variant="caption">Object Type: {category}</Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={12} mt={2}>
            <Box>
              {renderedImages &&
                renderedImages.map((image: any, index: number) => (
                  <>
                    <ImageContainer key={index}>
                      <img src={image.thumb_url || ''} draggable={false} />
                    </ImageContainer>
                  </>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ActivityCategoryCard;
