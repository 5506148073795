/* eslint-disable  */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  styled
} from '@mui/material';
import { Button, Modal, Typography } from 'components';
import { useConfirm } from 'plugins/confirm';
import { useSnack } from 'plugins/snack';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigCameras } from 'store/config/schema';
import {
  applyToAll
  // deleteCropItem,
  // deleteRoiItem,
  // editRoiItem,
  // toggleROI
} from 'store/config/configSlice';
import { getVideoDuration, secondsToFriendlyTime } from 'utils';

import { ROI } from './Canvas';
import CroppedItem from './cropItem';
import ROIItem from './roiItem';
import { selectCameras } from 'store/config/configSlice';
import ApplyModel from './applyModel';
import { toggleROI } from 'store/configSlice';
import { RootState } from 'store';

interface VideoEditorAccodionProps {
  camera: ConfigCameras;
  expanded: boolean;

  onChange: (expanded: boolean) => void;
  onCropEdit: (idx: number, start: number, end: number) => void;
  onRoiEdit: (idx: number) => void;
  tempRoi?: ROI | null;
  actionState: string;
  onRefreshRoi?: () => void;
  deleteRoiItem: (roiId: string) => void;
  deleteCropItem: (idx: number) => void;
  camerasLen: number;
}

const StyledAccordion = styled(Accordion)(({ theme, expanded }) => ({
  padding: theme.spacing(0),
  boxShadow: '0px 3px 6px #00000033 !important',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  },
  '&.Mui-expanded': {
    color: `${theme.palette.primary.contrastText} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    padding: `${theme.spacing(2)} !important`,
    paddingTop: `0 !important`,
    '& .MuiAccordionSummary-root': {
      padding: `0 !important`
    },
    '& .MuiAccordionSummary-expandIconWrapper > svg': {
      fill: `${theme.palette.primary.contrastText}`
    }
  },
  borderRadius: '0px !important'
}));

const StyledAccordionSummary = styled(AccordionSummary)(({}) => ({}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: `${theme.palette.primary.contrastText} !important`,
  color: `${theme.palette.primary.main} !important`,
  borderRadius: '3px'
}));

const StyledAccordionDetailsOverlay = styled('div')(({}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#ffffff99',
  borderRadius: '3px',
  zIndex: 1
}));

const AccordianDetails: React.FC<PropsWithChildren & { disabled: boolean }> = ({
  children,
  disabled
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <StyledAccordionDetails>
        {children}
        {disabled && <StyledAccordionDetailsOverlay />}
      </StyledAccordionDetails>
    </div>
  );
};

export const VideoEditorAccordion: React.FC<VideoEditorAccodionProps> = ({
  camera,
  expanded,
  tempRoi,
  onChange,
  onCropEdit,
  onRoiEdit,
  camerasLen,
  onRefreshRoi,
  deleteRoiItem,
  deleteCropItem,
  actionState
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const snack = useSnack();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedCrops, setSelectedCrops] = React.useState<number[]>([]);
  const [selectedRois, setSelectedRois] = React.useState<number[]>([]);
  const currentCameraId = useSelector(
    (state: RootState) => state.configV2.currentCameraId
  );
  const allCropsSelected = useMemo(() => {
    return selectedCrops.length
      ? selectedCrops.length === camera?.crops?.length
      : false;
  }, [selectedCrops, camera?.crops]);

  const handleAllCropsCheck = () => {
    if (allCropsSelected) {
      setSelectedCrops([]);
    } else {
      setSelectedCrops(camera?.crops.map((_, index) => index));
    }
  };

  const handleCropCheck = (index: number) => {
    if (selectedCrops.includes(index)) {
      setSelectedCrops(selectedCrops.filter(item => item !== index));
    } else {
      setSelectedCrops([...selectedCrops, index]);
    }
  };

  const handleRoiCheck = (index: number) => {
    if (selectedRois.includes(index)) {
      setSelectedRois(selectedRois.filter(item => item !== index));
    } else {
      setSelectedRois([...selectedRois, index]);
    }
  };

  const handleToggleRoi = (idx: number) => {
    dispatch(toggleROI({ cameraId: camera.id, idx }));
  };

  const cameras = useSelector(selectCameras);
  const [checkedCameras, setCheckedCameras] = useState<string[]>([]);
  useEffect(() => {
    if (cameras) {
      const currentCamera = cameras?.find(
        camera => camera?.id === currentCameraId
      );
      if (currentCamera) {
        const initialCheckedCameras = cameras
          ?.filter(
            camera =>
              camera.id !== currentCameraId &&
              (currentCamera?.video_metadata?.duration ?? 0) <=
                (camera.video_metadata?.duration ?? 0)
          )
          ?.map(camera => camera?.id);
        setCheckedCameras(initialCheckedCameras);
      }
    }
  }, [cameras, currentCameraId, open]);

  const handleCheckboxChange = (cameraId: string) => {
    setCheckedCameras(prevCheckedCameras =>
      prevCheckedCameras.includes(cameraId)
        ? prevCheckedCameras.filter(id => id !== cameraId)
        : [...prevCheckedCameras, cameraId]
    );
  };
  const handleApplyToAll = useCallback(() => {
    dispatch(
      applyToAll({
        cameraId: currentCameraId?.toString() ?? '',
        cropIndexes: selectedCrops,
        roiIndexes: selectedRois,
        checkedCameras: checkedCameras
      })
    );
    snack({
      message: 'Applied to selected cameras successfully',
      severity: 'success'
    });
    setOpen(false);
    // confirm({
    //   title: 'Apply to All',
    //   content:
    //     'Are you sure you want to apply this to all cameras?. This action will override the existing data.'
    // }).then(() => {});
  }, [dispatch, currentCameraId, selectedCrops, selectedRois, checkedCameras]);

  const handleEditRoi = (idx: number) => {
    // dispatch(editRoiItem({ idx }));
    onRoiEdit(idx);
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={(e, expanded) => onChange(expanded)}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon fill="#fff" />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack direction="column" spacing={0}>
          <Typography variant="subtitle2" fontWeight={'bold'}>
            {camera.name}
          </Typography>
          <div>
            <Typography variant="caption">
              Total Duration:{' '}
              <span>
                {secondsToFriendlyTime(camera.video_metadata?.duration || 0)}
              </span>
            </Typography>
          </div>
        </Stack>
      </StyledAccordionSummary>
      <Stack direction="column" spacing={2}>
        {/* Crop Accordian Start */}
        <AccordianDetails disabled={actionState == 'crop'}>
          <Typography variant="subtitle2" fontWeight={'bold'}>
            Cropped Video Details
          </Typography>
          <Stack direction="column" spacing={1}>
            {camera?.crops.map((item, index) => (
              <CroppedItem
                title={`Cropped Video ${index + 1}`}
                duration={secondsToFriendlyTime(item.end - item.start)}
                timeDuration={`${secondsToFriendlyTime(
                  item.start
                )} - ${secondsToFriendlyTime(item.end)}`}
                onEdit={() => onCropEdit(index, item.start, item.end)}
                onDelete={() => deleteCropItem(index)}
                checked={selectedCrops.includes(index)}
                onCheck={() => {
                  handleCropCheck(index);
                }}
                hasAction={true}
              />
            ))}
            {camera?.crops.length > 0 && (
              <>
                <Divider />
                <CroppedItem
                  title={`Total Cropped Video`}
                  duration={secondsToFriendlyTime(
                    camera?.crops?.reduce(
                      (acc, item) => acc + item.end - item.start,
                      0
                    )
                  )}
                  checked={allCropsSelected}
                  onCheck={handleAllCropsCheck}
                  hasAction={false}
                />
              </>
            )}
          </Stack>
        </AccordianDetails>
        {/* Crop Accordian End */}

        {/* ROI Accordian Start */}
        <AccordianDetails disabled={actionState == 'roi'}>
          <Typography variant="subtitle2" fontWeight={'bold'}>
            ROI Details
          </Typography>
          {camera.roi.map((item, index) => (
            <ROIItem
              title={`ROI -${index}`}
              checked={selectedRois.includes(index)}
              color={item.color}
              hidden={!item.isVisible}
              onToggle={() => handleToggleRoi(index)}
              onCheck={() => handleRoiCheck(index)}
              onEdit={() => handleEditRoi(index)}
              onDelete={() => {
                deleteRoiItem(item?.id);
              }}
              hasAction={true}
            />
          ))}
        </AccordianDetails>
        {/* ROI Accordian End */}

        <Button variant="outlined" version="light">
          Map Cropped Video And ROI
        </Button>
        <Stack direction="row" spacing={2} width={'100%'}>
          <Button variant="outlined" version="light" fullWidth disabled>
            Copy Selected
          </Button>
          <Button
            variant="outlined"
            version="light"
            fullWidth
            disabled={
              selectedCrops.length === 0 ||
              selectedRois.length === 0 ||
              camerasLen === 1
            }
            onClick={() => setOpen(true)}
          >
            Apply to All
          </Button>
        </Stack>
      </Stack>
      {open && (
        <Modal open={true} onClose={() => setOpen(false)} size="md">
          <Typography variant="subtitle1" fontWeight={'bold'}>
            The Crop section and ROI will apply only on selected videos, Since
            the remaining videos doesn't meet the Requirements.
          </Typography>

          <ApplyModel
            cameras={cameras}
            checkedCameras={checkedCameras}
            handleCheckboxChange={handleCheckboxChange}
            currentCameraId={currentCameraId}
          />
          <Stack spacing={2} direction="row" justifyContent={'center'}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(false)}
              version="light"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={checkedCameras.length === 0}
              onClick={handleApplyToAll}
            >
              Add to Selected
            </Button>
          </Stack>
        </Modal>
      )}
    </StyledAccordion>
  );
};

export default VideoEditorAccordion;
