/* eslint-disable */
import { Formik } from 'formik';
import React from 'react';
import { TextField, SelectField } from 'components/Form';
import { Button } from 'components';
import { Box, Grid, Stack } from '@mui/material';
import * as Yup from 'yup';

import { ConfigCameras } from 'store/config/schema';
import { useSyncObject } from 'api/sdk';
import { useSnack } from 'plugins/snack';
import { faker } from '@faker-js/faker';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
interface FormValues {
  new_name: string;
  category: string;
}

interface IUpdateObjectFormProps {
  onClose: () => void;
  onSaved: (values: FormValues) => void;
  data: {
    name: string;
    category: string;
  };
}

const validationSchema = Yup.object().shape({
  new_name: Yup.string()
    .trim()
    .required('Name is required')
    .max(120, 'Name must be at most 120 characters'),
  category: Yup.string().required('Category is required')
});

const CreateObjectForm: React.FC<IUpdateObjectFormProps> = ({
  data: { name: new_name, category },
  onClose,
  onSaved
}) => {
  const initialValues: FormValues = {
    new_name: '',
    category: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSaved(values);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ m: 2 }} boxSizing={'border-box'}>
            <Stack spacing={3}>
              <TextField
                name="new_name"
                label="New Object Name"
                variant="outlined"
              />
              <SelectField
                name="category"
                label="Object Type"
                options={[
                  { value: 'People', label: 'People' },
                  { value: 'Moving Object', label: 'Moving Object' },
                  { value: 'Stationary Object', label: 'Stationary Object' }
                ]}
                variant="outlined"
              />
              <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
              >
                <Button
                  type="submit"
                  variant="contained"
                  version="light"
                  fullWidth
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" fullWidth>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateObjectForm;
