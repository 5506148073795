/* eslint-disable  */
import React, { useEffect, useRef, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

//image crop
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop
} from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import Tesseract from 'tesseract.js';
import 'react-image-crop/dist/ReactCrop.css';

import { ListBox } from './cardlistbox';
import { Button, Card, VideoLoader } from 'components';
import { BoundingBoxList } from './BoundingBoxList';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { addBondingBoxRoiItem } from 'store/config/configSlice';
import { SelectInput } from 'components/Form';
import SaveRoiAction from './roiActions';
import html2canvas from 'html2canvas';

import { generateVideoThumbnailViaUrl, getVideoCover } from 'utils/thumbnail';
import { faker } from '@faker-js/faker';
import {
  requestImageUploadUrl,
  useAddToActivityAssist,
  useGetConfiguration
} from 'api/sdk';
import { useParams } from 'react-router-dom';
import { Snack } from 'plugins/snack/Snack';
import { RootState } from 'store';
import { ConfigCameras } from 'store/config/schema';
import useVideo from 'hooks/video';
interface ActionState {
  state: 'initial' | 'roi';
  type?: 'roi';
  itemIndex?: number;
}
const VideoPlayer = styled('video')<{ disableControl: boolean }>(
  ({ disableControl }) => {
    return {
      '&::-webkit-media-controls': {
        display: disableControl ? 'none' : 'initial'
      }
    };
  }
);
interface IObjectAnnotationProps {
  cameras: ConfigCameras[] | undefined;
  currentCameraId: string | undefined;
  OnCameraChange: (id: string) => void;
  currentCamera: ConfigCameras | undefined;
  videoContainerRef: any;
  object: any;
  onRefresh: () => void;
  onActivity: () => void;
  OnTrigger: () => void;
  showReview: boolean;
  onRedirect: () => void;
  OnReturn: () => void;
  trainingPipelineStatus: string | boolean | undefined;
  onApply: (btn: number) => void;
  Apply: boolean | undefined;
}

// interface AnnotationShape {
//   x: number;
//   y: number;
//   width: number;
//   height: number;
//   id: string;
// }
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ObjectAnnotationPage: React.FC<IObjectAnnotationProps> = ({
  currentCameraId,
  OnCameraChange,
  cameras,
  videoContainerRef,
  object,
  onRefresh,
  currentCamera,
  OnTrigger,
  OnReturn,
  onActivity,
  showReview,
  trainingPipelineStatus,
  onApply,
  Apply
}) => {
  const {
    playing,
    duration,
    seek,

    reset,
    loading: loadingVideo,
    setVideoLoading,
    videoRef
  } = useVideo();

  const [uploadError, setError] = React.useState<string | undefined>();
  const [actionState, setActionState] = React.useState<ActionState>({
    state: 'initial'
  });
  //Roi creation for boundingboxes

  const [canvasVersion, setCanvasVersion] = React.useState<number>(1);
  const [size, setSize] = React.useState<{ width: number; height: number }>({
    width: 0,
    height: 0
  });
  const dispatch = useDispatch();

  const NewConfigID = useSelector((state: RootState) => state.configV2.id);

  const { data, refetch } = useGetConfiguration(
    {
      config_id: NewConfigID || ''
    },
    {
      query: {
        enabled: !!NewConfigID
      }
    }
  );

  const [showActionBtn, setShowActionBtn] = React.useState(false);
  const [showCanvas, setShowCanvas] = React.useState(false);

  const [savedisable, setSaveDisable] = React.useState(false);

  useEffect(() => {
    reset(currentCamera?.video_url || '');
  }, [currentCamera?.video_url]);

  const setVideoContainerSize = () => {
    if (videoContainerRef.current) {
      const rect = videoContainerRef.current.getBoundingClientRect();
      setSize({ width: rect.width, height: rect.height });
    }
  };
  useEffect(() => {
    if (videoContainerRef.current) {
      setVideoContainerSize();
      window.addEventListener('resize', setVideoContainerSize);
    }
    return () => {
      window.removeEventListener('resize', setVideoContainerSize);
    };
  }, [videoContainerRef.current]);
  const handleAddRoiAction = () => {
    setSaveDisable(false);
    setShowActionBtn(true);
    setActionState({ state: 'roi' });
  };

  const handleRefreshRoiAction = () => {
    setVideoLoading(true); // Set the video loading state to true
    setTimeout(() => {
      setVideoLoading(false); // Set the video loading state back to false after fration of seconds
    }, 100);
  };
  const [cover, setCover] = useState<string | null>(null);
  const [FrameSrc, setFrame] = useState('');

  const getImageDimensions = (
    src: string
  ): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = src;
    });
  };
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    if (cover) {
      getImageDimensions(cover)
        .then(dimensions => {
          setDimensions(dimensions);
        })
        .catch(err => {
          console.error('Error loading image:', err);
        });
    }
  }, [cover]);

  const [currentTime, setCurrentTime] = useState<number>(0);

  // Functions to update currentTime
  const handleUpdateTime = (newTime: number) => {
    setCurrentTime(newTime);
  };
  useEffect(() => {
    if (currentCamera) {
      generateVideoThumbnailViaUrl(
        currentCamera?.video_url ?? '',
        currentTime
      ).then(res => {
        setCover(res);
      });
      setImgSrc(cover ?? '');
    }
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, [currentTime, currentCamera]);

  useEffect(() => {
    if (cover) {
      setImgSrc(cover);
    }
  }, [cover]);

  //video crop
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>();
  const [boundingBox, setBoundingBox] = useState<number[] | null>(null);
  const [status, setStatus] = useState<'idle' | 'uploading' | 'done' | 'fail'>(
    'idle'
  );
  const { mutateAsync: addToPipeline, isLoading: loadingPipeline } =
    useAddToActivityAssist();
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        const base64DataNew = previewCanvasRef.current.toDataURL('image/jpeg');
        setCroppedImage(base64DataNew);
        // Calculate bounding box coordinates
        const boundingBox = [
          Math.round(completedCrop.x),
          Math.round(completedCrop.y),
          Math.round(completedCrop.x + completedCrop.width),
          Math.round(completedCrop.y + completedCrop.height)
        ];
        setBoundingBox(boundingBox);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  // Get the Base64 encoded image data

  const xhr = new XMLHttpRequest();
  const getPresignedUrlFun = (filename: string) => {
    if (currentCamera) {
      return requestImageUploadUrl({
        camera_id: currentCamera.id,
        config_id: NewConfigID || '',
        filename: filename,
        type: 'objects'
      }).then(res => res.data);
    } else {
      return Promise.reject(new Error('Current camera is undefined.'));
    }
  };
  const getFrameUrlFun = (filename: string) => {
    if (currentCamera) {
      return requestImageUploadUrl({
        camera_id: currentCamera.id,
        config_id: NewConfigID || '',
        filename: filename,
        type: 'clips'
      }).then(res => res.data);
    } else {
      return Promise.reject(new Error('Current camera is undefined.'));
    }
  };

  const handleSaveRoi = async () => {
    if (!croppedImage) {
      setError('Base64 data is undefined.');
      setStatus('fail');
      return;
    }

    setSaveDisable(true);

    try {
      const base64Image = croppedImage.replace(/^data:image\/jpeg;base64,/, '');
      const blob = base64toBlob(base64Image, 'image/jpeg');

      const base64FrameImg =
        cover && imgSrc.replace(/^data:image\/jpeg;base64,/, '');
      const blob1 = base64toBlob(base64FrameImg ?? '', 'image/jpeg');

      // Generate a filename using faker for the frame image
      const filename1 = faker.string.uuid() + '.jpeg';
      const frameUploadResponse = await getFrameUrlFun(filename1);
      const frameUrl = frameUploadResponse.url;

      // Upload the frame image
      await uploadImage(frameUrl, blob1, 'frame');
      const frameUrlFinal = frameUploadResponse.url;

      // Generate a filename using faker for the cropped image
      const filename = faker.string.uuid() + '.jpeg';
      const imageUploadResponse = await getPresignedUrlFun(filename);
      const imageUrl = imageUploadResponse.url;

      // Upload the cropped image
      await uploadImage(imageUrl, blob, 'cropped');

      // Dispatch with both URLs
      dispatch(
        addBondingBoxRoiItem({
          cameraId: currentCameraId ?? '',
          id: faker.string.uuid(),
          images: imageUploadResponse.url,
          index: actionState.itemIndex,
          frames: frameUrlFinal,
          frame_number: currentTime,
          bounding_box: boundingBox ?? [],
          frame_width: dimensions?.width ?? 0,
          frame_height: dimensions?.height ?? 0
        })
      );

      // Reset the state
      setStatus('done');
      setActionState({ state: 'initial' });
      setCanvasVersion(prev => prev + 1);
      setShowActionBtn(false);
      setShowCanvas(true);
      setCroppedImage(null);
      setImgSrc('');
      setCrop(undefined);
      setCompletedCrop(undefined);
      setScale(1);
      setRotate(0);
      setAspect(undefined);
      setCurrentTime(0);
    } catch (error) {
      setError('Something went wrong while uploading image.');
      setStatus('fail');
    }
  };

  const uploadImage = (
    url: string,
    blob: Blob,
    type: string
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', 'image/jpeg');
      setStatus('uploading');
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(new Error(`Failed to upload ${type} image`));
          }
        }
      };

      xhr.send(blob); // Send the blob
    });
  };

  // Function to convert base64 to Blob
  function base64toBlob(base64Data: string, contentType: string) {
    const byteCharacters = atob(base64Data ?? '');
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: contentType });
  }
  const [refreshKey, setRefreshKey] = useState(false);
  const { camera } = useParams<{ camera: string }>();

  const handleUpdateClick = () => {
    // Call the updateCamera function if it's defined
    addToPipeline({
      params: {
        config_id: NewConfigID || ''
      }
    })
      .then(() => {
        Snack({
          message: 'Pipeline started successfully',
          severity: 'success'
        });
        onActivity();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          overflowY: 'auto'
        }}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={8} md={12}>
              <Stack spacing={2} direction={'column'}>
                <div
                  id="videoContainer"
                  ref={videoContainerRef}
                  style={{
                    position: 'relative'
                  }}
                >
                  {loadingVideo && <VideoLoader height={400} />}

                  <VideoPlayer
                    id="video"
                    ref={videoRef}
                    controls
                    style={{ width: '100%', height: 'auto' }}
                    controlsList="nodownload"
                    disablePictureInPicture
                    muted
                    autoPlay
                    disableControl={actionState.state !== 'initial'}
                    hidden={loadingVideo}
                  ></VideoPlayer>
                  {showActionBtn && (
                    <>
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%'
                        }}
                      >
                        {!!imgSrc && (
                          <ReactCrop
                            style={{ width: '100%', height: 'auto' }}
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={c => setCompletedCrop(c)}
                            aspect={aspect}
                          >
                            <img
                              ref={imgRef}
                              alt="Crop me"
                              src={`${imgSrc}`}
                              style={{
                                transform: `scale(${scale}) rotate(${rotate}deg)`,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                              }}
                              onLoad={onImageLoad}
                            />
                          </ReactCrop>
                        )}
                      </div>

                      <div>
                        <canvas
                          style={{ display: 'none' }}
                          ref={previewCanvasRef}
                        ></canvas>
                      </div>
                    </>
                  )}
                  {/* <img src={imgArr}></img> */}
                  {currentCamera && showActionBtn && (
                    <Card>
                      {
                        {
                          initial: <></>,

                          roi: (
                            <>
                              <SaveRoiAction
                                duration={duration}
                                playing={playing}
                                onUpdateTime={handleUpdateTime}
                                currentTime={currentTime}
                                onPlay={() => videoRef.current?.play()}
                                onPause={() => videoRef.current?.pause()}
                                seek={seek}
                                onSave={() => handleSaveRoi()}
                                onCancel={() => {
                                  setShowActionBtn(false);
                                  setActionState({ state: 'initial' });
                                  setCroppedImage(null);
                                  setImgSrc('');
                                  setCrop(undefined);
                                  setCompletedCrop(undefined);
                                  setScale(1);
                                  setRotate(0);
                                  setAspect(undefined);
                                  setCurrentTime(0);
                                  setSaveDisable(false);
                                }}
                                saveDisabled={false}
                                savedisableBtn={savedisable}
                                onReset={() => {
                                  setShowActionBtn(true);

                                  setCroppedImage(null);
                                  setImgSrc('');
                                  setCrop(undefined);
                                  setCompletedCrop(undefined);
                                  setScale(1);
                                  setRotate(0);
                                  setAspect(undefined);
                                  setCurrentTime(0);
                                  setSaveDisable(false);
                                }}
                              />
                            </>
                          )
                        }[actionState.state]
                      }
                    </Card>
                  )}
                </div>
              </Stack>
            </Grid>
            <Grid item lg={4} md={12}>
              <Stack spacing={2}>
                <SelectInput
                  name="scene"
                  options={
                    cameras?.map((camera, index) => ({
                      label: camera.name,
                      value: camera.id
                    })) || []
                  }
                  value={String(currentCameraId)}
                  variant="outlined"
                  onChange={value => {
                    setShowActionBtn(false);
                    OnCameraChange(value);
                  }}
                />

                <Stack spacing={2}>
                  <ListBox
                    Uploadstatus={status}
                    object={object}
                    actionState={actionState.state as string}
                    onAddRoi={handleAddRoiAction}
                    currentCamera={currentCamera}
                    onRefreshRoi={handleRefreshRoiAction}
                    onRefresh={onRefresh}
                  />
                </Stack>
              </Stack>
              <div
                style={{
                  overflowY: 'auto',
                  height: '40vh'
                }}
              >
                <Stack spacing={2} mt={2}>
                  <>
                    {currentCamera &&
                      object &&
                      object?.objects &&
                      object?.objects.map(
                        (cameraObject: any, index: number) => (
                          <BoundingBoxList
                            key={index}
                            title={cameraObject.name}
                            objectType={cameraObject.category}
                            images={cameraObject.images || []}
                          />
                        )
                      )}
                  </>
                </Stack>
              </div>
              <Stack direction="row" spacing={2} width={'100%'} mt={2}>
                <Button
                  variant="outlined"
                  version="light"
                  fullWidth
                  disabled={
                    showReview || object?.objects.length == 0 || !object
                  }
                  onClick={() => {
                    OnTrigger();
                  }}
                >
                  Save And Update
                </Button>
                <Button
                  variant="outlined"
                  version="dark"
                  fullWidth
                  disabled={Apply}
                  onClick={() => {
                    onApply(4);
                  }}
                  style={{ color: 'white' }}
                >
                  {Apply ? 'Saving...' : 'Apply'}
                </Button>
              </Stack>
              <Stack direction="row" spacing={2} width={'100%'} mt={2}>
                {trainingPipelineStatus === true && (
                  <Button
                    variant="outlined"
                    version="dark"
                    fullWidth
                    // disabled
                    onClick={() => {
                      OnReturn();
                    }}
                    style={{ color: 'white' }}
                  >
                    Return
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ObjectAnnotationPage;
