/* eslint-disable  */
import {
  Grid,
  IconButton,
  TextField,
  Slider,
  Stack,
  Box,
  styled
} from '@mui/material';
import React from 'react';
import { secondsToHms } from 'utils/date';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Button, Typography } from 'components';

interface AddRoiActionProps {
  duration: number;
  playing: boolean;
  saveDisabled: boolean;
  savedisableBtn: boolean;
  onPlay: () => void;
  onPause: () => void;
  seek: (time: number) => void;
  onSave: () => void;
  onCancel: () => void;
  onReset: () => void;
  currentTime: number;
  onUpdateTime: (newTime: number) => void;
}

const StyledInput = styled(TextField)({
  width: '100px'
});

const SaveRoiAction: React.FC<AddRoiActionProps> = ({
  playing,
  duration,
  onPlay,
  saveDisabled,
  savedisableBtn,
  onUpdateTime,
  currentTime,
  onPause,
  seek,
  onSave,
  onCancel,
  onReset
}) => {
  const [cursor, setCursor] = React.useState<'start' | 'end'>('start');

  const [end, setEnd] = React.useState<number>(duration - 1);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const val = newValue as number;
    // setCurrentTime(val);
    onUpdateTime(val);
    seek(val);
  };

  const valuetext = (value: number) => {
    return secondsToHms(value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={2} direction={'row'} paddingTop={2} paddingRight={3}>
            <IconButton
              aria-label="play"
              onClick={() => (playing ? onPause() : onPlay())}
              disableRipple
            >
              {playing ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <Slider
              style={{ marginTop: '5px' }}
              getAriaLabel={() => 'Temperature range'}
              value={currentTime}
              min={0}
              max={duration}
              onChange={handleChange}
              valueLabelDisplay="auto"
              valueLabelFormat={valuetext}
              disableSwap
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack spacing={2} direction={'row'} paddingLeft={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body1">Start Time</Typography>
                  <StyledInput
                    value={secondsToHms(currentTime)}
                    onChange={() => {}}
                    disabled
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                <Button variant="outlined" version="light" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="contained" version="light" onClick={onReset}>
                  Reset Points
                </Button>
                <Button
                  variant="contained"
                  version="light"
                  onClick={onSave}
                  disabled={savedisableBtn}
                >
                  {savedisableBtn ? 'Saving' : 'Save'}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default SaveRoiAction;
