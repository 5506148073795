/* eslint-disable  */
import { Stack } from '@mui/material';
import { Button } from 'components';

import React from 'react';

interface IActionsProps {
  onAddCrop: () => void;
  onAddRoi: () => void;
}

const Actions: React.FC<IActionsProps> = ({ onAddCrop, onAddRoi }) => {
  return (
    <>
      <Stack
        spacing={2}
        direction={'row'}
        paddingTop={2}
        paddingRight={3}
        justifyContent={'right'}
      >
        <Button variant="contained" onClick={onAddRoi} version="light">
          Add ROI
        </Button>
        <Button variant="contained" onClick={onAddCrop} version="light">
          Add Crop
        </Button>
      </Stack>
    </>
  );
};

export default Actions;
