/* eslint-disable */
import { useEffect, useRef, useState } from 'react';

interface Resolution {
  width: number;
  height: number;
}

const useVideo = (url: null | string = null) => {
  const [videoUrl, updateVideoUrl] = useState<string | null>(url);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [resolution, setResolution] = useState<Resolution>({
    width: 0,
    height: 0
  });
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0
  });

  const seek = (time: number) => {
    videoRef.current!.currentTime = time;
  };

  const play = () => {
    videoRef.current?.play();
  };

  const pause = () => {
    videoRef.current?.pause();
  };

  const setVideoLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  useEffect(() => {
    if (!videoRef.current) return;

    const video = videoRef.current;

    const handleLoadedMetadata = () => {
      setDuration(video.duration);
      setResolution({ width: video.videoWidth, height: video.videoHeight });
      setLoading(false);
      setError(false);
    };

    const handlePlay = () => {
      setPlaying(true);
    };

    const handlePause = () => {
      setPlaying(false);
    };

    const handleEnded = () => {
      setPlaying(false);
    };

    const handleTimeUpdate = () => {
      // setCurrentTime(Math.floor(video.currentTime * 100) / 100);
      if (video.currentTime === video.duration) {
        setPlaying(false);
      } else {
        setPlaying(true);
      }
    };

    const handleSeeking = () => {
      setSeeking(true);
    };

    const handleSeeked = () => {
      setSeeking(false);
    };

    const handleError = () => {
      setError(true);
    };

    const handleWaiting = () => {
      setWaiting(true);
    };

    const handlePlaying = () => {
      setWaiting(false);
    };

    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    video.addEventListener('play', handlePlay);
    video.addEventListener('pause', handlePause);
    video.addEventListener('ended', handleEnded);
    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('seeking', handleSeeking);
    video.addEventListener('seeked', handleSeeked);
    video.addEventListener('error', handleError);
    video.addEventListener('waiting', handleWaiting);
    video.addEventListener('playing', handlePlaying);

    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('ended', handleEnded);
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('seeking', handleSeeking);
      video.removeEventListener('seeked', handleSeeked);
      video.removeEventListener('error', handleError);
      video.removeEventListener('waiting', handleWaiting);
      video.removeEventListener('playing', handlePlaying);
    };
  }, [videoRef]);

  const reset = (url: string | null) => {
    if (!videoRef.current) return;

    const video = videoRef.current;

    setDuration(0);
    setPlaying(false);
    setResolution({ width: 0, height: 0 });
    updateVideoUrl(url);

    if (!url) return;
    setLoading(true);

    video.src = url;
    video.load();
  };

  const setPlayerSize = () => {
    if (!videoRef.current) return;

    const video = videoRef.current;
    const width = video.clientWidth;
    const height = video.clientHeight;

    setSize({ width, height });
  };

  useEffect(() => {
    setPlayerSize();
    window.addEventListener('resize', setPlayerSize);

    return () => {
      window.removeEventListener('resize', setPlayerSize);
    };
  }, [videoRef.current]);

  return {
    videoRef,
    videoUrl,
    duration,
    playing,
    resolution,
    size,
    loading,
    seeking,
    error,
    currentTime,
    seek,
    play,
    pause,
    reset,
    updateVideoUrl,
    setVideoLoading,
    waiting
  };
};

export default useVideo;
