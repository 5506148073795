import { useCallback, useEffect, useRef, useState } from 'react';
import { useInferenceSocket } from './useInferenceSocket';
import useVideo from './video';
import Peer, { SignalData } from 'simple-peer';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { setPreventNavigation } from 'store/preventNavigation/preventNavigationSlice';
import { useSnack } from 'plugins/snack';

export const useLiveInference = () => {
  const [stream, setStream] = useState<MediaStream>();
  const [modelData, setModelData] = useState<any>();
  const [started, setStarted] = useState(false);
  const [isReseting, setResetting] = useState(false);
  const [activityData, setActivityData] = useState<any>([]);
  const [hasMultipleCameras, setHasMultipleCameras] = useState(false);
  const connectionRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceIds, setDeviceIds] = useState<string[]>([]);
  const [image, setImage] = useState<any>();
  const [streamError, setStreamError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const snack = useSnack();
  const { videoRef } = useVideo();

  const { socket, sendMessage, connectionStatus } = useInferenceSocket();

  const handleMessageReceived = (message: any) => {
    const messageType = message?.type;
    const data = message?.data;

    switch (messageType) {
      case 'answer':
        connectionRef.current?.signal(data);
        break;
      case 'live_inf_output':
        setActivityData((prevData: any) => {
          console.log('data activity', data.activity);
          let activityName = data.activity.activity;
          let updatedData = [...prevData];

          if (
            updatedData.length > 0 &&
            updatedData[updatedData.length - 1].activity === activityName
          ) {
            updatedData[updatedData.length - 1] = data.activity;
            return updatedData;
          } else {
            activityName = data.activity.activity;
            return [...prevData, data.activity];
          }
        });
        break;
      case 'success':
        snack({
          message: message.message,
          severity: 'success'
        });
        setResetting(false);
        break;
      case 'error':
        setStreamError(message.message);
        break;
      case 'loaded':
        setLoading(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    socket?.addEventListener('message', (event: any) => {
      if (event.data instanceof Blob) {
        // Handle Blob (assuming it's an image)
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result as string;
          setImage(imageUrl);
          console.log('image url in the socket', imageUrl);
        };
        reader.readAsDataURL(event.data);
      } else {
        // Handle JSON data
        try {
          const message = JSON.parse(event.data);
          handleMessageReceived(message);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      }
    });

    return () => {
      if (socket) {
        socket.close();
        socket.removeEventListener('message', () => {});
      }
    };
  }, [socket]);

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        const videoInputDevices = devices.filter(
          device => device.kind === 'videoinput'
        );
        setDeviceIds(videoInputDevices.map(device => device.deviceId));
        setHasMultipleCameras(videoInputDevices.length > 1);
      })
      .catch(error => {
        console.error('Error enumerating devices.', error);
      });
    return () => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, []);

  const startStream = (url: string, cameraId: string) => {
    if (socket) {
      sendMessage(
        JSON.stringify({
          event: 'start_live_stream',
          data: {
            camera_id: cameraId,
            url: url
          }
        })
      );
    }
  };

  const endStream = () => {
    if (socket) {
      sendMessage(
        JSON.stringify({
          event: 'end_live_stream'
        })
      );
    }
  };

  useEffect(() => {
    if (!started) {
      setImage('');
    }
  }, [started]);

  const startLive = (url: string, cameraId: string) => {
    // const peerCon = createRTCPeerConnection();
    setStarted(true);
    setImage('');
    setActivityData([]);
    dispatch(setPreventNavigation(true));
    const peer = new Peer({ initiator: true, trickle: false, stream });
    connectionRef.current = peer;
    peer.on('signal', (signal: SignalData) => {
      if (socket) {
        sendMessage(
          JSON.stringify({
            event: 'start_live_inference',
            data: {
              signal: signal,
              modelData: modelData,
              url: url,
              camera_id: cameraId
            }
          })
        );
      }
    });
    peer.on('stream', currentStream => {
      videoRef.current!.srcObject = currentStream;
    });

    peer.on('track', (track, stream) => {
      console.log('track', track, stream);
    });

    peer.on('connect', () => {
      console.log('connected');
    });
  };

  const leaveCall = () => {
    setTimeout(() => setActivityData([]), 3000);
    setStarted(false);
    dispatch(setPreventNavigation(false));
    sendMessage(
      JSON.stringify({
        event: 'end_live_inference'
      })
    );

    if (connectionRef.current) {
      connectionRef.current.destroy();
      connectionRef.current = null;
    }
  };

  return {
    socket,
    videoRef,
    stream,
    startLive,
    loading,
    setLoading,
    started,
    leaveCall,
    startStream,
    streamError,
    endStream,
    activityData,
    hasMultipleCameras,
    setModelData,
    setStreamError,
    image,
    setImage,
    sendMessage,
    isReseting,
    setResetting
  };
};
