/* eslint-disable  */
import * as React from 'react';

import { Button, Card, Modal } from 'components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { deleteBoundingBoxItem, selectConfig } from 'store/config/configSlice';
import styled from 'styled-components';
import BoundingBoxItem from './roiItem';
import { useDispatch, useSelector } from 'react-redux';
import { ROI } from '../selectRoiStep/Canvas';
import CreateObjectForm from './createObjectForm';
import ObjectModel from './moveObjectModel';
import { requestImageUploadUrl } from 'api/sdk';
import { ConfigCameras } from 'store/config/schema';

interface ListBoxProps {
  onAddRoi: () => void;
  actionState: string;
  currentCamera: ConfigCameras | undefined;
  onRefreshRoi: () => void;
  onRefresh: () => void;
  object: any;
  Uploadstatus: string;
}
const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: `5px`,
  backgroundColor: `#ffffff !important`,
  color: `#102633 !important`,
  borderRadius: '3px',
  marginBottom: '10px'
}));
const StyledAccordionDetailsOverlay = styled('div')(({}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#ffffff99',
  borderRadius: '3px',
  zIndex: 1
}));
const ImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: 30,
  width: 30,
  borderRadius: 5,
  overflow: 'hidden', // Ensure the overlay stays within the bounds of the container
  '&::after': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.5)', // semi-transparent white
    animation: '$blink 1s infinite' // Apply the blink animation
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 5,
    filter: 'brightness(0.7)' // Dim the image
  },
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 }
  }
}));
const AccordianDetails: React.FC<
  React.PropsWithChildren & { disabled: boolean }
> = ({ children, disabled }) => {
  return (
    <div style={{ position: 'relative' }}>
      <StyledAccordionDetails>
        {children}
        {disabled && <StyledAccordionDetailsOverlay />}
      </StyledAccordionDetails>
    </div>
  );
};
export const ListBox: React.FC<ListBoxProps> = ({
  onAddRoi,
  object,
  actionState,
  Uploadstatus,
  currentCamera,
  onRefreshRoi,
  onRefresh
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [objModelOpen, setObjOpen] = React.useState(false);

  const handleRoiCheck = (
    id: string,
    images: string,
    frames: string,
    boundingBox: number[],
    frameNumber: number,
    frameWidth: number,
    frameHeight: number
  ) => {
    const index = selectedRois.findIndex(roi => roi.id === id);
    if (index !== -1) {
      setSelectedRois(prevState => prevState.filter(roi => roi.id !== id));
    } else {
      setSelectedRois(prevState => [
        ...prevState,
        {
          id,
          images,
          frames,
          boundingBox,
          frameNumber,
          frameWidth,
          frameHeight
        }
      ]);
    }
  };

  const [selectedRois, setSelectedRois] = React.useState<
    {
      id: string;
      images: string;
      frames: string;
      boundingBox: number[];
      frameNumber: number;
      frameWidth: number;
      frameHeight: number;
    }[]
  >([]);

  const handleDeleteROi = (idx: number) => {
    // Dispatch action to delete the ROI item
    dispatch(
      deleteBoundingBoxItem({
        cameraId: currentCamera?.id?.toString() ?? '',
        idx: idx ?? ''
      })
    );
    // onRefreshRoi();
  };
  const handleSubmit = () => {
    setOpen(false);
  };

  return (
    <Card style={{ background: '#102633', color: 'white' }}>
      <Stack direction="row" justifyContent="space-between" mb={0.5}>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          Bounding Box
        </Typography>
        <Button variant="outlined" version="light" onClick={onAddRoi}>
          ADD
        </Button>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        style={{ overflowY: 'auto', height: '50%' }}
      >
        {currentCamera && Uploadstatus === 'uploading' && (
          <AccordianDetails disabled={false}>
            <Grid container spacing={0} width={'100%'}>
              <Grid item xs={2}></Grid>
              <Grid
                item
                xs={5}
                display="flex"
                flexDirection={'row'}
                style={{
                  paddingTop: '5px'
                }}
              >
                <Typography
                  variant="caption"
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                  fontWeight={'bold'}
                  mt={1}
                >
                  uploading ....
                </Typography>
              </Grid>

              <Grid
                item
                xs={5}
                display="flex"
                flexDirection={'row'}
                justifyContent={'right'}
              >
                <ImageContainer style={{ marginTop: '5px' }}>
                  <></>
                </ImageContainer>
              </Grid>
            </Grid>
          </AccordianDetails>
        )}
        <div
          style={{
            overflowY:
              Uploadstatus === 'done' &&
              currentCamera &&
              currentCamera?.boundingbox?.length >= 3
                ? 'auto'
                : undefined,
            height:
              Uploadstatus === 'done' &&
              currentCamera &&
              currentCamera?.boundingbox?.length >= 3
                ? '15vh'
                : currentCamera && currentCamera?.boundingbox?.length === 0
                ? ''
                : ''
          }}
        >
          {currentCamera &&
            Uploadstatus === 'done' &&
            currentCamera.boundingbox?.map((item, index) => (
              <AccordianDetails disabled={actionState === 'roi'} key={index}>
                <BoundingBoxItem
                  title={`Bounding Box ${index}`}
                  images={item.images}
                  checked={selectedRois.some(roi => roi.id === item.id)}
                  onCheck={() =>
                    handleRoiCheck(
                      item.id,
                      item.images,
                      item.frames,
                      item.bounding_box,
                      item.frame_number,
                      item.frame_width,
                      item.frame_height
                    )
                  }
                  onDelete={() => handleDeleteROi(index)}
                  hasAction={true}
                />
              </AccordianDetails>
            ))}
        </div>
      </Stack>

      <Stack direction="row" spacing={2} width={'100%'} mt={2}>
        <Button
          variant="outlined"
          version="light"
          fullWidth
          disabled={selectedRois?.length === 0}
          onClick={() => setObjOpen(true)}
        >
          Add To Existing Object
        </Button>
        <Button
          variant="outlined"
          version="light"
          fullWidth
          disabled={selectedRois?.length === 0}
          onClick={() => setOpen(true)}
        >
          Create New Object
        </Button>
      </Stack>
      {open && (
        <Modal open={true} onClose={() => setOpen(false)} size="sm">
          <CreateObjectForm
            onRefresh={onRefresh}
            onClose={() => setOpen(false)}
            onSaved={() => handleSubmit()}
            currentCamera={currentCamera}
            selectedRois={selectedRois}
            object={object}
          />
        </Modal>
      )}
      {objModelOpen && (
        <Modal open={true} onClose={() => setObjOpen(false)} size="xl">
          <ObjectModel
            currentCamera={currentCamera}
            object={object}
            selectedRois={selectedRois}
            onClose={() => setObjOpen(false)}
            onRefresh={onRefresh}
          />
        </Modal>
      )}
    </Card>
  );
};
