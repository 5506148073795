/* eslint-disable  */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography,
  styled
} from '@mui/material';
import { useConfirm } from 'plugins/confirm';
import React from 'react';

import { PropsWithChildren } from 'react';
import { ConfigCameras } from 'store/config/schema';

interface IApplyModelProps {
  cameras: ConfigCameras[] | undefined;
  checkedCameras: string[];
  handleCheckboxChange: (cameraId: string) => void;
  currentCameraId: string | undefined;
}

const StyledAccordion = styled(Accordion)(({ theme, expanded }) => ({
  padding: theme.spacing(0),
  boxShadow: '0px 3px 6px #00000033 !important',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  },
  '&.Mui-expanded': {
    color: `${theme.palette.primary.contrastText} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    padding: `${theme.spacing(2)} !important`,
    paddingTop: `0 !important`,
    '& .MuiAccordionSummary-root': {
      padding: `0 !important`
    },
    '& .MuiAccordionSummary-expandIconWrapper > svg': {
      fill: `${theme.palette.primary.contrastText}`
    }
  },
  borderRadius: '0px !important'
}));

const StyledAccordionSummary = styled(AccordionSummary)(({}) => ({}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: `${theme.palette.primary.contrastText} !important`,
  color: `${theme.palette.primary.main} !important`,
  borderRadius: '3px',
  marginBottom: '15px',
  border: '1px solid #102633',
  marginTop: '10px'
}));

const StyledAccordionDetailsOverlay = styled('div')(({}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#ffffff99',
  borderRadius: '3px',
  zIndex: 1
}));

const AccordianDetails: React.FC<PropsWithChildren & { disabled: boolean }> = ({
  children,
  disabled
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <StyledAccordionDetails>
        {children}
        {disabled && <StyledAccordionDetailsOverlay />}
      </StyledAccordionDetails>
    </div>
  );
};

export const ApplyModel: React.FC<IApplyModelProps> = ({
  cameras,
  checkedCameras,
  handleCheckboxChange,
  currentCameraId
}) => {
  const confirm = useConfirm();

  // Find the current camera
  const currentCamera = cameras?.find(camera => camera.id === currentCameraId);

  return (
    <>
      {cameras &&
        cameras.map((camera, index) => {
          if (currentCameraId && camera.id === currentCameraId) {
            return null;
          }

          const isDurationMatch =
            (currentCamera?.video_metadata?.duration ?? 0) <=
            (camera.video_metadata?.duration ?? 0);

          const isDisabled = !isDurationMatch;

          return (
            <AccordianDetails key={camera.id} disabled={false}>
              <Grid container spacing={0} width={'100%'}>
                <Grid item xs={2}>
                  <Checkbox
                    checked={isDurationMatch}
                    disabled={isDisabled}
                    onChange={() => {
                      handleCheckboxChange(camera.id);
                    }}
                    style={{ marginLeft: '-10px' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    paddingTop: '7px',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography variant="body1" fontWeight={'bold'}>
                    {camera?.name}
                  </Typography>
                  <Typography variant="caption" fontWeight={'bold'}>
                    Total Duration: {camera?.video_metadata?.duration}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  display="flex"
                  flexDirection={'row'}
                  justifyContent={'right'}
                ></Grid>
              </Grid>
            </AccordianDetails>
          );
        })}
    </>
  );
};

export default ApplyModel;
