import {
  addToTrainingAssist,
  useAddToActivityAssist,
  useGetAllObjects,
  useGetConfiguration,
  useGetPipelineStatus,
  useLabelAssistInterrupt,
  useSyncObject
} from 'api/sdk';

import { useConfirm } from 'plugins/confirm';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import {
  deleteAllBoundingBoxItems,
  setConfigFromApiResponse,
  setCurrentCameraId
} from 'store/config/configSlice';
import { selectCameras } from 'store/config/configSlice';

import {
  InitialTrainingMessage,
  addObject,
  createObject,
  deleteAllImages,
  deleteObject,
  deleteSelectedImages,
  mergeObjects,
  moveImages,
  updateObjectName,
  updatePipelineStatus
} from 'store/config/objects';
import { selectConfig, selectCurrentCamera } from 'store/config/configSlice';
import { PipelineStatus } from 'types';
import { Snack } from 'plugins/snack/Snack';
import { ObjectsSchema } from 'store/config/schema';

export const useObjectConfiguration = (
  onSuccess: () => void,
  onRedirect: () => void,
  onActivity: () => void,
  onAnalysis: () => void
) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const confirm = useConfirm();
  const [moveModelOpen, setMoveModelOpen] = useState(false);
  const [showReview, setReview] = useState(false);
  const [trigger, seTrigger] = useState(false);
  const [createModelOpen, setCreateModelOpen] = useState<{
    open: boolean;
    data?: {
      name: string;
      category: string;
    };
  }>({
    open: false
  });
  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [updateList, setUpdateList] = useState<boolean>(false);

  const [buttonState, setButtonState] = useState<number | null>(null);
  // this state is to maintain which button is clicked since we are calling the same API in 2 different buttons
  // 2 is for Need more objects and 3 is for  Apply button

  const currentCamera = useSelector(selectCurrentCamera);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const { mutateAsync: addToPipeline, isLoading: loadingPipeline } =
    useAddToActivityAssist();

  const [videoResolution, setVideoResolution] = useState<{
    width: number;
    height: number;
  }>({
    width: currentCamera?.video_metadata?.resolution[0] || 0,
    height: currentCamera?.video_metadata?.resolution[1] || 0
  });
  // reset the video when the current camera changes

  const cameras = useSelector(selectCameras);

  const currentCameraId = useSelector(
    (state: RootState) => state.configV2.currentCameraId
  );
  const analyticsList = useSelector(
    (state: RootState) => state.configV2.analytics
  );

  const setCurrentCamera = (cameraId: string) => {
    dispatch(setCurrentCameraId(cameraId));
  };

  const {
    mutateAsync: syncObject,
    isLoading: Syncloading,

    error: syncError
  } = useSyncObject();
  const { refetch, isLoading, error } = useGetAllObjects(id || '', {
    pipeline_satus: true || null
  });

  const { refetch: list } = useGetConfiguration(
    {
      config_id: id || ''
    },
    {
      query: {
        enabled: !!id
      }
    }
  );
  const apiLoading = isLoading;

  const objects = useSelector((state: RootState) => state.objects.objects);
  const object = (currentCameraId && objects[currentCameraId]) || null;
  const [selectedDestObjects, setSelectedDestObjects] = useState<string | null>(
    null
  );
  const pipeline_status =
    currentCameraId && objects[currentCameraId]?.pipeline_status;
  const training_pipeline_status =
    currentCameraId && objects[currentCameraId]?.trained;

  const pipelineStatuses = useMemo(() => {
    const status: Array<{
      id: string;
      title: string;
      status: PipelineStatus;
      progress: number;
    }> = [];

    cameras?.forEach(camera => {
      const s = objects[camera.id];
      status.push({
        id: camera.id,
        title: camera.name,
        status: s?.pipeline_status,
        progress: s?.pipeline_progress
      });
    });
    return status;
  }, [cameras, objects]);

  /**
   * Update the current camera
   */
  const handleCameraChange = (id: string) => {
    dispatch(setCurrentCameraId(id));
    setSelectedObjects([]);
  };

  useEffect(() => {
    if (id) {
      refetch().then(response => {
        const responseData = response.data?.data;
        if (responseData && typeof responseData === 'object') {
          const dataArray = [responseData];

          dispatch(addObject(dataArray));
        }
      });
    }
  }, [id]);
  useEffect(() => {
    if (updateList) {
      refetch().then(response => {
        const responseData = response.data?.data;
        if (responseData && typeof responseData === 'object') {
          const dataArray = [responseData];

          dispatch(addObject(dataArray));
        }
      });
    }
  }, [updateList]);
  useEffect(() => {
    if (updateList) {
      dispatch(
        deleteAllBoundingBoxItems({
          cameraId: currentCamera?.id?.toString() ?? ''
        })
      );
    }
  }, [updateList]);

  const handlerefresh = () => {
    refetch();
    // Incrementing the key to force a re-render
    setUpdateList(true);

    // Reset refreshKey to false after 3 seconds
    setTimeout(() => {
      setUpdateList(false);
    }, 3000);
  };
  // refetchPipelineStatus().then(response => {
  //   dispatch(updatePipelineStatus(response.data?.data || []));
  // });
  const handleUpdateObjectName = (
    id: string,
    name: string,
    category: string
  ) => {
    dispatch(
      updateObjectName({
        cameraId: currentCameraId || '',
        objectId: id,
        name,
        category
      })
    );
  };

  const handleMergeObjects = (new_name: string, category: string) => {
    const dest = selectedObjects[0];
    const src = selectedObjects.slice(1);
    // Merge objects
    dispatch(
      mergeObjects({
        cameraId: currentCameraId || '',
        srcId: src,
        destId: dest,
        name: new_name,
        category
      })
    );
    setSelectedObjects([]);
  };
  const handleCreateObjects = (
    objectId: string | null,
    new_name: string,
    category: string,
    imageIds: string[]
  ) => {
    // Merge objects
    dispatch(
      createObject({
        cameraId: currentCameraId || '',
        name: new_name,
        images: imageIds,
        srcObject: objectId,
        category
      })
    );
    setSelectedObjects([]);
  };
  const handleMoveImages = (
    objectId: string,
    selectedDestObjects: string | null,
    imageIds: string[]
  ) => {
    const dest = selectedDestObjects;

    // move objects
    dispatch(
      moveImages({
        cameraId: currentCameraId || '',
        srcObject: objectId,
        destObject: dest,
        images: imageIds
      })
    );
    setSelectedObjects([]);
    setMoveModelOpen(false);
  };

  const handleDeleteAllImages = (objectId: string) => {
    currentCameraId &&
      confirm({
        title: 'Delete all images',
        content: 'Are you sure you want to delete all images?'
      }).then(() => {
        dispatch(
          deleteAllImages({
            cameraId: currentCameraId,
            objectId
          })
        );
        setSelectedItems([]);
      });
  };

  const handleDeleteImages = (objectId: string, imageIds: string[]) => {
    currentCameraId &&
      confirm({
        title: 'Delete images',
        content: `Are you sure you want to delete ${imageIds.length} images?`
      }).then(() => {
        dispatch(
          deleteSelectedImages({
            cameraId: currentCameraId,
            objectId,
            images: imageIds
          })
        );
        setSelectedItems([]);
      });
  };
  const handleDeleteObject = (objectId: string) => {
    currentCameraId &&
      confirm({
        title: 'Delete Object',
        content: `Are you sure you want to delete selected Object?`
      }).then(() => {
        dispatch(
          deleteObject({
            cameraId: currentCameraId,
            objectId
          })
        );
      });
  };

  const handleApplyClick = (buttonNumber: number, route?: boolean) => {
    if (!object || !object.objects) {
      console.error('Objects array is null or undefined');
      return;
    }

    const updatedObjects = object.objects.map(obj => ({
      id: obj.id,
      classname: obj.name,
      category: obj.category,
      camera_id: currentCameraId?.toString() ?? '',

      images: obj.images
        ? obj.images.map(imagePath => ({
            id: imagePath.id,
            image_path: imagePath.image_path || null,
            frame_path: null,
            frame_number: null,
            frame_width: null,
            frame_height: null,
            bounding_box: null,
            thumb_url: '' // You can define the thumb_url logic here
          }))
        : []
    }));
    setButtonState(buttonNumber);

    syncObject({
      cameraId: currentCameraId?.toString() ?? '',
      configId: id?.toString() ?? '',
      data: updatedObjects
    }).then(() => {
      setButtonState(null);
      if (route) {
        onSuccess();
      }
      Snack({
        message: 'Objects updated successfully',
        severity: 'success'
      });
    });
  };
  const handleActivityClick = () => {
    const shouldRedirect =
      analyticsList.includes('EMPLOYEE_PRODUCTIVITY') ||
      analyticsList.includes('WORKFLOW_MONITORING');
    if (shouldRedirect) {
      addToPipeline({
        params: {
          config_id: id || ''
        }
      })
        .then(() => {
          Snack({
            message: 'Pipeline started successfully',
            severity: 'success'
          });
          onActivity();
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      onAnalysis();
    }
  };

  const [disableInterrupt, setDisableInterrupt] = useState(false);
  const { mutateAsync: interrupt } = useLabelAssistInterrupt();
  const handleInterruptClick = (cameraId: string) => {
    setDisableInterrupt(true);
    interrupt({
      cameraId: cameraId?.toString() ?? '',
      configId: id?.toString() ?? ''
    }).then(() => {
      Snack({
        message: 'Objects updated successfully',
        severity: 'success'
      });
      setDisableInterrupt(false);
    });
  };
  const handleTTriggerClick = useCallback(() => {
    setReview(true);

    addToTrainingAssist({
      config_id: id?.toString() ?? '',
      is_incremental_training: false
    })
      .then(() => {
        Snack({
          message: 'Pipeline started successfully',
          severity: 'success'
        });
        onRedirect();
        setReview(false);

        const cameraIds = cameras && cameras.map(camera => camera.id);

        dispatch(
          InitialTrainingMessage({
            camera_ids: cameraIds ?? [],
            config_id: id?.toString() ?? '',
            type: 'TRAINING'
          })
        );
      })
      .catch(error => {
        console.log(error);
      });
  }, [addToTrainingAssist, id, cameras, dispatch]);

  const handleReturnClick = useCallback(() => {
    // Call the updateCamera function if it's defined
    setReview(true);
    onRedirect();
    setReview(false);
  }, []);

  return {
    isLoading,
    refetch,
    setCurrentCamera,
    currentCameraId,
    cameras,
    object,
    pipelineStatuses,
    disableInterrupt,
    selectedObjects,
    selectedItems,
    setSelectedItems,
    setSelectedObjects,
    handleCameraChange,
    apiLoading,
    handleUpdateObjectName,
    handleDeleteObject,
    handleMergeObjects,
    handleDeleteAllImages,
    handleDeleteImages,
    pipeline_status,
    handleMoveImages,
    currentCamera,
    videoContainerRef,
    handlerefresh,

    moveModelOpen,
    setMoveModelOpen,
    selectedDestObjects,
    setSelectedDestObjects,
    handleCreateObjects,
    createModelOpen,
    setCreateModelOpen,

    handleInterruptClick,
    handleTTriggerClick,
    id,
    showReview,
    training_pipeline_status,
    handleReturnClick,
    Syncloading,
    handleApplyClick,
    buttonState,
    handleActivityClick,
    setButtonState,
    loadingPipeline,
    syncError
  };
};
