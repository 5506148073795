// Dependency imports
import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSelector } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SearchOutlined } from '@mui/icons-material';
import Chip from '@mui/material/Chip';

// Local imports
import MultiReport from './multiReports';
import { Card, Typography } from 'components';
import SingleReport from './singleReport';
import GanttReport from './ganttReports';
import SelectConfig from '../../assets/images/selectConfig.svg';
import {
  useInferedVideosGraphData,
  useListConfigurations,
  useListInferenceModels,
  useListInferenceVideos
} from 'api/sdk';
import { useAppSelector, useAppDispatch } from 'hooks';
import { RootState } from 'store';
import { selectInferenceState, updateConfigId } from 'store/inference';
import SearchSelect from 'components/SearchSelect';

// Style imports
import Styles from './styles.module.scss';
import { set } from 'date-fns';
import { setsEqual } from 'chart.js/dist/helpers/helpers.core';

const InferenceReports = () => {
  const [modelSelected, setModelSelected] = useState<string | null>(null);
  const [openState, setOpenState] = useState<number>(0);
  const [searchedVal, setSearchedVal] = useState<string>('');
  const [sortModel, setSortModel] = useState<string>('');
  const [getVideo, setVideo] = useState<any>('');
  const { isLoading: confgiLoading, data: configData } =
    useListConfigurations();
  const currentConfigId = useAppSelector(
    (state: RootState) => state.inference.currentConfigId
  );
  const [getConfigId, setConfigId] = useState<any>(currentConfigId);
  const siteId = useSelector(
    (data: any) => data?.auth?.profile?.session_site?.id
  );
  const { data: models } = useListInferenceModels(
    siteId,
    {
      config_id: currentConfigId
    },
    {
      query: {
        enabled: !!currentConfigId
      }
    }
  );
  const dispatch = useAppDispatch();
  const {
    data: indefenceVideoData,
    isLoading: indefenceVideoLoading,
    refetch: refetchInferenceVideos
  } = useListInferenceVideos(
    getConfigId,
    {
      model_id: null,
      with_graph: true
    },
    {
      query: {
        enabled: !!getConfigId
      }
    }
  );
  const [inferenceMockData, setInferenceData] = useState<any>([]);

  useEffect(() => {
    const filterData = indefenceVideoData?.data.filter((item: any) => {
      if (item.status === 'COMPLETED' && item.graph_data !== null) {
        return item;
      }
    });
    setInferenceData(filterData);
  }, [indefenceVideoData]);

  useEffect(() => {
    videoInferenceRefetch();
    refetchInferenceVideos();
  }, [currentConfigId]);

  const { videos: reportMetaData, loading } = useSelector(selectInferenceState);

  const handleSearch = (searchVal: string, searchBy: string) => {
    if (searchVal === '') {
      if (indefenceVideoData) {
        const filterData = indefenceVideoData?.data.filter((item: any) => {
          if (item.status === 'COMPLETED' && item.graph_data !== null) {
            return item;
          }
        });
        setInferenceData(filterData);
        return;
      }
    }
    if (searchBy === 'filename') {
      const filterBySearch = indefenceVideoData?.data?.filter((item: any) => {
        console.log('item model id', item.model.id);
        if (
          item.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          item?.model?.id.includes(sortModel.toLowerCase())
        ) {
          return item;
        }
      });
      setInferenceData(filterBySearch);
    } else if (searchBy === 'model') {
      const filterBySearch = indefenceVideoData?.data?.filter((item: any) => {
        if (
          item?.model?.id
            .toString()
            .toLowerCase()
            .includes(searchVal.toLowerCase()) &&
          item.name.toLowerCase().includes(searchedVal.toLowerCase())
        ) {
          return item;
        }
      });
      setInferenceData(filterBySearch);
    }
  };

  // to get inference video
  const { refetch: videoInferenceRefetch, data: inferenceData } =
    useInferedVideosGraphData(getConfigId);

  return confgiLoading ? (
    <></>
  ) : (
    <>
      <Grid container>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={2}
          width={'100%'}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            gap={2}
          >
            <SearchSelect
              label="Select Configuration"
              data={
                configData
                  ? configData?.data.map(data => {
                      return { label: data?.name, value: data?.id };
                    })
                  : []
              }
              selectValue={getConfigId}
              onChange={(value: any) => {
                dispatch(updateConfigId(value));
                setConfigId(value);
              }}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifySelf: 'flex-start',
                gap: 4,
                padding: '6px 4px',
                background: 'white',
                border: 'none',
                boxShadow:
                  '0 1px 3px 0 rgb(0 0 0 / 0.2), 0 1px 2px -1px rgb(0 0 0 / 0.2)',
                borderRadius: '0.25rem'
              }}
            >
              <SearchOutlined />
              <input
                type="text"
                onChange={e => {
                  setSearchedVal(e.target.value);
                  handleSearch(e.target.value, 'filename');
                }}
                style={{
                  flex: 1,
                  border: 'none',
                  fontSize: '14px',
                  padding: '4px',
                  outline: 'none',
                  background: 'transparent'
                }}
                placeholder="Search by Video Name"
              />
            </div>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
          >
            {modelSelected && (
              <Chip
                label={modelSelected}
                variant="outlined"
                onDelete={() => {
                  setSortModel('');
                  setModelSelected(null);
                  console.log('SearchedVAl', typeof searchedVal);
                  handleSearch(searchedVal, 'filename');
                }}
              />
            )}
            <DropdownMenu.Root>
              <DropdownMenu.Trigger
                style={{
                  cursor: 'pointer',
                  border: 'none',
                  boxShadow: '2px 1px 5px 1px grey',
                  outline: 'none',
                  textDecoration: 'none',
                  borderRadius: '6px'
                }}
              >
                <FilterListIcon className={Styles.filtericon} />
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <DropdownMenu.Content
                  align="end"
                  alignOffset={0}
                  side="bottom"
                  sideOffset={0}
                  style={{
                    background: 'white',
                    minWidth: '160px',
                    alignItems: 'center',
                    zIndex: 100,
                    border: 'none',
                    padding: '4px 0px',
                    borderRadius: '4px',
                    boxShadow: '2px 1px 5px 1px grey'
                  }}
                >
                  <DropdownMenu.Label>
                    <p className={Styles.dropdownLabel}>Filter by Model</p>
                  </DropdownMenu.Label>
                  {models?.data
                    ?.filter((item: any) => {
                      if (item.status === 'COMPLETED') {
                        return item;
                      }
                    })
                    .map((model: any, index: number) => {
                      return (
                        <DropdownMenu.Item
                          key={model.id}
                          onSelect={() => {
                            setSortModel(model.id);
                            setModelSelected(model.model_name);
                            handleSearch(model.id, 'model');
                          }}
                          textValue={model.id}
                        >
                          <p className={Styles.dropdownItem}>
                            {model.model_name}
                          </p>
                        </DropdownMenu.Item>
                      );
                    })}
                  {models?.data?.length === 0 && (
                    <DropdownMenu.Item>
                      <p className={Styles.noModelsText}>No Models available</p>
                    </DropdownMenu.Item>
                  )}
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </Stack>
        </Stack>
        <Grid
          item
          xs={openState === 1 || openState === 0 ? 12 : 0}
          marginTop={'10px'}
        >
          {getConfigId !== 'Select Configuration' ? (
            (openState === 0 || openState === 1) && (
              <MultiReport
                setVideo={setVideo}
                getVideo={getVideo}
                reportMetaData={reportMetaData}
                indefenceVideoData={inferenceMockData}
                openState={openState}
                getConfigId={getConfigId}
                setOpenState={setOpenState}
              />
            )
          ) : (
            <Card>
              <Stack
                sx={{ height: '650px' }}
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <img
                  src={SelectConfig}
                  alt="select_config"
                  style={{ width: '260px' }}
                />
                <Typography sx={{ fontSize: '24px', color: '#707070' }}>
                  Select configuration type to view Reports
                </Typography>
              </Stack>
            </Card>
          )}
        </Grid>
        <Grid container spacing={2} marginTop={'10px'}>
          {(openState === 0 || openState === 2) && (
            <Grid item xs={openState === 2 ? 12 : openState === 0 ? 6 : 0}>
              <SingleReport
                getVideo={getVideo}
                indefenceVideoData={inferenceMockData}
                openState={openState}
                setOpenState={setOpenState}
              />
            </Grid>
          )}
          {(openState === 0 || openState === 3) && (
            <Grid item xs={openState === 3 ? 12 : openState === 0 ? 6 : 0}>
              <GanttReport
                getVideo={getVideo}
                indefenceVideoData={inferenceMockData}
                openState={openState}
                setOpenState={setOpenState}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default InferenceReports;
