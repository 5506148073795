import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ObjectsSchema,
  ObjResponseSchema,
  ObjSchema,
  PipelineStatusResponse
} from 'schemas';
import { PipelineStatus } from 'types';

import { ObjectsState } from './schema';
import { faker } from '@faker-js/faker';

const initialState: ObjectsState = {
  loaded: false,
  objects: {}
};

export const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    addObject: (state, action: PayloadAction<ObjResponseSchema[]>) => {
      const data = action.payload;

      // Clear existing objects while retaining the rest of the state
      for (const cameraId in state.objects) {
        if (state.objects.hasOwnProperty(cameraId)) {
          state.objects[cameraId].objects = [];
        }
      }

      data.forEach(objResponse => {
        objResponse.object_details.forEach(obj => {
          const cameraId = obj.camera_id;

          // Initialize camera state if it doesn't exist
          if (!state.objects[cameraId]) {
            state.objects[cameraId] = {
              camera_id: cameraId,
              pipeline_status: PipelineStatus.UNSET,
              pipeline_progress: 0,
              trained: false,
              objects: []
            };
          }

          const newObject = {
            id: obj.id,
            name: obj.classname,
            category: obj.category,
            default: obj.classname === 'General objects',
            images:
              obj.images?.map(image => ({
                id: image.id,
                image_path: image.image_path,
                thumb_url: image.thumb_url || ''
              })) || []
          };

          state.objects[cameraId].objects.push(newObject);

          // Convert pipeline status
          if (objResponse.pipeline_status) {
            objResponse.pipeline_status.forEach(status => {
              if (!state.objects[status.camera_id]) {
                state.objects[status.camera_id] = {
                  camera_id: status.camera_id,
                  pipeline_status: PipelineStatus.UNSET,
                  pipeline_progress: 0,
                  trained: status.training_model,
                  objects: []
                };
              }
              state.objects[status.camera_id].pipeline_status = status.status;
              state.objects[status.camera_id].trained = status.training_model;

              switch (status.status) {
                case PipelineStatus.PROCESSING:
                  state.objects[status.camera_id].pipeline_progress = 0;
                  break;
                case PipelineStatus.COMPLETED:
                  state.objects[status.camera_id].pipeline_progress = 100;
                  break;
                case PipelineStatus.QUEUED:
                  state.objects[status.camera_id].pipeline_progress = 0;
                  break;
              }
            });
          }
        });

        objResponse?.pipeline_status?.forEach(status => {
          if (!state.objects[status.camera_id]) {
            state.objects[status.camera_id] = {
              camera_id: status.camera_id,
              pipeline_status: PipelineStatus.UNSET,
              pipeline_progress: 0,
              trained: status.training_model,
              objects: []
            };
          }

          state.objects[status.camera_id].pipeline_status = status.status;
          state.objects[status.camera_id].pipeline_progress = 0;
          switch (status.status) {
            case PipelineStatus.PROCESSING:
              state.objects[status.camera_id].pipeline_progress = 0;
              break;
            case PipelineStatus.COMPLETED:
              state.objects[status.camera_id].pipeline_progress = 100;
              break;
            case PipelineStatus.QUEUED:
              state.objects[status.camera_id].pipeline_progress = 0;
              break;
          }
        });
      });

      // Sort objects to maintain the sequence with "General objects" at the end
      for (const cameraId in state.objects) {
        if (state.objects.hasOwnProperty(cameraId)) {
          state.objects[cameraId].objects.sort((a, b) => {
            if (a.default === b.default) {
              return 0; // If both are the same (either both default or both not), maintain original order
            }
            return a.default ? 1 : -1; // Move "default" objects to the end
          });
        }
      }

      state.loaded = true;
    },

    updatePipelineStatus: (
      state,
      action: PayloadAction<PipelineStatusResponse[]>
    ) => {
      const objects = Object.values(state.objects);
      const data = action.payload;

      objects.forEach(camera => {
        const statuses = data.find(
          status => status.camera_id === camera.camera_id
        );
        const labelAssistStatus = statuses?.pipeline_status.find(
          status => status.pipeline === 'label_assist'
        );

        if (labelAssistStatus) {
          camera.pipeline_status = labelAssistStatus.status;

          if (labelAssistStatus.status === PipelineStatus.COMPLETED) {
            camera.pipeline_progress = 100;
          } else if (labelAssistStatus.status === PipelineStatus.QUEUED) {
            camera.pipeline_progress = 0;
          }
        }
      });
    },

    resetObjects: state => {
      state = initialState;
    },

    updateObjectName: (
      state,
      action: PayloadAction<{
        cameraId: string;
        objectId: string;
        name: string;
        category: string;
      }>
    ) => {
      const { cameraId, objectId, name, category } = action.payload;
      const camera = state.objects[cameraId];

      const object = camera.objects.find(obj => obj.id === objectId);
      if (object) {
        object.name = name;
        object.category = category;
      }
    },
    CreateNewObject: (
      state,
      action: PayloadAction<{
        cameraId: string;
        objectId: string;
        name: string;
        category: string;
        images: Array<{
          id: string;
          image_path: string;
          frame_path: string;
          frame_number: number;
          frame_width: number;
          frame_height: number;
          bounding_box: number[];
          thumb_url: string;
        }>;
      }>
    ) => {
      const { cameraId, objectId, name, category, images } = action.payload;
      const camera = state.objects[cameraId];

      if (camera) {
        // Add the new object to the camera's objects array
        camera.objects = camera.objects || [];
        camera.objects.push({
          id: objectId,
          name: name,
          category: category,
          images: images
        });
      }
    },
    deleteObject: (
      state,
      action: PayloadAction<{
        cameraId: string;
        objectId: string;
      }>
    ) => {
      const { cameraId, objectId } = action.payload;
      const camera = state.objects[cameraId];
      camera.objects = camera.objects.filter(obj => obj.id !== objectId);
    },

    mergeObjects: (
      state,
      action: PayloadAction<{
        cameraId: string;
        srcId: string[];
        destId: string;
        category: string;
        name: string;
      }>
    ) => {
      const { srcId, destId, category, cameraId } = action.payload;
      const camera = state.objects[cameraId];

      const destObject = camera.objects.find(obj => obj.id === destId);

      srcId.forEach(id => {
        const srcObject = camera.objects.find(obj => obj.id === id);
        if (srcObject) {
          destObject?.images.push(...srcObject.images);
        }
      });

      camera.objects = camera.objects.filter(obj => !srcId.includes(obj.id));

      if (destObject) {
        destObject.category = category;
        destObject.name = action.payload.name;
      }
    },

    deleteAllImages: (
      state,
      action: PayloadAction<{
        cameraId: string;
        objectId: string;
      }>
    ) => {
      const { cameraId, objectId } = action.payload;
      const camera = state.objects[cameraId];

      if (!camera) return;

      const object = camera.objects.find(obj => obj.id === objectId);
      const generalObject = camera.objects.find(
        obj => obj.name === 'General objects'
      );

      if (object && generalObject) {
        // Move all images to the 'General' object's images array
        generalObject.images.push(...object.images);

        // Clear the original object's images array
        object.images = [];
      }
    },

    deleteSelectedImages: (
      state,
      action: PayloadAction<{
        cameraId: string;
        objectId: string;
        images: string[];
      }>
    ) => {
      const { cameraId, objectId, images } = action.payload;
      const camera = state.objects[cameraId];

      if (!camera) return;

      const object = camera.objects.find(obj => obj.id === objectId);
      const generalObject = camera.objects.find(
        obj => obj.name === 'General objects'
      );

      if (object && generalObject) {
        // Move the images to the 'General' object's images array
        const imagesToMove = object.images.filter(image =>
          images.includes(image.id)
        );
        generalObject.images.push(...imagesToMove);

        // Remove the images from the original object
        object.images = object.images.filter(
          image => !images.includes(image.id)
        );
      }
    },

    moveImages: (
      state,
      action: PayloadAction<{
        cameraId: string;
        srcObject: string;
        destObject: string | null;
        images: string[];
      }>
    ) => {
      const { cameraId, srcObject, destObject, images } = action.payload;
      const camera = state.objects[cameraId];

      const src = camera.objects.find(obj => obj.id === srcObject);
      const dest = camera.objects.find(obj => obj.id === destObject);

      if (src && dest) {
        dest.images.push(
          ...images.map(id => src.images.find(image => image.id === id)!)
        );
        src.images = src.images.filter(image => !images.includes(image.id));
      }
    },

    createObject: (
      state,
      action: PayloadAction<{
        cameraId: string;
        name: string;
        category: string;
        images: string[];
        srcObject: string | null;
      }>
    ) => {
      const { cameraId, name, category, images, srcObject } = action.payload;
      const camera = state.objects[cameraId];

      const src = camera.objects.find(obj => obj.id === srcObject);

      const newObject = {
        id: faker.string.uuid(),
        name,
        category,
        default: false,
        new: true,
        images: images.map(id => src?.images.find(image => image.id === id)!)
      };

      camera.objects.push(newObject);

      // remove the images from the source object
      src!.images = src!.images.filter(image => !images.includes(image.id));
    },
    lowresWebsocketMessage: (
      state,
      action: PayloadAction<{
        data: any;
      }>
    ) => {
      const message = action.payload.data;
      state.objects['lowres_url'] = message;
    },

    consumeWebsocketMessage: (
      state,
      action: PayloadAction<{
        type: string;
        data: {
          config_id: string;
          camera_id: string;
          progress?: number;
          object_details?: ObjSchema[];
        };
      }>
    ) => {
      const messageType = action.payload.type;
      const cameraId = action.payload.data.camera_id;

      if (!state.objects[cameraId]) {
        state.objects[cameraId] = {
          camera_id: cameraId,
          pipeline_status: PipelineStatus.UNSET,
          pipeline_progress: 0,
          trained: false,
          objects: []
        };
      }

      switch (messageType) {
        case 'QUEUED':
          state.objects[cameraId].pipeline_status = PipelineStatus.QUEUED;
          state.objects[cameraId].pipeline_progress = 0;
          break;
        case 'TRAINING':
          state.objects[cameraId].pipeline_status = PipelineStatus.TRAINING;
          state.objects[cameraId].pipeline_progress = 0;
          break;
        case 'PROCESSING':
          state.objects[cameraId].pipeline_status = PipelineStatus.PROCESSING;
          state.objects[cameraId].pipeline_progress = 0;
          break;
        case 'PROGRESS':
          state.objects[cameraId].pipeline_status = PipelineStatus.PROCESSING;
          state.objects[cameraId].pipeline_progress =
            action.payload.data.progress || 0;
          break;
        case 'COMPLETED':
          state.objects[cameraId].pipeline_status = PipelineStatus.COMPLETED;
          state.objects[cameraId].pipeline_progress = 100;
          state.objects[cameraId].objects =
            action.payload.data.object_details?.map(obj => {
              return {
                id: obj.id,
                name: obj.classname,
                category: obj.category,
                default: obj.classname === 'General objects',
                images:
                  obj.images?.map(image => ({
                    id: image.id,
                    image_path: image.image_path,
                    thumb_url: image.thumb_url || ''
                  })) || []
              };
            }) || [];

          break;
        case 'FAILED':
          state.objects[cameraId].pipeline_status = PipelineStatus.FAILED;
          state.objects[cameraId].pipeline_progress = 100;
          break;
        default:
          // For other message types, return the current state unchanged
          break;
      }
    },

    InitialTrainingMessage: (
      state,
      action: PayloadAction<{
        type: string;
        config_id: string | undefined;
        camera_ids: string[];
      }>
    ) => {
      const messageType = action.payload.type;
      const cameraIds = action.payload.camera_ids;

      cameraIds.forEach(cameraId => {
        if (!state.objects[cameraId]) {
          state.objects[cameraId] = {
            camera_id: cameraId,
            pipeline_status: PipelineStatus.UNSET,
            pipeline_progress: 0,
            trained: false,
            objects: []
          };
        }

        switch (messageType) {
          case 'TRAINING':
            state.objects[cameraId].pipeline_status = PipelineStatus.TRAINING;
            state.objects[cameraId].pipeline_progress = 0;
            break;

          default:
            // For other message types, return the current state unchanged
            break;
        }
      });
    },
    consumesocketTrainingMessage: (
      state,
      action: PayloadAction<{
        type: string;
        data: {
          config_id: string;
          camera_id: string;
          progress?: number;
        };
      }>
    ) => {
      const messageType = action.payload.type;
      const cameraId = action.payload.data.camera_id;

      if (!state.objects[cameraId]) {
        state.objects[cameraId] = {
          camera_id: cameraId,
          pipeline_status: PipelineStatus.UNSET,
          pipeline_progress: 0,
          trained: false,
          objects: []
        };
      }

      switch (messageType) {
        case 'QUEUED':
          state.objects[cameraId].pipeline_status = PipelineStatus.QUEUED;
          state.objects[cameraId].pipeline_progress = 0;
          break;
        case 'TRAINING':
          state.objects[cameraId].pipeline_status = PipelineStatus.TRAINING;
          state.objects[cameraId].pipeline_progress = 0;
          break;
        case 'COMPLETED':
          state.objects[cameraId].pipeline_status = PipelineStatus.PROCESSING;
          state.objects[cameraId].pipeline_progress = 0;
          state.objects[cameraId].trained = true;
          break;
        case 'FAILED':
          state.objects[cameraId].pipeline_status = PipelineStatus.FAILED;
          state.objects[cameraId].pipeline_progress = 100;
          break;
        default:
          // For other message types, return the current state unchanged
          break;
      }
    }
  }
});

export const {
  addObject,
  updatePipelineStatus,
  updateObjectName,
  deleteObject,
  mergeObjects,
  deleteAllImages,
  moveImages,
  createObject,
  deleteSelectedImages,
  consumeWebsocketMessage,
  lowresWebsocketMessage,
  CreateNewObject,
  consumesocketTrainingMessage,
  InitialTrainingMessage
} = objectsSlice.actions;

export const selectObjects = (state: { objects: ObjectsState }) =>
  state.objects;

export default objectsSlice.reducer;
