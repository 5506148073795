import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { DragHandle } from './DragHandle';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Typography,
  Avatar,
  Stack,
  ListItemAvatar,
  ListItemText,
  TableCell,
  SelectChangeEvent,
  IconButton,
  Tooltip
} from '@mui/material';

import MultiSelector from 'components/MultiSelect';
import { Switch } from 'components/Switch';
import styled from 'styled-components';
import {
  ModulesTrainingActivitiesSchemasActivitySchema,
  ModulesTrainingAnalysisSchemasWorkflow,
  ModulesTrainingAnalysisSchemasWorkflowDependency,
  Workflow,
  WorkflowDependencyItem,
  WorkflowSchema
} from 'schemas';
import { SelectInput } from 'components/Form';
import DeleteIcon from '@mui/icons-material/Delete';
import { secondsToHms } from 'utils';

interface SortableRowProps {
  id: string;
  row: Workflow;
  index: number;
  activity: ModulesTrainingActivitiesSchemasActivitySchema[];
  dependency: ModulesTrainingAnalysisSchemasWorkflowDependency;
  handleChangeDependency: (id: string, array: WorkflowDependencyItem) => void;
  handleDelete: (id: string) => void;
  rows: ModulesTrainingAnalysisSchemasWorkflow[];
  handleToggleMendatory: (rowId: string, value: boolean) => void;
  setRows: React.Dispatch<React.SetStateAction<WorkflowSchema | undefined>>;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid  #ededed`,
  padding: '2px'
}));

const SortableRow: React.FC<SortableRowProps> = ({
  id,
  row,
  index,
  activity,
  dependency,
  handleChangeDependency,
  handleDelete,
  handleToggleMendatory,
  setRows,
  rows
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: transform ? `translateY(${transform.y}px)` : undefined,
    transition,
    fontSize: '10px'
  };

  const handleChange = (value: string) => {
    handleChangeDependency(id, value);
  };

  const activityMap = useMemo(() => {
    let map: {
      [key: string]: ModulesTrainingActivitiesSchemasActivitySchema;
    } = {};
    activity.forEach(i => {
      map[i?.id] = i;
    });
    return map;
  }, [activity]);

  const activityDurations = useMemo(() => {
    const map: { [key: string]: { min: number; max: number } } = {};

    activity.forEach(a => {
      const durations =
        a?.clips?.map(c => {
          const start = parseInt(c.start_time, 10);
          const end = parseInt(c.end_time, 10);
          return end - start;
        }) ?? [];

      map[a?.id] = {
        min: durations.length > 0 ? Math.min(...durations) : 0,
        max: durations.length > 0 ? Math.max(...durations) : 0
      };
    });
    return map;
  }, [activity]);

  return (
    <TableRow ref={setNodeRef} style={style}>
      <StyledTableCell component="th" scope="row" style={{ maxWidth: '200px' }}>
        <Stack direction={'row'} alignItems={'center'}>
          <div
            style={{
              padding: '0px 0px 0px 15px',
              whiteSpace: 'nowrap',
              overflow: 'scroll',
              textOverflow: 'ellipsis',
              maxWidth: '100%'
            }}
          >
            <Tooltip
              title={`${index + 1}. ${activityMap[row?.activity_id]?.activity}`}
            >
              <ListItemText
                primary={`${index + 1}. ${
                  activityMap[row?.activity_id]?.activity
                }`}
              />
            </Tooltip>
          </div>
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="center" width={'250px'}>
        {index > 0 && (
          <SelectInput
            name=""
            label=""
            options={[
              { value: 'remove', label: 'None' }, // Add the 'none' option here
              ...(rows
                .map((r, i) => {
                  return {
                    value: r?.id,
                    label: `${i + 1}. ${
                      activityMap[r?.activity_id]?.activity.length < 20
                        ? activityMap[r?.activity_id]?.activity
                        : activityMap[r?.activity_id]?.activity.slice(0, 20) +
                            '...' || ''
                    }`
                  };
                })
                .filter((r, i) => i !== index) || [])
            ]}
            value={dependency}
            placeholder="Select Dependency"
            variant="outlined"
            maxWidth={'250px'}
            onChange={handleChange}
          />
        )}
      </StyledTableCell>
      <StyledTableCell align="center">
        <Switch
          checked={row?.mendatory || false}
          onChange={e => {
            handleToggleMendatory(row?.id, e?.target?.checked);
          }}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        {secondsToHms(activityDurations[row?.activity_id]?.min || 0)}
      </StyledTableCell>
      <StyledTableCell align="center">
        {secondsToHms(activityDurations[row?.activity_id]?.max || 0)}
      </StyledTableCell>
      <StyledTableCell align="center">
        <IconButton
          aria-label="delete"
          disableRipple
          onClick={() => handleDelete(id)}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton style={{ marginTop: '-12px' }} disableRipple>
          <DragHandle {...attributes} {...listeners} isDragging={true} />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  );
};

export default SortableRow;
