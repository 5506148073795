/* eslint-disable */
import CallMergeIcon from '@mui/icons-material/CallMerge';
import { Alert, Box, Divider, Grid, Stack } from '@mui/material';
import {
  AppLoading,
  Button,
  Card,
  ObjectCategoryCard,
  ObjectCategoryPageSkeleton,
  ObjectCategorySkeleton,
  PipelineProgressBar,
  Modal,
  Typography
} from 'components';
import { useObjectConfiguration } from 'hooks/config';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConfigSteps, PipelineStatus } from 'types';

import { SelectInput } from 'components/Form';
import UpdateObjectForm from './updateObjectForm';
import MergeObjectsForm from './mergeObjectsForm';
import ObjectAnnotationPage from '../objectAnnotation';
import MoveObjectModel from './moveObjectModel';
import CreateObjectForm from './createObjectForm';
import { useConfigWebsocket } from 'hooks/config/useConfigWebsocket';
import { setCurrentCameraId } from 'store/config/configSlice';
import { useDispatch } from 'react-redux';
import { updatePipelineStatus } from 'store/config/objects';
import { useLabelAssistInterrupt } from 'api/sdk';
import { Snack } from 'plugins/snack/Snack';
import NoObject from 'components/icons/NoObject';
import { TroubleshootRounded } from '@mui/icons-material';

interface IObjectConfigurationProps {
  onNext: () => void;
  step: string;
  onActivity: () => void;
  onRedirect: () => void;
  onAnalysis: () => void;
}

const ObjectConfiguration: React.FC<IObjectConfigurationProps> = ({
  step,
  onNext,
  onActivity,
  onRedirect,
  onAnalysis
}) => {
  const {
    cameras,
    currentCameraId,
    object,
    pipelineStatuses,
    isLoading,
    training_pipeline_status,
    pipeline_status,
    handleDeleteObject,
    selectedObjects,
    selectedItems,
    apiLoading,
    setSelectedObjects,
    setSelectedItems,
    handlerefresh,
    handleCameraChange,
    handleUpdateObjectName,
    handleMergeObjects,
    handleDeleteAllImages,
    handleDeleteImages,
    handleMoveImages,
    currentCamera,
    videoContainerRef,
    moveModelOpen,
    setMoveModelOpen,
    selectedDestObjects,
    setSelectedDestObjects,
    handleCreateObjects,
    createModelOpen,
    setCreateModelOpen,
    handleInterruptClick,
    disableInterrupt,
    handleTTriggerClick,
    showReview,
    handleReturnClick,
    Syncloading,
    handleApplyClick,
    handleActivityClick,
    id,
    buttonState,
    setButtonState,
    syncError,
    loadingPipeline
  } = useObjectConfiguration(onNext, onRedirect, onActivity, onAnalysis);

  const [expanded, setExpanded] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState<{
    open: boolean;
    data?: {
      id: string;
      name: string;
      category: string;
    };
  }>({
    open: false
  });

  const [mergeMode, setMergeMode] = useState<{
    open: boolean;
    data?: {
      name: string;
      category: string;
    };
  }>({
    open: false
  });

  const renderObjects = useMemo(() => {
    if (expanded) {
      return object?.objects.filter(obj => obj.id === expanded);
    } else {
      return object?.objects;
    }
  }, [expanded, object]);

  const handleMergeObjectsClick = () => {
    const firstObject = object?.objects.find(
      obj => obj.id === selectedObjects[0]
    );
    setMergeMode({
      open: true,
      data: {
        name: firstObject?.name || '',
        category: firstObject?.category || ''
      }
    });
  };
  const handleViewClick = (cameraId: string) => {
    dispatch(setCurrentCameraId(cameraId));
  };
  // Inside your parent component

  const Skeleton = () => {
    return <ObjectCategoryPageSkeleton />;
  };

  const ExtraActions = () => {
    const disabled = selectedItems.length === 0;
    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent={'center'}
        flexWrap={'wrap'}
        mt={2}
      >
        <Button
          version="light"
          disabled={disabled}
          onClick={() => setMoveModelOpen(true)}
        >
          Move
        </Button>
        <Button
          version="light"
          disabled={disabled}
          onClick={() =>
            expanded && handleDeleteImages(expanded, selectedItems)
          }
        >
          Delete Selected
        </Button>
        <Button
          version="light"
          onClick={() => expanded && handleDeleteAllImages(expanded)}
        >
          Delete All
        </Button>
        <Button
          disabled={disabled}
          onClick={() => {
            setCreateModelOpen({ open: true });
          }}
        >
          Create New Object
        </Button>
      </Stack>
    );
  };

  return (
    <>
      {step === ConfigSteps.OBJECT_CONFIGURATION ? (
        <>
          <Grid
            container
            spacing={3}
            paddingX={{
              xl: 4
            }}
          >
            {apiLoading && <Skeleton />}
            {!apiLoading && (
              <Grid item xs={12}>
                {isLoading ? (
                  <AppLoading />
                ) : (
                  <>
                    {pipeline_status === PipelineStatus.COMPLETED ? (
                      <>
                        <Grid item xs={12}>
                          <SelectInput
                            name="scene"
                            options={
                              cameras?.map((camera, index) => ({
                                label: camera.name,
                                value: camera.id
                              })) || []
                            }
                            value={String(currentCameraId)}
                            variant="outlined"
                            onChange={value => handleCameraChange(value)}
                          />
                          <Box sx={{ m: 2 }} />
                          <Divider />
                        </Grid>

                        <Divider />

                        <Grid item xs={12} my={2}>
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={'right'}
                          >
                            <Button
                              version="light"
                              disabled={selectedObjects.length < 2}
                              onClick={handleMergeObjectsClick}
                            >
                              <CallMergeIcon />
                              Merge Objects
                            </Button>
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            {renderObjects?.map((category, index) => (
                              <>
                                <Grid
                                  item
                                  {...(expanded === category.id
                                    ? { xs: 12 }
                                    : {
                                        xl: 3,
                                        lg: 4,
                                        sm: 6,
                                        xs: 12
                                      })}
                                  key={category.id}
                                >
                                  <ObjectCategoryCard
                                    id={category.id}
                                    title={category.name}
                                    subtitle={
                                      category.category
                                        ? `Object Type: ${category.category}: ${category.images.length} images`
                                        : ''
                                    }
                                    actions={
                                      category.default
                                        ? ['expand']
                                        : ['edit', 'delete', 'expand']
                                    }
                                    contentType="image"
                                    checkable={!Boolean(expanded)}
                                    expanded={expanded === category.id}
                                    checkableItems={expanded === category.id}
                                    checked={selectedObjects.includes(
                                      category.id
                                    )}
                                    onExpandAction={() => {
                                      setSelectedObjects([]);
                                      setSelectedItems([]);
                                      setExpanded(
                                        expanded === category.id
                                          ? null
                                          : category.id
                                      );
                                    }}
                                    onCheck={checked => {
                                      if (checked) {
                                        setSelectedObjects([
                                          ...selectedObjects,
                                          category.id
                                        ]);
                                      } else {
                                        setSelectedObjects(
                                          selectedObjects.filter(
                                            obj => obj !== category.id
                                          )
                                        );
                                      }
                                    }}
                                    onDeleteAction={() =>
                                      handleDeleteObject(category.id)
                                    }
                                    onCheckItem={(checked, id) => {
                                      if (checked) {
                                        setSelectedItems(prev => [...prev, id]);
                                      } else {
                                        setSelectedItems(
                                          selectedItems.filter(
                                            item => item !== id
                                          )
                                        );
                                      }
                                    }}
                                    checkedItems={selectedItems}
                                    items={category.images
                                      .slice()
                                      .reverse()
                                      .slice(
                                        0,
                                        expanded === category.id
                                          ? category.images.length
                                          : 3
                                      )
                                      .map(image => ({
                                        id: image.id,
                                        url: image.thumb_url,
                                        thumbnail_url: image.thumb_url
                                      }))}
                                    onEditAction={() =>
                                      setEditMode({
                                        open: true,
                                        data: {
                                          id: category.id,
                                          name: category.name,
                                          category: category.category
                                        }
                                      })
                                    }
                                    postContent={
                                      expanded ? <ExtraActions /> : undefined
                                    }
                                  />
                                </Grid>
                              </>
                            ))}
                            {syncError && (
                              <>
                                <Alert severity="error">
                                  {syncError.toString()}
                                </Alert>
                              </>
                            )}

                            {!expanded && (
                              <Stack
                                direction="row"
                                spacing={2}
                                justifyContent={'center'}
                                width={'100%'}
                                mt={2}
                              >
                                <Button
                                  variant="outlined"
                                  version="light"
                                  disabled={!object?.trained}
                                  onClick={() => {
                                    handleActivityClick();
                                  }}
                                >
                                  {loadingPipeline
                                    ? 'Saving...'
                                    : 'Save And Proceed'}
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  version="dark"
                                  disabled={Syncloading && buttonState == 2}
                                  onClick={() => {
                                    handleApplyClick(2, true);
                                  }}
                                >
                                  {Syncloading && buttonState == 2
                                    ? 'Saving...'
                                    : 'Need more objects'}
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={Syncloading && buttonState == 3}
                                  version="dark"
                                  onClick={() => {
                                    handleApplyClick(3);
                                  }}
                                >
                                  {Syncloading && buttonState == 3
                                    ? 'Saving...'
                                    : 'Apply'}
                                </Button>
                              </Stack>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <>
                          {pipeline_status === PipelineStatus.PROCESSING ||
                          pipeline_status === PipelineStatus.QUEUED ||
                          pipeline_status === PipelineStatus.TRAINING ||
                          pipeline_status === PipelineStatus.FAILED ? (
                            <>
                              <Grid container spacing={3}>
                                <Grid item xs={12} lg={2}></Grid>
                                <Grid item xs={12} lg={8}>
                                  <Card>
                                    <PipelineProgressBar
                                      items={pipelineStatuses.map(status => ({
                                        title: status.title,
                                        id: status.id,
                                        status: status.status,
                                        progress: status.progress
                                      }))}
                                      title="Please wait until Process is completed."
                                      onViewClick={handleViewClick}
                                      onInterruptClick={handleInterruptClick}
                                      disableInterrupt={disableInterrupt}
                                    />
                                  </Card>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid container spacing={3}>
                                <Grid
                                  item
                                  xs={12}
                                  lg={12}
                                  mt={6}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <NoObject />
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </>
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>

          {editMode.open && (
            <Modal
              open={editMode.open}
              onClose={() => setEditMode({ open: false })}
            >
              <UpdateObjectForm
                data={{
                  id: editMode.data?.id || '',
                  name: editMode.data?.name || '',
                  category: editMode.data?.category || ''
                }}
                onClose={() => setEditMode({ open: false })}
                onSaved={values => {
                  setEditMode({ open: false });

                  handleUpdateObjectName(
                    values.id,
                    values.new_name,
                    values.category
                  );
                }}
              />
            </Modal>
          )}

          {mergeMode.open && (
            <Modal
              open={mergeMode.open}
              onClose={() => setMergeMode({ open: false })}
            >
              <MergeObjectsForm
                name={mergeMode.data?.name || ''}
                category={mergeMode.data?.category || ''}
                onClose={() => setMergeMode({ open: false })}
                onSaved={values => {
                  setMergeMode({ open: false });
                  handleMergeObjects(values.new_name, values.category);
                }}
              />
            </Modal>
          )}
          {moveModelOpen && (
            <Modal
              open={true}
              onClose={() => setMoveModelOpen(false)}
              size="xl"
            >
              <MoveObjectModel
                onCancel={() => setMoveModelOpen(false)}
                object={object?.objects || []}
                selectedDestObjects={selectedDestObjects}
                setSelectedDestObjects={setSelectedDestObjects}
                onMove={
                  () => {
                    expanded &&
                      handleMoveImages(
                        expanded,
                        selectedDestObjects,
                        selectedItems
                      );
                  }
                  //   id => {
                  //   handleMove(id);
                  // }
                }
              />
            </Modal>
          )}
          {createModelOpen.open && (
            <Modal
              open={true}
              onClose={() => setCreateModelOpen({ open: false })}
              size="sm"
            >
              <CreateObjectForm
                data={{
                  name: createModelOpen.data?.name || '',
                  category: createModelOpen.data?.category || ''
                }}
                onClose={() => setCreateModelOpen({ open: false })}
                onSaved={values => {
                  setCreateModelOpen({ open: false });

                  handleCreateObjects(
                    expanded,
                    values.new_name,
                    values.category,
                    selectedItems
                  );
                }}
              />
            </Modal>
          )}
        </>
      ) : (
        <>
          {object?.pipeline_status === PipelineStatus.COMPLETED ? (
            // object?.pipeline_status === PipelineStatus.COMPLETED
            <ObjectAnnotationPage
              cameras={cameras}
              OnCameraChange={handleCameraChange}
              currentCameraId={currentCameraId}
              currentCamera={currentCamera}
              videoContainerRef={videoContainerRef}
              object={object}
              onRefresh={handlerefresh}
              onActivity={onActivity}
              OnTrigger={handleTTriggerClick}
              onRedirect={onRedirect}
              showReview={showReview}
              OnReturn={handleReturnClick}
              trainingPipelineStatus={training_pipeline_status}
              onApply={handleApplyClick}
              Apply={Syncloading}
            />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <ObjectAnnotationPage
                  cameras={cameras}
                  OnCameraChange={handleCameraChange}
                  currentCameraId={currentCameraId}
                  currentCamera={currentCamera}
                  videoContainerRef={videoContainerRef}
                  object={object}
                  onRefresh={handlerefresh}
                  onActivity={onActivity}
                  OnTrigger={handleTTriggerClick}
                  onRedirect={onRedirect}
                  showReview={showReview}
                  OnReturn={handleReturnClick}
                  trainingPipelineStatus={training_pipeline_status}
                  onApply={handleApplyClick}
                  Apply={Syncloading}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ObjectConfiguration;
