/* eslint-disable */

import { Grid } from '@mui/material';
import { Button, Modal, Typography } from 'components';
import { useEffect, useState } from 'react';
import { LabelAssistMode } from 'schemas';

interface modalStatus {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getOpen: boolean;
  handleUpdateClick: (mode: LabelAssistMode) => void;
  onNext: () => void;
}

const SelectModeModal = ({
  setOpen,
  getOpen,
  handleUpdateClick,
  onNext
}: modalStatus) => {
  const [mode, setMode] = useState<LabelAssistMode>('balanced');
  const [isLoading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    // TO RESET MODE TO BALANCED WHEN CLOSING WITHOUT SAVING
    !getOpen && setMode('balanced');
  }, [getOpen]);
  return (
    <Modal open={getOpen} onClose={() => setOpen(false)}>
      <Grid container justifyContent={'center'}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Select mode to proceed with the process.
        </Typography>
      </Grid>
      <Grid container justifyContent={'center'} margin={'20px 0px 15px 0px'}>
        <div
          style={{
            backgroundColor: mode === 'accuracy' ? '#102633' : 'white',
            border: '1px solid black',
            color: mode === 'accuracy' ? 'white' : 'black',
            minWidth: '5.5vw',
            height: '4',
            alignContent: 'center',
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: 'bold',
            cursor: 'pointer',
            padding: '0.5vh 1vh 0.5vh 1vh',
            borderTopLeftRadius: '20px', // Adjust the value as needed
            borderBottomLeftRadius: '20px' // Adjust the value as needed
          }}
          onClick={() => setMode('accuracy')}
        >
          Accuracy
        </div>

        <div
          style={{
            backgroundColor: mode === 'balanced' ? '#102633' : 'white',
            border: '1px solid black',
            color: mode === 'balanced' ? 'white' : 'black',
            minWidth: '5.5vw',
            height: '4',
            alignContent: 'center',
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: 'bold',
            cursor: 'pointer',
            padding: '0.5vh 1vh 0.5vh 1vh'
          }}
          onClick={() => setMode('balanced')}
        >
          Balanced
        </div>
        <div
          style={{
            backgroundColor: mode === 'fast' ? '#102633' : 'white',
            border: '1px solid black',
            color: mode === 'fast' ? 'white' : 'black',
            minWidth: '5.5vw',
            height: '4',
            alignContent: 'center',
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: 'bold',
            cursor: 'pointer',
            padding: '0.5vh 1vh 0.5vh 1vh',
            borderTopRightRadius: '20px', // Adjust the value as needed
            borderBottomRightRadius: '20px' // Adjust the value as needed
          }}
          onClick={() => setMode('fast')}
        >
          Fast
        </div>
      </Grid>
      <Grid container justifyContent={'center'} margin={'30px 0px 0px 0px'}>
        <Grid item display={'flex'} justifyContent={'space-between'} xs={6}>
          <Button
            version={'light'}
            onClick={() => {
              setOpen(false);
              setMode('balanced');
            }}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            version={'dark'}
            disabled={!mode || isLoading}
            onClick={() => {
              handleUpdateClick(mode);
              setLoading(true);
              setTimeout(() => {
                onNext(); // Call onNext after 10 seconds
              }, 4000);
            }}
          >
            Proceed
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SelectModeModal;
