/* esling-disable */
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { PrivateLayout } from 'layouts';
import ConfigurationCreatePage from 'pages/configuration/create';
import { ConfigurationListPage } from 'pages/configuration/list';
import NotFound from 'pages/errors/notFound.tsx';
import { RootState } from 'store';

import urls from './privateRoutesConfig';
import publicUrls from './urls';
import InferenceReports from 'pages/InferenceReports';
import { TrainPage } from 'pages/train';
import LiveInference from 'pages/workFlowAnnotation/LiveInference';
import IpcamInference from 'pages/workFlowAnnotation/IpcamInference';

/**
 * List of routes available  for authenticated users
 * Also renders the "Private Layout" composition
 * @routes PrivateRoutes
 */
const PrivateRoutes = () => {
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const currentConfigId = useAppSelector(
    (state: RootState) => state.inference.currentConfigId
  );

  /**
   * Route to login page if token is not valid
   * @param {any} (
   * @returns {any}
   */
  useEffect(() => {
    if (!isLoggedIn) {
      navigate(publicUrls.LOGIN.generate());
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && location.pathname.startsWith('/auth')) {
      const url = '/configuration';
      navigate(url);
    }
  }, []);

  return (
    <PrivateLayout>
      <Routes>
        {Object.values(urls).map(route => {
          if (route.items) {
            return Object.values(route.items).map(subRoute => {
              return (
                <Route
                  key={subRoute.name}
                  path={`${route.route}${subRoute.route}`}
                  element={subRoute.component}
                />
              );
            });
          }
          return (
            <Route
              key={route.name}
              path={route.route}
              element={route.component}
            />
          );
        })}
        <Route path="/configuration/list" element={<ConfigurationListPage />} />

        <Route path="/train/:id" element={<TrainPage />} />
        <Route path="/inference/ipcam" element={<IpcamInference />} />
        <Route
          path="/configuration/edit/:id"
          element={<ConfigurationCreatePage />}
        />
        <Route path="/inference/live" element={<LiveInference />} />
        <Route
          path="/inference/recorded/reports"
          element={<InferenceReports />}
        />

        <Route path="*" element={<ConfigurationListPage />} />
      </Routes>
    </PrivateLayout>
  );
};

export default PrivateRoutes;
