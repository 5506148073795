/* eslint-disable */
import { Box, Divider, Grid, Stack, styled } from '@mui/material';
import { Button, Card, Modal, Typography } from 'components';

import React, { useCallback } from 'react';
import ObjectCategoryCard from './objectCategoryCard';
import { useSelector } from 'react-redux';

import { useSyncObject } from 'api/sdk';
import { useSnack } from 'plugins/snack';
import { ConfigCameras } from 'store/config/schema';
import { object } from 'yup';
import { RootState } from 'store';
interface IAddObjectProps {
  onClose: () => void;
  selectedRois: {
    id: string;
    images: string;
    frames: string;
    boundingBox: number[];
    frameNumber: number;
    frameWidth: number;
    frameHeight: number;
  }[];
  // currentCameraDel: ConfigCameras | undefined;
  object: any;
  currentCamera: ConfigCameras | undefined;
  onRefresh: () => void;
}

const ObjectModel: React.FC<IAddObjectProps> = ({
  onClose,
  currentCamera,
  selectedRois,
  object,
  onRefresh
}) => {
  const { mutateAsync, isLoading } = useSyncObject();
  const NewConfigID = useSelector((state: RootState) => state.configV2.id);

  const [selectedActivities, setSelectedActivities] = React.useState<string[]>(
    []
  );

  const snack = useSnack();

  const handleUpdateClick = () => {
    if (!currentCamera || !object.objects) {
      console.error('Objects array is null or undefined');
      return;
    }
    // Prepare the data for the update
    const updatedObjects = object.objects.map((obj: any) => ({
      id: obj.id,
      classname: obj.name,
      category: obj.category,
      camera_id: currentCamera.id?.toString() ?? '',
      // images: obj.images ? obj.images.slice() : [] // Convert to array if null
      images: obj.images
        ? obj.images.map((imagePath: any) => ({
            id: imagePath.id,
            image_path: imagePath.image_path || null,
            frame_path: imagePath.frames || null,
            frame_number: imagePath.frameNumber || null,
            frame_width: imagePath.frameWidth || null,
            frame_height: imagePath.frameHeight || null,
            bounding_box: imagePath.boundingBox || null
          }))
        : null
    }));
    // Create new images objects from selectedRois
    const filteredNewImages = selectedRois.map(imagePath => ({
      id: imagePath.id, // Assuming image details ID is the same as the object ID
      image_path: imagePath.images.trim(),
      frame_path: imagePath.frames.trim(),
      frame_number: imagePath.frameNumber,
      frame_width: imagePath.frameWidth,
      frame_height: imagePath.frameWidth,
      bounding_box: imagePath.boundingBox,
      thumb_url: ''
    }));
    // Iterate through updatedObjects and prepend filteredNewImages to images array of matching objects
    updatedObjects.forEach((obj: any) => {
      if (selectedActivities.includes(obj.id)) {
        if (obj.images) {
          // Filter new images by object id and prepend them to existing images
          const newImages = filteredNewImages;

          obj.images.unshift(...newImages);
        } else {
          // If obj.images is null or undefined, assign filtered new images
          obj.images = filteredNewImages.filter(image => image.id === obj.id);
        }
      }
    });
    mutateAsync({
      cameraId: currentCamera.id?.toString() ?? '',
      configId: NewConfigID?.toString() ?? '',
      data: updatedObjects
    }).then(() => {
      // snack({
      //   message: 'Images updated successfully',
      //   severity: 'success'
      // });
      snack({
        message: 'Images added successfully',
        severity: 'success'
      });
    });
    onClose();
    setTimeout(() => {
      onRefresh();
    }, 5000);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2}>
          <Grid
            container
            spacing={2}
            style={{ maxHeight: '450px', overflowY: 'auto' }}
          >
            <>
              {object.objects?.map((item: any, index: number) => (
                <>
                  <Grid item lg={4} key={index} display={'block'}>
                    <ObjectCategoryCard
                      object={item}
                      checked={selectedActivities.includes(item.id)}
                      onCheck={() => {
                        if (selectedActivities.includes(item.id)) {
                          setSelectedActivities(
                            selectedActivities.filter(obj => obj !== item.id)
                          );
                        } else {
                          setSelectedActivities([
                            ...selectedActivities,
                            item.id
                          ]);
                        }
                      }}
                    />
                  </Grid>
                </>
              ))}
            </>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Stack direction="row" spacing={2} justifyContent={'center'}>
            <Button
              type="submit"
              variant="contained"
              version="light"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                handleUpdateClick();
              }}
            >
              Add To Selected
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default ObjectModel;
