/* eslint-disable */
import React from 'react';
import { Button } from '@mui/material';

interface ButtonProps {
  startIcon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  color?: string;
  backgroundColor?: string;
  padding?: string;
  border?: string;
  radius?: string;
  shadow?: string;
  text?: string;
  centerIcon?: React.ReactNode;
  cursor?: string;
}

const ButtonComponent: React.FC<ButtonProps> = props => {
  const {
    startIcon,
    onClick,
    disabled,
    variant,
    color,
    backgroundColor,
    padding,
    border,
    radius,
    shadow,
    text,
    centerIcon,
    cursor
  } = props;

  return (
    <Button
      sx={{
        '&:hover': {
          transform: 'scale(1.02)'
        }
      }}
      startIcon={startIcon}
      onClick={onClick}
      disabled={disabled}
      variant={disabled ? 'text' : variant}
      style={{
        width: '100%',
        height: '100%',
        color: disabled ? 'rgb(90,90,90)' : color,
        backgroundColor: disabled ? 'rgb(200, 200, 200)' : backgroundColor,
        padding: padding,
        border: border,
        borderRadius: radius,
        boxShadow: shadow,
        cursor: cursor
      }}
    >
      {text}
      {centerIcon}
    </Button>
  );
};

ButtonComponent.defaultProps = {
  color: 'black',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  radius: '0px',
  disabled: false
};

export default ButtonComponent;
