/* eslint-disable */
// Dependency imports

import {
  List,
  ListItem,
  Collapse,
  styled,
  ListItemButton
} from '@mui/material';
import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Local imports
import { RootState } from '../../store';
import routes from 'routes/privateRoutesConfig';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import {
  setEndProcess,
  setPreventNavigation
} from 'store/preventNavigation/preventNavigationSlice';

interface IMenuModeProps {
  mode: 'light' | 'dark';
  subMenu?: boolean;
}

const StyledList = styled(List)<IMenuModeProps>(({ theme, mode }) => ({
  minWidth: '200px',
  backgroundColor:
    mode === 'light'
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main,
  color:
    mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText
}));

const StyledListItem = styled(ListItem)<
  IMenuModeProps & { active: boolean; disabled?: boolean }
>(({ theme, mode, active, subMenu, disabled }) => ({
  padding: '5px 0',
  paddingLeft: subMenu ? '16px' : '0',
  // color: active ? theme.palette.primary.main : 'inherit',
  borderBottom: `1px solid ${
    mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText
  }`,
  ':last-of-type': {
    borderBottom: 'none'
  },
  backgroundColor: active ? theme.palette.primary.contrastText : 'inherit',
  ...(active && {
    color: '#000 !important',
    backgroundColor: '#EDEDED'
  }),
  ...(disabled && {
    pointerEvents: 'none',
    opacity: 0.5
  })
}));

const AppMenuContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '100%',
  marginTop: '64px'
}));

const AppMenu: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state: RootState) => state.auth.profile);
  const preventNavigation = useSelector(
    (state: RootState) => state.preventNavigation.preventNavigation
  );

  const [openIndex, setOpenIndex] = useState<number | null>(() => {
    let opened = null;
    Object.keys(routes).forEach((key, index) => {
      const item = routes[key];
      Object.keys(item?.items || {}).forEach((key, idx) => {
        const subItem = (item?.items || {})[key];

        if (location.pathname.includes(subItem.route || '')) {
          opened = index;
        }
      });
    });
    return opened;
  });

  return (
    <AppMenuContainer>
      <StyledList disablePadding mode="dark">
        {Object.keys(routes).map((key, index) => {
          const item = routes[key];
          const isOpened = openIndex === index;
          const path = item.route || '';
          return (
            <>
              {!(
                profile?.organization?.id ===
                  '2695594a-87fd-4431-8219-14d2f453e2c2' &&
                item?.name === 'Inference'
              ) && (
                <StyledListItem
                  mode="dark"
                  key={`app-menu-${index}`}
                  onClick={() => setOpenIndex(isOpened ? null : index)}
                  active={
                    location.pathname === path ||
                    location?.pathname.startsWith(path)
                  }
                  {...(!item?.items && {
                    onClick: () => {
                      if (preventNavigation) {
                        Swal.fire({
                          title: 'Warning: Ongoing Process!!',
                          icon: 'warning',
                          html: `
                          Are you sure you want to leave?
                        `,
                          // text: 'Are you sure you want to leave?',
                          allowOutsideClick: false,
                          confirmButtonText: 'Leave!',
                          showCancelButton: true,
                          confirmButtonColor: '#E33030',
                          cancelButtonColor: '#102633',
                          cancelButtonText: 'Cancel!',
                          focusCancel: true,
                          animation: true,

                          customClass: {
                            title: 'swal2-title2',
                            container: 'swal2-html-cont2',
                            actions: 'swal2-actions2',
                            htmlContainer: 'swal2-html'
                          }
                        }).then(async result => {
                          if (result.isConfirmed) {
                            dispatch(setPreventNavigation(false));
                            item.route && navigate(item.route);
                          }
                        });
                      } else {
                        item.route && navigate(item.route);
                      }
                    }
                  })}
                >
                  <ListItemButton>{item.name}</ListItemButton>
                  {item?.items ? (
                    isOpened ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : (
                    <> </>
                  )}
                </StyledListItem>
              )}

              <Collapse in={openIndex == index} timeout="auto" unmountOnExit>
                <StyledList
                  disablePadding
                  mode="light"
                  subMenu={item?.items ? true : false}
                >
                  {Object.keys(item?.items || {}).map((key, idx) => {
                    const subItem = (item?.items || {})[key];
                    // Check if profile?.organization?.id matches the specified value
                    if (
                      (profile?.organization?.id ===
                        '2695594a-87fd-4431-8219-14d2f453e2c2' &&
                        (subItem.name === 'Historical' ||
                          subItem.name === 'System' ||
                          subItem.name === 'Scheduler' ||
                          subItem.name === 'Live' ||
                          subItem.name === 'Recorded')) ||
                      (profile?.organization?.id ===
                        'ff9c79f3-c142-4a61-9932-c68c69fe05e1' &&
                        (subItem.name === 'Recorded' ||
                          subItem.name === 'Historical' ||
                          subItem.name === 'System' ||
                          subItem.name === 'Scheduler'))
                    ) {
                      return null; // Skip rendering the item if it matches any of the specified names
                    }
                    return (
                      <StyledListItem
                        mode="light"
                        key={`app-menu-${index}-${idx}`}
                        onClick={() => {
                          subItem.route &&
                            navigate(`${item.route}${subItem.route}`);
                        }}
                        active={location.pathname === `${path}${subItem.route}`}
                        subMenu
                        disabled={subItem.disabled}
                      >
                        <ListItemButton
                          LinkComponent={'a'}
                          disabled={
                            location.pathname === `${path}${subItem.route}`
                          }
                        >
                          {subItem.name}
                        </ListItemButton>
                      </StyledListItem>
                    );
                  })}
                </StyledList>
              </Collapse>
            </>
          );
        })}
      </StyledList>
    </AppMenuContainer>
  );
};
export default AppMenu;
