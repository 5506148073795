/* eslint-disable */
import React, { useEffect, useMemo, useRef } from 'react';
import { Stage, Layer } from 'react-konva';
import { Point } from 'schemas';
import CanvasLayer from './CanvasLayer';
import Konva from 'konva';

// Styles for wrapper and column
const wrapperStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  // backgroundColor: '#000000',
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: 1
};
const columnStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center'
};

export interface ROI {
  points: Point[];
  color: string;
  isVisible: boolean;
  isLocked: boolean;
  isEditing: boolean;
}

interface Resolution {
  width: number;
  height: number;
}

interface CanvasProps {
  rois: ROI[];
  size: Resolution;
  resolution: Resolution;
  version?: number;
  setTempRoiPoints?: (points: Point[]) => void;
}

/**
 * Canvas component for drawing polygons on an image.
 */
const Canvas: React.FC<CanvasProps> = ({
  rois,
  size,
  resolution,
  version,
  setTempRoiPoints
}) => {
  const stageRef = useRef<Konva.Stage>(null);

  const scale = useMemo(() => {
    const scaleX = resolution.width ? size.width / resolution.width : 1;
    const scaleY = resolution.height ? size.height / resolution.height : 1;
    const scale = Math.min(scaleX, scaleY);
    return {
      x: scale,
      y: scale
    };
  }, [size, resolution]);

  useEffect(() => {
    if (stageRef.current) {
      stageRef.current.width(size.width);
      stageRef.current.height(size.height);
    }
  }, [size]);

  return (
    <div style={wrapperStyle}>
      <div style={columnStyle}>
        <Stage
          ref={stageRef}
          height={size?.height}
          width={size?.width}
          scale={scale}
        >
          {rois?.map((roi, index) => {
            return (
              <CanvasLayer
                key={index}
                // stage={stageRef.current}
                color={roi?.color}
                isVisible={roi?.isVisible}
                isLocked={roi?.isLocked}
                isEditing={roi?.isEditing}
                scale={scale}
                version={version}
                initialPoints={roi?.points?.map(point => {
                  return point;
                })}
                setTempRoiPoints={setTempRoiPoints}
              />
            );
          })}
        </Stage>
      </div>
    </div>
  );
};

export default Canvas;
