import { configureStore, combineReducers } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import config2Reducer, { updateCropsApiMiddleware } from './config/configSlice';
import objectsReducer from './config/objects';
import configReducer from './configSlice';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import inferenceReducer from './inference';
import activitiesReducer, { addActivityMiddleware } from './config/activities';
import paginationReducer from './pagination/paginationSlice';
import preventNavigationReducer from './preventNavigation/preventNavigationSlice';

const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  configV2: config2Reducer,
  objects: objectsReducer,
  activities: activitiesReducer,
  inference: persistReducer(persistConfig, inferenceReducer),
  pagination: persistReducer(persistConfig, paginationReducer),
  preventNavigation: preventNavigationReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE']
      }
    })
      .concat(updateCropsApiMiddleware)
      .concat(addActivityMiddleware)
});

const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
