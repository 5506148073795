/* eslint-disable */
import * as React from 'react';
import { Card } from 'components';
import { Grid, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { ImagesSchema, ObjectsSchemaImages } from 'schemas';

interface BoundingBoxListProps {
  title: string;
  objectType: string;
  images: { thumb_url: string }[];
}
const ImageContainer = styled('div')(({ theme }) => ({
  height: 50,
  width: 50,
  borderRadius: 5,
  backgroundColor: 'gray',
  float: 'left',
  marginRight: 10,
  marginBottom: 10,
  border: `1px solid gray`,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 5
  }
}));

export const BoundingBoxList: React.FC<BoundingBoxListProps> = ({
  title,
  objectType,
  images
}) => {
  const renderedImages = React.useMemo(() => {
    if (!images) return [];
    else if (images) {
      return images?.slice(-3)?.reverse();
    }
  }, [images?.length]);

  return (
    <Card style={{ background: 'white', color: '#373737', height: '100%' }}>
      <Stack
        direction="column"
        justifyContent="space-between"
        style={{ marginBottom: '10px' }}
      >
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography style={{ fontSize: '14px' }}>
          Object Type: {objectType}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} width={'100%'}>
        <Grid>
          {renderedImages &&
            renderedImages.map((image, index) => (
              <>
                <ImageContainer key={index}>
                  <img src={image?.thumb_url || ''} draggable={false} />
                </ImageContainer>
              </>
            ))}
        </Grid>
      </Stack>
    </Card>
  );
};
