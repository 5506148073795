import { Box, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { Button, ITableHeadColumn, Table, Typography } from 'components';
import { TextField } from 'components/Form';
import { Formik } from 'formik';
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';
import React, { useMemo } from 'react';
import {
  HTTPValidationError,
  ModulesTrainingActivitiesSchemasActivitySchema,
  ModulesTrainingAnalysisSchemasWorkflow
} from 'schemas';
import * as Yup from 'yup';

interface IProps {
  config_id: string;
  camera_length: number;
  activities?: ModulesTrainingActivitiesSchemasActivitySchema[];
  workflow_data: ModulesTrainingAnalysisSchemasWorkflow[];
  onSumbit: (model_name: string, epochs: number) => void;
  loading?: boolean;
  error?: AxiosError<HTTPValidationError, any> | null;
  trainingStarted?: boolean;
}

interface FormValues {
  modal_name: string;
  config_id: string;
  epochs: number;
}

const TrainSummaryModal: React.FC<IProps> = ({
  config_id,
  activities,
  camera_length,
  workflow_data,
  onSumbit,
  error,
  trainingStarted
}) => {
  const initialValues: FormValues = {
    modal_name: '',
    config_id: config_id,
    epochs: 300
  };

  const validationSchema = Yup.object().shape({
    modal_name: Yup.string().required('Model Name is required'),
    config_id: Yup.string().required('Config ID is required'),
    epochs: Yup.number()
      .min(1, 'Epochs must be greater than 0')
      .max(500, 'Epochs must be less or equal to 500')
      .typeError('Epochs must be a number')
      .required('Epochs is required')
  });

  const handleSubmit = (values: FormValues) => {
    onSumbit(values.modal_name, values.epochs);
  };

  const columns: Array<ITableHeadColumn> = [
    {
      key: 'activity',
      value: 'Activity',
      width: '50%',
      align: 'center',
      render: ({ value }) => (
        <Typography variant="subtitle2" textAlign={'center'}>
          {value as string}
        </Typography>
      )
    },
    {
      key: 'dependency',
      value: 'Dependency',
      width: '50%',
      align: 'center',
      render: ({ value }) => (
        <Typography variant="subtitle2" textAlign={'center'}>
          {value as string}
        </Typography>
      )
    }
  ];

  const activitiesMap = useMemo(() => {
    const map: {
      [key: string]: ModulesTrainingActivitiesSchemasActivitySchema;
    } = {};

    activities?.forEach(activity => {
      map[activity.id] = activity;
    });
    return map;
  }, [activities]);

  return (
    <Box>
      {trainingStarted ? (
        <>
          <Box my={4}>
            <Typography
              variant="h6"
              color="primary"
              textAlign={'center'}
              style={{
                fontWeight: 'bold'
              }}
            >
              Model training has started!
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Formik<FormValues>
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <table width={'100%'} className="modal-summary-table">
                    <tr>
                      <td>
                        <Typography
                          variant="subtitle2"
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          No. of Videos
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="subtitle2">
                          {camera_length}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography
                          variant="subtitle2"
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          No. of Activities
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="subtitle2">
                          {activities?.length || 0}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography
                          variant="subtitle2"
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          Model Name
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="subtitle2">
                          <TextField
                            name="modal_name"
                            type="text"
                            variant="outlined"
                            size="small"
                          />
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style={{ display: 'flex' }}>
                          {' '}
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: 'bold'
                            }}
                          >
                            Epochs
                          </Typography>
                        </div>
                      </td>
                      <td>
                        <Typography variant="subtitle2">
                          <TextField
                            name="epochs"
                            type="number"
                            variant="outlined"
                            size="small"
                          />
                        </Typography>
                      </td>
                    </tr>
                  </table>
                </div>
                {/* <Box mt={2}>
                  <Table
                    columns={columns}
                    rows={workflow_data.map((workflow, index) => {
                      return {
                        activity:
                          activitiesMap[workflow.activity_id]?.activity || '',
                        dependency: workflow.dependency
                          ? activitiesMap[workflow.dependency]?.activity || ''
                          : 'Na'
                      };
                    })}
                    striped
                  />
                </Box> */}
                {error && (
                  <Typography variant="subtitle2" color="error">
                    {error.message || ''}
                  </Typography>
                )}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={3}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ backgroundColor: '#295D65' }}
                  >
                    Start Training
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </>
      )}
    </Box>
  );
};

export default TrainSummaryModal;
