/* eslint-disable */
import { Checkbox, Grid, IconButton } from '@mui/material';
import { Typography } from 'components';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from 'plugins/confirm';

interface ICroppedItemProps {
  title: string;
  duration: string;
  timeDuration?: string;
  checked: boolean;
  onCheck: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  hasAction?: boolean;
}

const CroppedItem: React.FC<ICroppedItemProps> = ({
  title,
  duration,
  timeDuration,
  checked,
  onCheck,
  onEdit,
  onDelete,
  hasAction
}) => {
  const confirm = useConfirm();

  const handleDeleteClick = () => {
    confirm({
      title: 'Delete Cropped Item',
      content: 'Are you sure you want to delete this cropped item?'
    })
      .then(() => {
        onDelete && onDelete();
      })
      .catch(() => {
        // Do nothing
      });
  };

  return (
    <Grid container spacing={0} width={'100%'}>
      <Grid item xs={1}>
        <Checkbox
          checked={checked}
          onChange={onCheck}
          style={{ marginLeft: '-10px' }}
        />
      </Grid>
      <Grid item xs={9}>
        <table width={'100%'}>
          <tr>
            <td>
              <Typography variant="caption" fontWeight={'bold'}>
                {title}
              </Typography>
            </td>
            <td>
              <Typography variant="caption" fontWeight={'bold'}>
                :{duration}
              </Typography>
            </td>
          </tr>
          {timeDuration && (
            <tr>
              <td>
                <Typography variant="caption">Time Duration</Typography>
              </td>
              <td>
                <Typography variant="caption">:{timeDuration}</Typography>
              </td>
            </tr>
          )}
        </table>
      </Grid>
      {hasAction && (
        <Grid
          item
          xs={2}
          display="flex"
          flexDirection={'row'}
          justifyContent={'right'}
        >
          <IconButton disableRipple>
            <EditIcon fontSize="small" color="primary" onClick={onEdit} />
          </IconButton>
          <IconButton disableRipple>
            <DeleteIcon
              fontSize="small"
              color="primary"
              onClick={handleDeleteClick}
            />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default CroppedItem;
