import { useAddToLabelAssist } from 'api/sdk';
import useVideo from 'hooks/video';
import { Snack } from 'plugins/snack/Snack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LabelAssistMode, Point } from 'schemas';
import {
  addCropItem,
  addRoi,
  deleteCropItem,
  deleteRoi,
  selectCameras,
  selectConfig,
  selectCurrentCamera,
  setCurrentCameraId
} from 'store/config/configSlice';
import { ROI } from 'store/config/schema';
import { getRandomColor } from 'utils';

interface ActionState {
  state: 'initial' | 'crop' | 'roi';
  type?: 'crop' | 'roi';
  itemIndex?: number;
  data?: {
    start: number;
    end: number;
  };
}

export const useCropStep = (onSuccess: () => void) => {
  const dispatch = useDispatch();
  const { id: configId, currentCameraId } = useSelector(selectConfig);
  const cameras = useSelector(selectCameras);
  const currentCamera = useSelector(selectCurrentCamera);

  const [videoResolution, setVideoResolution] = useState<{
    width: number;
    height: number;
  }>({
    width: currentCamera?.video_metadata?.resolution[0] || 0,
    height: currentCamera?.video_metadata?.resolution[1] || 0
  });

  const [actionState, setActionState] = useState<ActionState>({
    state: 'initial'
  });

  const video = useVideo();
  const { reset, videoRef } = video;

  const videoContainerRef = useRef<HTMLDivElement>(null);

  const [tempRoi, setTempRoi] = useState<ROI | null>();
  const [tempRoiPoints, setTempRoiPoints] = useState<Point[]>([]);
  const [canvasVersion, setCanvasVersion] = useState<number>(1);
  const [roiIndex, setRoiIndex] = useState<number>(() => {
    return (currentCamera?.roi.length || 0) + 1;
  });
  const { mutateAsync: addToPipeline, isLoading: loadingPipeline } =
    useAddToLabelAssist();
  //   change the current camera
  const changeCamera = useCallback(
    (cameraId: string) => {
      dispatch(setCurrentCameraId(cameraId));
    },
    [dispatch]
  );

  // reset the video when the current camera changes
  useEffect(() => {
    if (currentCamera) {
      reset(currentCamera.lowres_url || '');
    }
  }, [currentCamera]);

  const handleAddRoiClick = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    const count = currentCamera?.roi.length || 0;

    //   update the action state
    setActionState({ state: 'roi', itemIndex: -1 });

    //   set the temp roi
    setTempRoi({
      id: `${roiIndex}`,
      name: `ROI ${roiIndex}`,
      points: [],
      color: getRandomColor(),
      isVisible: true,
      isLocked: false,
      isEditing: true
    });
    setTempRoiPoints([]);
    setRoiIndex(prev => prev + 1);
    setCanvasVersion(prev => prev + 1);
  }, [videoRef, setActionState, setTempRoi, currentCamera]);

  //  cancel the roi action
  const cancelRoiAction = useCallback(() => {
    if (actionState.itemIndex === -1) {
      setRoiIndex(prev => prev - 1);
    }
    setActionState({ state: 'initial' });
    setTempRoiPoints([]);
    setTempRoi(null);
  }, [actionState]);

  //   reset the roi points to the initial points
  const resetRoiAction = useCallback(() => {
    setTempRoiPoints(tempRoi?.points || []);
    setCanvasVersion(prev => prev + 1);
  }, [tempRoi]);

  //   handle the click event of the edit roi, display the roi drawing on the canvas
  const editRoiClick = useCallback(
    (idx: number) => {
      const roi = currentCamera?.roi[idx];
      if (roi) {
        setActionState({ state: 'roi', itemIndex: idx });
        setTempRoi(roi);
        setTempRoiPoints(roi.points);
        setCanvasVersion(prev => prev + 1);
      }
    },
    [currentCamera]
  );

  //   save the roi action
  const saveRoiAction = useCallback(() => {
    if (tempRoi && tempRoiPoints.length > 2) {
      dispatch(
        addRoi({
          cameraId: currentCameraId || '',
          roi: {
            ...tempRoi,
            points: tempRoiPoints
          },
          idx: actionState.itemIndex || 0
        })
      );
    }
    setActionState({ state: 'initial' });
    setTempRoiPoints([]);
    setTempRoi(null);
    setCanvasVersion(prev => prev + 1);
  }, [dispatch, tempRoi, tempRoiPoints, currentCameraId, actionState]);

  //   delete the roi
  const handleDeleteRoi = useCallback(
    (roiId: string) => {
      dispatch(
        deleteRoi({
          cameraId: currentCameraId || '',
          roiId
        })
      );
    },
    [dispatch, currentCameraId]
  );

  //   handle the click event of the add crop button
  const handleAddCropClick = useCallback(() => {
    setActionState({ state: 'crop', itemIndex: -1 });
  }, [setActionState]);

  //   handle the click event of the save crop button
  const handleSaveCropClick = useCallback(
    (start: number, end: number, editIndex?: number) => {
      dispatch(
        addCropItem({
          cameraId: currentCameraId || '',
          crop: {
            start,
            end
          },
          idx: actionState.itemIndex || 0
        })
      );
      setActionState({ state: 'initial' });
    },
    [setActionState, dispatch, currentCameraId, actionState]
  );

  //   handle the click event of the cancel crop button
  const handleCancelCropClick = useCallback(() => {
    setActionState({ state: 'initial' });
  }, [setActionState]);

  //   handle the click event of the edit crop button
  const handleEditCropClick = useCallback(
    (idx: number, start: number, end: number) => {
      setActionState({ state: 'crop', itemIndex: idx, data: { start, end } });
    },
    [setActionState]
  );

  //   handle the click event of the delete crop button
  const handleDeleteCrop = useCallback(
    (idx: number) => {
      dispatch(
        deleteCropItem({
          cameraId: currentCameraId || '',
          idx
        })
      );
      setActionState({ state: 'initial' });
    },
    [setActionState, dispatch, currentCameraId]
  );
  const handleApplyToAll = useCallback(() => {
    // }).then(() => {});
  }, []);

  /**
   * Update data when the current camera changes
   * 1. Update the video resolution
   * 2. Update the roi index
   * 3. Update the canvas version
   * 4. Reset the temp roi
   * 5. Reset the temp roi points
   */
  useEffect(() => {
    if (currentCamera) {
      setVideoResolution({
        width: currentCamera.video_metadata?.resolution[0] || 0,
        height: currentCamera.video_metadata?.resolution[1] || 0
      });
      setRoiIndex((currentCamera.roi.length || 0) + 1);
      setCanvasVersion(prev => prev + 1);
      setTempRoi(null);
      setTempRoiPoints([]);
    }
  }, [currentCamera]);

  const handleUpdateClick = useCallback(
    (mode: LabelAssistMode) => {
      // Call the updateCamera function if it's defined
      addToPipeline({
        data: {
          config_id: configId || '',
          mode: mode
        }
      })
        .then(() => {
          Snack({
            message: 'Pipeline started successfully',
            severity: 'success'
          });
          onSuccess();
        })
        .catch(error => {
          console.log(error);
        });
    },
    [addToPipeline, configId, onSuccess]
  );

  return {
    currentCamera,
    cameras,
    loadingPipeline,
    videoResolution,
    actionState,
    video,
    videoContainerRef,
    canvasVersion,
    tempRoi,
    tempRoiPoints,

    setTempRoiPoints,
    handleUpdateClick,
    setActionState,
    changeCamera,
    handleAddRoiClick,
    editRoiClick,
    resetRoiAction,
    cancelRoiAction,
    saveRoiAction,
    handleDeleteRoi,
    handleAddCropClick,
    handleCancelCropClick,
    handleSaveCropClick,
    handleEditCropClick,
    handleDeleteCrop,
    setCanvasVersion,
    handleApplyToAll
  };
};
