import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const GridSkeliton = ({ data }: any) => {
  return (
    <Grid container spacing={1}>
      {data.map(() => {
        return (
          <Grid item xs={4}>
            <Skeleton width="100%" height="200px" />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default GridSkeliton;
