/* eslint-disable */
import { Formik } from 'formik';
import React from 'react';
import { TextField, SelectField } from 'components/Form';
import { Button } from 'components';
import { Box, Grid, Stack } from '@mui/material';
import * as Yup from 'yup';

interface FormValues {
  new_name: string;
  category: string;
}

interface IMergeObjectsFormProps {
  name: string;
  category: string;
  onClose: () => void;
  onSaved: (values: FormValues) => void;
}

const validationSchema = Yup.object().shape({
  new_name: Yup.string()
    .trim()
    .required('Name is required')
    .max(120, 'Name must be at most 120 characters'),
  category: Yup.string().required('Category is required')
});

const MergeObjectsForm: React.FC<IMergeObjectsFormProps> = ({
  name,
  category,
  onClose,
  onSaved
}) => {
  const initialValues: FormValues = {
    new_name: '',
    category: category
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSaved(values);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ m: 2 }} boxSizing={'border-box'}>
            <Stack spacing={3}>
              <TextField name="new_name" label="New Name" variant="outlined" />
              <SelectField
                name="category"
                label="Object Type"
                options={[
                  { value: 'People', label: 'People' },
                  { value: 'Moving Object', label: 'Moving Object' },
                  { value: 'Stationary Object', label: 'Stationary Object' }
                ]}
                variant="outlined"
              />
              <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
              >
                <Button
                  type="submit"
                  variant="contained"
                  version="light"
                  fullWidth
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" fullWidth>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default MergeObjectsForm;
