/* eslint-disable  */

import React, { useEffect, useState } from 'react';
import GridLayout, { Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import {
  DailywigetRegistry,
  HistorywigetRegistry,
  LivewidgetRegistry
} from './layout';
import {
  ComponentLayout,
  DefaultLayout,
  LivewidgetTypes
} from '../../schemas/dashboardConfigSchema';

interface DraggableScreeTyles {
  layout: any;
  onDelete: any;
  extraDescription: any;
  getScreen: string;
  handleChangeLayout: any;
}

const DraggableScreen: React.FC<DraggableScreeTyles> = ({
  layout,
  onDelete,
  extraDescription,
  getScreen,
  handleChangeLayout
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [getLayout, setLayout] = useState<any>(() => {
    if (getScreen === 'live') {
      return LivewidgetRegistry;
    } else if (getScreen === 'daily') {
      return DailywigetRegistry;
    } else if (getScreen === 'historical') {
      return HistorywigetRegistry;
    }
  });
  const [containerSize, setContainerSize] = useState<any>({
    width: 0,
    height: 0
  });

  useEffect(() => {
    if (containerRef.current) {
      setContainerSize({
        width: containerRef?.current?.offsetWidth,
        height: containerRef?.current?.offsetHeight
      });
    }
  }, [containerRef]);
  return (
    <div
      ref={containerRef}
      style={{
        marginTop: '10px',
        width: '100%',
        minHeight: '190px'
      }}
      className="layout-container"
    >
      {getLayout && (
        <GridLayout
          className="layout"
          layout={layout?.map((item: ComponentLayout) => {
            return {
              i: item?.component,
              x: item?.position?.x,
              y: item?.position?.y,
              w: item?.position?.w,
              h: item?.position?.h,
              minH: getLayout[item?.component]?.minH,
              maxH: getLayout[item?.component]?.maxH,
              minW: getLayout[item?.component]?.minW,
              maxW: getLayout[item?.component]?.maxW,
              static: getLayout[item?.component]?.static
            };
          })}
          cols={4}
          rowHeight={50}
          width={containerSize.width}
          containerPadding={[10, 10]}
          onLayoutChange={handleChangeLayout}
        >
          {layout?.map((item: ComponentLayout) => {
            const Componnent = getLayout[item?.component]?.component;
            return (
              <div key={String(item.component)}>
                {Componnent && (
                  <Componnent
                    onDelete={() => {
                      onDelete(item?.component);
                    }}
                    description={extraDescription[item?.component]}
                  />
                )}
              </div>
            );
          })}
        </GridLayout>
      )}
    </div>
  );
};

export default DraggableScreen;
