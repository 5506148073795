// Dependency imports
import {
  IconButton,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import React, { useState, useEffect } from 'react';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Local imports
import { Card } from 'components';
import { Barchart } from 'components/charts/barchart';
import { getColorList } from 'utils';
import styled from 'styled-components';

const MultiReport = ({
  getVideo,
  setVideo,
  indefenceVideoData,
  openState,
  setOpenState,
  reportMetaData,
  getConfigId
}: any) => {
  const [barColors, setBarColors] = useState<string[]>([]);

  // Extract and transform data
  const reportLabel = indefenceVideoData?.map((data: any) => {
    const isSequenceTrue = data?.graph_data?.sequence?.every((seq: any) =>
      seq.every((val: boolean) => val)
    );

    return {
      name: data.name,
      isSequenceTrue: isSequenceTrue
    };
  });

  // Initialize dataset structure
  const activityDurations: { [key: string]: number[] } = {};

  indefenceVideoData?.forEach((video: any) => {
    const { graph_data } = video;

    if (graph_data?.activities && graph_data?.duration) {
      graph_data.activities.forEach((activity: string, index: number) => {
        if (!activityDurations[activity]) {
          activityDurations[activity] = Array(reportLabel.length).fill(0);
        }
        const videoIndex = reportLabel.findIndex(
          (label: any) => label.name === video.name
        );
        if (videoIndex !== -1) {
          activityDurations[activity][videoIndex] = graph_data.duration[index];
        }
      });
    }
  });

  // Get unique activities count
  const uniqueActivities = Object.keys(activityDurations);
  const activityCount = uniqueActivities.length;

  // Generate colors for each unique activity
  useEffect(() => {
    const colors = getColorList(uniqueActivities);
    setBarColors(colors);
  }, [activityCount]);

  const originalLabels =
    reportLabel && reportLabel.length > 0
      ? reportLabel?.map((value: any) => value.name)
      : [];

  const getTrimmedLabel = (label: string) => {
    return label.length > 10 ? label.slice(0, 10) + '...' : label;
  };

  // Transform the collected data into the datasets format
  const datasets = uniqueActivities.map((activity, index) => ({
    label: activity,
    data: activityDurations[activity],
    backgroundColor: barColors[index % barColors.length] || '#000000',
    borderWidth: 0.4,
    borderColor: 'white' // Use modulo to cycle through colors
  }));

  // Find the maximum value in the datasets
  const maxValue = Math.max(...datasets.flatMap(dataset => dataset.data)) + 2;

  // Prepare the chart data
  const data: ChartData<'bar'> = {
    labels: originalLabels,
    datasets: datasets
  };

  const options: ChartOptions<'bar'> = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'start',
        maxHeight: 200,
        maxWidth: 300,
        labels: {
          boxHeight: 10,
          boxWidth: 10,
          textAlign: 'left',
          usePointStyle: true,
          font: {
            size: 10
          }
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: tooltipitems => {
            const tooltipItem = tooltipitems[0];
            const dataIndex = tooltipItem.dataIndex;
            const modelName = indefenceVideoData[dataIndex]?.model?.model_name;
            const originalTitle = tooltipItem.label;

            return modelName
              ? `v: ${originalTitle}\nm: ${modelName}`
              : originalTitle;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Video (activities)'
        },
        ticks: {
          autoSkip: false,
          minRotation: 30,
          maxRotation: 30,
          textStrokeWidth: 1,
          color: context => {
            if (reportLabel[context.index]?.isSequenceTrue) {
              return 'green';
            } else {
              return 'red';
            }
          },
          font: context => {
            const label = context.tick.label as string;
            if (label === getVideo) {
              return {
                size: 14,
                weight: 'bold'
              };
            }
            return {
              size: 12,
              weight: 'normal'
            };
          },
          callback: (value, index, values) => {
            const originalLabel = originalLabels[index];
            return getTrimmedLabel(originalLabel);
          }
        }
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Duration(sec)'
        },
        border: {
          display: true,
          color: 'white',
          width: 1
        },
        suggestedMax: maxValue // Set the upper limit here
      }
    },
    onClick: (event: any, elements, chart: any) => {
      const points: any = chart.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: true },
        true
      );

      const mouseEvent = event.native as MouseEvent;

      const xPos = mouseEvent.offsetX;
      const xAxis = chart.scales['x'];
      xAxis.ticks.forEach((tick: any, index: any) => {
        const labelWidth = xAxis.width / xAxis.ticks.length;
        const startX = xAxis.left + labelWidth * index;
        const endX = startX + labelWidth;
        if (xPos >= startX && xPos <= endX) {
          setVideo(originalLabels[index]);
        }
      });
    }
  };

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000000',
      color: 'white',
      maxWidth: 220,
      fontSize: '10px',
      border: '1px solid #dadde9'
    }
  }));

  const selectedConfigData = reportMetaData.find(
    (data: any) => data?.configuration_id === getConfigId
  );

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width={'100%'}
      >
        <div style={{ display: 'flex' }}>
          <Typography fontWeight={'bold'}>Inference Reports</Typography>
          <CustomTooltip
            title={
              <React.Fragment>
                <div>Model Used: {selectedConfigData?.model?.model_name}</div>
                <div>
                  Created At:{' '}
                  {moment
                    .utc(selectedConfigData?.created_at)
                    .local()
                    .format('MMM DD, YY, h:mm a')}
                </div>
              </React.Fragment>
            }
          >
            <InfoOutlinedIcon
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            />
          </CustomTooltip>
        </div>

        {/* <div>
          Model Used:{' '}
          {
            reportMetaData.find(
              (data: any) => data?.configuration_id === getConfigId
            )?.model?.model_name
          }
        </div> */}
        <Stack>
          {openState === 0 ? (
            <IconButton onClick={() => setOpenState(1)}>
              <OpenInFullIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => setOpenState(0)}>
              <CloseFullscreenIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Barchart
        data={data}
        options={options}
        openState={openState}
        yAxisTitle="Unit ->"
      />
    </Card>
  );
};

export default MultiReport;
