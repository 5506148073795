/* eslint-disable  */
import { Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';

import { Button } from 'components';

import ObjectCategoryModel from './objectCategoryModel';
import { ObjectsSchema } from 'store/config/schema';

interface IMoveObjectModelProps {
  onMove: (selectedObjects: string) => void;
  onCancel: () => void;
  object: ObjectsSchema[];
  selectedDestObjects: string | null;
  setSelectedDestObjects: (value: string | null) => void;
}

const MoveObjectModel: React.FC<IMoveObjectModelProps> = ({
  onMove,
  onCancel,
  object,
  selectedDestObjects,
  setSelectedDestObjects
}) => {
  return (
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Grid
            container
            spacing={2}
            style={{ maxHeight: '450px', overflowY: 'auto' }}
          >
            <>
              {object?.map((item: any, index: number) => (
                <>
                  <Grid item lg={4} key={index}>
                    <ObjectCategoryModel
                      object={item}
                      checked={item.id === selectedDestObjects}
                      onCheck={id => {
                        setSelectedDestObjects(id);
                      }}
                    />
                  </Grid>
                </>
              ))}
            </>
          </Grid>
        </Grid>
        <Grid item sm={12} mt={3}>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
            <Button
              variant="contained"
              color="primary"
              onClick={onCancel}
              version="light"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                selectedDestObjects && onMove(selectedDestObjects);
              }}
              disabled={!selectedDestObjects}
            >
              Move to Selected
            </Button>
          </Stack>
        </Grid>
      </Grid>
  );
};

export default MoveObjectModel;
