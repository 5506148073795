// Dependency imports
import { DataGrid, gridClasses } from '@mui/x-data-grid';

// Styles import
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setRowsPerPage } from 'store/pagination/paginationSlice';
import { useState } from 'react';

const ODD_OPACITY = 0.2;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-sortIcon': {
    opacity: 1,
    color: 'white'
  },
  '& .MuiDataGrid-filterIcon': {
    opacity: 1,
    color: 'white'
  },
  '& .MuiDataGrid-menuIconButton': {
    opacity: 1,
    color: 'white'
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          )
        }
      }
    }
  },
  [`& .${gridClasses.row}.odd`]: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
}));

export type RecordValue =
  | string
  | number
  | undefined
  | object
  | React.ReactElement;

export type Record = {
  id: string; // Ensure that each record has a unique id
  [key: string]: RecordValue | RecordValue[];
};

interface DataGridProps {
  rows: Array<Record>;
  columns: any;
  loading?: boolean;
  checkboxSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
}

export const DataGridComponent = ({
  rows,
  columns,
  loading,
  checkboxSelection,
  disableRowSelectionOnClick
}: DataGridProps) => {
  const dispatch = useDispatch();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: useSelector((state: RootState) => state.pagination.rowsPerPage),
    page: 0 // Get rowsPerPage from Redux store
  });

  return (
    <Box
      sx={{
        height: 620,
        width: '100%',
        border: 'none',
        borderRadius: '6px',
        '& .super-app-theme--header': {
          backgroundColor: '#102633',
          border: 'none',
          color: 'white',
          fontSize: '16px',
          fontWeight: 'bold'
        }
      }}
    >
      <StyledDataGrid
        rows={rows}
        loading={loading}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton'
          }
        }}
        paginationModel={paginationModel}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        onPaginationModelChange={newModel => {
          setPaginationModel({
            pageSize: newModel.pageSize,
            page: newModel.page // Update the page value here
          });
          dispatch(setRowsPerPage(newModel.pageSize)); // Dispatch the new page size to Redux
        }}
        pageSizeOptions={[10, 20, 30]}
        columns={columns}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
      />
    </Box>
  );
};
