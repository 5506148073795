// Dependency imports
import {
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useMemo, useEffect } from 'react';

// Local imports
import { useListInferenceModels, useDeleteSequenceModel } from 'api/sdk';
import { Typography } from 'components';
import {
  ConfigurationCameraResponseSchema,
  InferenceModelSchema
} from 'schemas';
import LinearProgressWithLabel from 'components/progress/linearProgress';
import { useConfirm } from 'plugins/confirm';
import { Modal } from 'components';
import { useSnack } from 'plugins/snack';
import DependancyGraph from '../AnalysisConfiguration/WorkflowMonitor/dependancyGraph';
import Swal from 'sweetalert2';
import { Background } from 'reactflow';
import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';

// Style imports

interface DropdownListProps {
  record: ConfigurationCameraResponseSchema[];
  siteId: string;
  configId: string;
}

export const DropdownList = ({
  record,
  siteId,
  configId
}: DropdownListProps) => {
  const { data, isLoading, refetch } = useListInferenceModels(siteId, {
    config_id: configId
  });
  const [selectedModelId, setSelectedModelId] = useState<string | null>(null);
  const [refreshTimeout, setRefreshTimeout] = useState<any>();
  const confirm = useConfirm();
  const snack = useSnack();
  const { mutateAsync: deleteModel, isLoading: deleting } =
    useDeleteSequenceModel();

  const ActionToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  }));

  useEffect(() => {
    if (data && data?.data?.length > 0) {
      data?.data.every((item, index) => {
        if (item.status === 'TRAINING' || item.status === 'QUEUED') {
          const timeoutId = setTimeout(() => {
            refetch();
          }, 5000);
          setRefreshTimeout(timeoutId);
          return false;
        }
      });
    }

    // Clear timeouts
    return () => clearTimeout(refreshTimeout);
  }, [data]);

  const styleContainerCell = {
    flex: 1,
    padding: '10px 0'
  };

  const styleHeaderCell = {
    flex: 1,
    padding: '10px 0',
    background: '#102633'
  };

  const breakStyle = {
    height: '40px',
    width: '1px',
    background: '#fff'
  };

  const handleOpen = (modelId: string) => {
    setSelectedModelId(modelId);
  };

  const handleClose = () => {
    setSelectedModelId(null);
  };

  const handleDeleteModel = async (id: string) => {
    Swal.fire({
      title: 'Delete Model!',
      text: 'Are you sure you want to delete this model?',
      allowOutsideClick: false,
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      confirmButtonColor: '#E33030',
      cancelButtonColor: '#102633',
      cancelButtonText: 'No, cancel!',
      focusCancel: true,
      customClass: {
        title: 'swal2-title',
        container: 'swal2-html-container',
        actions: 'swal2-actions'
      }
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteModel({
            configId: configId,
            modelId: id
          });
          refetch();
          snack({
            message: 'Model deleted successfully',
            severity: 'success'
          });
        } catch (error: any) {
          console.log('error', error);
          snack({
            message:
              error?.response?.data?.detail ===
              'Cannot delete model due to existing dependencies. Constraint: inference_model'
                ? 'Cannot be deleted as the model is used for inference.'
                : 'Oops! something went wrong',
            severity: 'error'
          });
        }
      }
    });
  };

  const InfoPopover: React.FC<{ model: InferenceModelSchema }> = ({
    model
  }) => {
    const activities_map = model.workflow_config?.activities || {};
    const activities: any = Object.values(activities_map);
    const deps = model.workflow_config?.workflow || [];

    const nodes: any[] = useMemo(() => {
      return (deps as any).map((item: any, index: number) => ({
        id: item.id,
        data: {
          mendatory: item.mendatory,
          label:
            activities?.find(
              (activity: any) => activity.id === item.activity_id
            )?.activity ?? ''
        },
        position: { x: 50 * index, y: 100 * index }
      }));
    }, [deps, activities]);

    const edges = useMemo(() => {
      return (deps as Array<any>)
        .filter((item: any) => item.dependency !== null)
        .map((item: any) => ({
          id: `e-${item.dependency}-${item.activity_id}`, // Ensure this ID is unique
          source: item.dependency, // This ID should exist in nodes
          target: item.id // This ID should exist in nodes
        }));
    }, [deps]);

    return (
      <div style={{ width: '100%' }}>
        <DependancyGraph nodes={nodes} edges={edges} width={'100%'} />
      </div>
    );
  };

  return (
    <Stack direction={'column'}>
      {data?.data && data?.data?.length > 0 && (
        <Stack
          direction="row"
          gap={2}
          alignItems={'center'}
          justifyContent="space-between"
          sx={{ marginBottom: 1, background: '#102633' }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 600 }} color={'white'}>
              Model Name
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleContainerCell}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 600 }} color={'white'}>
              Created Date
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 600 }} color={'white'}>
              # of Videos Uploaded
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 600 }} color={'white'}>
              # of activities annotated
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleHeaderCell}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 600 }} color={'white'}>
              Status
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={styleHeaderCell}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 600 }} color={'white'}>
              Actions
            </Typography>
            <Stack />
          </Stack>
        </Stack>
      )}
      {data?.data.map((model, idx) => (
        <Stack
          key={model.id}
          direction="row"
          gap={2}
          alignItems={'center'}
          justifyContent="space-between"
          sx={{ marginBottom: 1, background: '#EEEEEE' }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleContainerCell}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 400 }}
              color={'#102633'}
            >
              {model.model_name}
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleContainerCell}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 400 }}
              color={'#102633'}
            >
              {moment.utc(model?.created_at).local().format('lll')}
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleContainerCell}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 400 }}
              color={'#102633'}
            >
              {Object.keys((model as any)?.workflow_config?.cameras).length}
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={styleContainerCell}
          >
            <Typography
              sx={{ fontSize: 12, fontWeight: 400 }}
              color={'#102633'}
            >
              {Object.keys((model as any)?.workflow_config?.activities).length}
            </Typography>
          </Stack>
          <div style={breakStyle}></div>
          {model?.status === 'TRAINING' ? (
            <ActionToolTip title={'Training'} style={{ cursor: 'pointer' }}>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={styleContainerCell}
              >
                <div
                  style={{
                    width: '70%'
                  }}
                >
                  <LinearProgressWithLabel value={model?.progress as number} />
                </div>
              </Stack>
            </ActionToolTip>
          ) : (
            <ActionToolTip
              title={
                model?.status === 'COMPLETED'
                  ? 'Completed'
                  : model?.status === 'QUEUED'
                  ? 'Queued'
                  : model?.status === 'FAILED'
                  ? model?.fail_reason
                  : 'Loading...'
              }
              style={{ cursor: 'pointer' }}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={styleContainerCell}
              >
                <Typography
                  sx={{ fontSize: 12, fontWeight: 600 }}
                  color={
                    model?.status === 'COMPLETED'
                      ? '#12AD2B'
                      : model?.status === 'QUEUED'
                      ? 'orange'
                      : model?.status === 'FAILED'
                      ? 'red'
                      : '#102633'
                  }
                >
                  {model?.status}
                </Typography>
              </Stack>
            </ActionToolTip>
          )}
          <div style={breakStyle}></div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={styleContainerCell}
          >
            <IconButton onClick={() => handleOpen(model.id)}>
              <InfoOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteModel(model?.id)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
          <Modal
            open={selectedModelId === model.id}
            onClose={handleClose}
            size="xl"
          >
            <InfoPopover model={model as any} />
          </Modal>
        </Stack>
      ))}
    </Stack>
  );
};
