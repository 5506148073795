/* eslint-disable  */
import React from 'react';
import { svgComponentTypes } from '../../schemas/dashboardConfigSchema';

export const FloorPlanSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          stroke="#707070"
          d="M-2487-8674h24v24h-24z"
          data-name="Rectangle 14320"
        />
      </clipPath>
    </defs>
    <g
      clipPath="url(#a)"
      data-name="Mask Group 268"
      transform="translate(2487 8674)"
    >
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        data-name="Icon feather-map"
      >
        <path
          d="M-2484-8666.727v13.091l5.727-3.273 6.545 3.273 5.728-3.273V-8670l-5.727 3.273-6.546-3.273Z"
          data-name="Path 64144"
        />
        <path d="M-2478.273-8670v13.228" data-name="Path 64145" />
        <path d="M-2471.727-8666.864v13.228" data-name="Path 64146" />
      </g>
    </g>
  </svg>
);

export const ProgressSvgComponent: React.FC<svgComponentTypes> = ({
  color
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14321"
            data-name="Rectangle 14321"
            width="24"
            height="24"
            transform="translate(-2421 -8715)"
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_269"
        data-name="Mask Group 269"
        transform="translate(2421 8715)"
        clipPath="url(#clip-path)"
      >
        <g
          id="Icon_feather-bar-chart-2"
          data-name="Icon feather-bar-chart-2"
          transform="translate(-2396 -8716) rotate(90)"
        >
          <path
            id="Path_64147"
            data-name="Path 64147"
            d="M18,21.254V10"
            transform="translate(1.5 0.746)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_64148"
            data-name="Path 64148"
            d="M12,22V4"
            transform="translate(0.75)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_64149"
            data-name="Path 64149"
            d="M6,20.753V14"
            transform="translate(0 1.247)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export const AlertSvgComponent: React.FC<svgComponentTypes> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="24" height="24" fill="none" stroke="none" />
      <path
        d="M13.375,18.891a.574.574,0,0,0-.563.452,1.111,1.111,0,0,1-.222.483.838.838,0,0,1-.714.262.852.852,0,0,1-.714-.262,1.111,1.111,0,0,1-.222-.483.574.574,0,0,0-.563-.452h0a.578.578,0,0,0-.563.7,1.981,1.981,0,0,0,2.061,1.645A1.977,1.977,0,0,0,13.938,19.6a.58.58,0,0,0-.563-.7Z"
        transform="translate(-4.698 -3.24)"
      />
      <path
        d="M18.717,15.993c-.693-.916-2.056-1.453-2.056-5.556,0-4.211-1.854-5.9-3.581-6.31-.162-.041-.279-.095-.279-.266V3.731a1.105,1.105,0,0,0-1.08-1.11h-.027a1.105,1.105,0,0,0-1.08,1.11v.131c0,.167-.117.226-.279.266-1.732.411-3.581,2.1-3.581,6.31,0,4.1-1.363,4.635-2.056,5.556a.9.9,0,0,0,.715,1.435h12.6A.9.9,0,0,0,18.717,15.993Zm-1.755.262H6.471a.2.2,0,0,1-.148-.329,5.47,5.47,0,0,0,.945-1.507,10.257,10.257,0,0,0,.643-3.981,6.939,6.939,0,0,1,.94-3.918,2.887,2.887,0,0,1,1.741-1.246,1.575,1.575,0,0,0,.837-.474.355.355,0,0,1,.535-.009,1.629,1.629,0,0,0,.846.483A2.886,2.886,0,0,1,14.551,6.52a6.939,6.939,0,0,1,.94,3.918,10.257,10.257,0,0,0,.643,3.981,5.532,5.532,0,0,0,.967,1.53A.187.187,0,0,1,16.963,16.255Z"
        transform="translate(-4.517 -2.62)"
      />
    </svg>
  );
};

export const InferenceSvgComponent: React.FC<svgComponentTypes> = ({
  color
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21.671,7,16,11.051,21.671,15.1Z" />
      <path d="M2.644,5h9.041a1.632,1.632,0,0,1,1.644,1.62v8.1a1.632,1.632,0,0,1-1.644,1.62H2.644A1.632,1.632,0,0,1,1,14.722V6.62A1.632,1.632,0,0,1,2.644,5Z" />
    </svg>
  );
};

export const ProductivityBarSvgComponent: React.FC<svgComponentTypes> = ({
  color
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="none" strokeWidth="1" />
      <g>
        <path
          d="M0,8.143V0"
          transform="translate(11.786 13.409) rotate(90)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M0,12.768V0"
          transform="translate(16.488 8.593) rotate(90)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M0,4.886V0"
          transform="translate(8.547 3.778) rotate(90)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M12,22V4"
          transform="translate(-12 -4)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M0,18V0"
          transform="translate(18 18) rotate(90)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const PieSvgComponent: React.FC<svgComponentTypes> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="none" strokeWidth="1" />
      <g>
        <path
          d="M19.209,14.527A8.958,8.958,0,1,1,7.375,2.83"
          transform="translate(-1.999 -2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M22,12A10,10,0,0,0,12,2V12Z"
          transform="translate(-4 -2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export const GanttSvgComponent: React.FC<svgComponentTypes> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_14321"
        width="24"
        height="24"
        transform="translate(0 23.907) rotate(-90)"
        fill="none"
        strokeWidth="1"
      />
      <g id="Mask_Group_272" transform="translate(0 0.093)">
        <g id="Icon_feather-bar-chart-2" transform="translate(3 3)">
          <path
            id="Path_64147"
            d="M0,2.209V0"
            transform="translate(15.106 14.409) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64148"
            d="M0,4.344V0"
            transform="translate(13.344 12.593) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64159"
            d="M0,4.344V0"
            transform="translate(10.344 7.15) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64160"
            d="M0,4.344V0"
            transform="translate(7.01 5.335) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64161"
            d="M0,8.218V0"
            transform="translate(12.046 3.335) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64149"
            d="M0,8.188V0"
            transform="translate(11.547 10.778) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64158"
            d="M0,6.716V0"
            transform="translate(14.653 8.963) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64154"
            d="M12,22V4"
            transform="translate(-12 -4)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_64155"
            d="M0,18V0"
            transform="translate(18 18) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export const BarAndLineGraphSvgComponent: React.FC<svgComponentTypes> = ({
  color
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="53"
      height="24"
      viewBox="0 0 53 24"
    >
      <rect
        id="Rectangle_14321"
        width="24"
        height="24"
        transform="translate(0 24.146) rotate(-90)"
        fill="none"
        strokeWidth="1"
      />
      <rect
        id="Rectangle_14343"
        width="24"
        height="24"
        transform="translate(0 23.815) rotate(-90)"
        fill="none"
        strokeWidth="1"
      />
      <g id="Group_57637" transform="translate(-16289 3147)">
        <g id="Mask_Group_272" transform="translate(16289 -3147.146)">
          <g id="Icon_feather-bar-chart-2" transform="translate(3 3)">
            <path
              id="Path_64147"
              d="M18,18.143V10"
              transform="translate(-4.119 -3.127)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_64148"
              d="M12,16.768V4"
              transform="translate(-2.935 -1.829)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_64149"
              d="M6,18.886V14"
              transform="translate(-1.75 -3.889)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_64154"
              d="M12,22V4"
              transform="translate(-12 -4)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_64155"
              d="M0,18V0"
              transform="translate(18 18) rotate(90)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </g>
        <g id="Mask_Group_285" transform="translate(16318 -3146.815)">
          <g id="Icon_feather-bar-chart-2-2" transform="translate(3 3)">
            <path
              id="Path_64193"
              d="M0,6.742V0"
              transform="translate(16.317 4.914) rotate(34)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_64194"
              d="M0,6.678V0"
              transform="matrix(-0.777, -0.629, 0.629, -0.777, 3.424, 13.098)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_64195"
              d="M0,5.523V0"
              transform="matrix(0.454, -0.891, 0.891, 0.454, 7.626, 7.908)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_64196"
              d="M12,22V4"
              transform="translate(-12 -4)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_64197"
              d="M0,18V0"
              transform="translate(18 18) rotate(90)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const HeatMapSvgComponent: React.FC<svgComponentTypes> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14325"
            // dataName="Rectangle 14325"
            width="24"
            height="24"
            transform="translate(-2786 -3395)"
            fill={color}
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_277"
        // dataName="Mask Group 277"
        transform="translate(2786 3395)"
        clipPath="url(#clip-path)"
      >
        <g
          id="Group_57587"
          //   dataName="Group 57587"
          transform="translate(-504 876.786)"
        >
          <path
            id="Path_64154"
            // dataName="Path 64154"
            d="M12,20V4"
            transform="translate(-2291 -4271.786)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_64155"
            // dataName="Path 64155"
            d="M0,18V0"
            transform="translate(-2261 -4251.786) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_64166"
            // dataName="Path 64166"
            d="M12,4V20"
            transform="translate(-2273 -4271.786)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_64167"
            // dataName="Path 64167"
            d="M0,18V0"
            transform="translate(-2279 -4267.786) rotate(-90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_64168"
            // dataName="Path 64168"
            d="M0,18V0"
            transform="translate(-2279 -4263.787) rotate(-90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64169"
            // dataName="Path 64169"
            d="M0,18V0"
            transform="translate(-2279 -4259.786) rotate(-90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64170"
            // dataName="Path 64170"
            d="M0,18V0"
            transform="translate(-2279 -4255.786) rotate(-90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64171"
            // dataName="Path 64171"
            d="M0,16V0"
            transform="translate(-2266 -4267.786)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64172"
            // dataName="Path 64172"
            d="M0,16V0"
            transform="translate(-2270 -4267.786)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_64173"
            // dataName="Path 64173"
            d="M0,16V0"
            transform="translate(-2274 -4267.786)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export const BoxPloySvgComponent: React.FC<svgComponentTypes> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M17,17.36H.834a.194.194,0,0,1-.194-.194V1h.388V16.972H17ZM7.839,15.2H3.528v-.388H5.49V13.049H3.528a.194.194,0,0,1-.194-.194V6.389a.194.194,0,0,1,.194-.194H5.49V4.427H3.528V4.039H7.839v.388H5.878V6.195H7.839a.194.194,0,0,1,.194.194v6.466a.194.194,0,0,1-.194.194H5.878v1.767H7.839ZM3.722,12.661H7.645V9.816H3.722Zm0-3.233H7.645V6.583H3.722Zm11.661,3.621H11.072v-.388h1.961V10.894H11.072a.194.194,0,0,1-.194-.194V4.233a.194.194,0,0,1,.194-.194h1.961V2.272H11.072V1.884h4.311v.388H13.422V4.039h1.961a.194.194,0,0,1,.194.194V10.7a.194.194,0,0,1-.194.194H13.422v1.767h1.961Zm-4.117-2.543h3.922V7.66H11.266Zm0-3.233h3.922V4.427H11.266Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <rect width="18" height="18" fill="none" />
    </svg>
  );
};

export const LineGraphSvgComponent: React.FC<svgComponentTypes> = ({
  color
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
      <defs>
        <clipPath id="a">
          <path
            fill={color}
            stroke="#707070"
            d="M0 23.815v-24h24v24z"
            data-name="Rectangle 14321"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#a)"
        data-name="Mask Group 274"
        transform="translate(0 .185)"
      >
        <g
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          data-name="Icon feather-bar-chart-2"
        >
          <path d="m15.547 13.503 3.77-5.589" data-name="Path 64147" />
          <path
            strokeWidth={1.99936}
            d="m10.624 10.91-4.2 5.188"
            data-name="Path 64148"
          />
          <path d="m15.547 13.415-4.921-2.507" data-name="Path 64149" />
          <path d="M3 21V3" data-name="Path 64154" />
          <path d="M3 21h18" data-name="Path 64155" />
        </g>
      </g>
    </svg>
  );
};

export const CloseArrow: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="12"
    viewBox="0 0 6 12"
    // style={{ border: "1px solid red" }}
  >
    <path
      id="Icon_ionic-md-arrow-dropleft"
      data-name="Icon ionic-md-arrow-dropleft"
      d="M9,6l6,6L9,18Z"
      transform="translate(-9 -6)"
      fill="white"
    />
  </svg>
);
