import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addCameras, requestVideoUploadUrl, updateCamera } from 'api/sdk';
import { ConfigSchema, VideoMetadata, VideoTrimSchema } from 'schemas';
import { colors, getRandomColor, getVideoDuration, getVideoMeta } from 'utils';
import { updateVideoPath as updateVideoPathApi } from 'api/sdk';

import type { RootState } from './../store';
import { ConfigState, ROI } from './schema';
import { convertServerErrors } from 'utils/form';

const initialState: ConfigState = { analytics: [] };

export const uploadVideos = createAsyncThunk(
  '/training/add_cameras',
  async (
    payload: {
      configuration_id: string;
      files: FileList;
      showDuplicateError: any;
    },
    { dispatch }
  ) => {
    const { configuration_id, files, showDuplicateError } = payload;
    const filesList = Array.from(payload.files);
    const res = await addCameras(
      filesList.map(file => file.name),
      {
        config_id: configuration_id,
        number_of_camera: files.length
      }
    )
      .then(res => {
        const cameras = res.data;

        const total = files.length;

        for (let i = 0; i <= total; i++) {}

        filesList.forEach((file, idx) => {
          const cam = cameras[idx];

          dispatch(
            updateCameraStatus({
              cameraID: cam.id,
              status: 'uploading'
            })
          );
          requestVideoUploadUrl({
            camera_id: cameras[idx].id,
            config_id: configuration_id,
            filename: file.name,
            type: 'video'
          })
            .then(res => {
              const {
                data: { file_name, file_type, path, signed_url, url }
              } = res;

              getVideoMeta(url).then(metadata => {
                dispatch(
                  updateVideoData({
                    cameraId: cam.id,
                    video_metadata: {
                      duration: parseInt(String(metadata.duration), 10),
                      resolution: [metadata.videoWidth, metadata.videoHeight]
                    },
                    filename: file_name,
                    url: url
                  })
                );
              });

              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signed_url, true);
              xhr.setRequestHeader('Content-Type', file_type);

              xhr.upload.onprogress = e => {
                const percentCompleted = Math.round((e.loaded * 100) / e.total);
                dispatch(
                  updateCameraStatus({
                    cameraID: cam.id,
                    status: 'uploading',
                    progress: percentCompleted
                  })
                );
              };

              xhr.onload = () => {
                if (xhr.status === 200) {
                  dispatch(
                    updateCameraStatus({
                      cameraID: cam.id,
                      status: 'uploaded',
                      video_path: path
                    })
                  );
                  dispatch(
                    videoPath({
                      cameraId: cam.id,
                      path,
                      url,
                      status: 'uploaded'
                    })
                  );
                  getVideoMeta(url).then(metadata => {
                    dispatch(
                      updateVideoData({
                        cameraId: cam.id,
                        video_metadata: {
                          duration: parseInt(String(metadata.duration), 10),
                          resolution: [
                            metadata.videoWidth,
                            metadata.videoHeight
                          ]
                        },
                        filename: file_name,
                        url: url
                      })
                    );

                    updateVideoPathApi(
                      {
                        video_path: path,
                        metadata: {
                          duration: parseInt(String(metadata.duration), 10),
                          resolution: [
                            metadata.videoWidth,
                            metadata.videoHeight
                          ]
                        },
                        filename: file_name
                      },
                      {
                        camera_id: cam.id,
                        config_id: configuration_id
                      }
                    );
                  });

                  // call another api to update the video status
                } else {
                  dispatch(
                    updateCameraStatus({
                      cameraID: cam.id,
                      status: 'failed'
                    })
                  );
                }
              };

              xhr.send(file);
            })
            .catch(err => {
              dispatch(
                updateCameraStatus({
                  cameraID: cam.id,
                  status: 'failed'
                })
              );
            });
        });

        dispatch(
          addCamera(
            res.data.map(cam => {
              return {
                cameraId: cam.id,
                cameraName: cam.name,
                metadata: cam.video_metadata,
                created_at: cam.created_at
              };
            })
          )
        );
      })
      .catch((err: any) => {
        console.log('err', err);
        showDuplicateError(err?.response?.data?.detail[0]['message']);
      });
    console.log('res', res);
  }
);

export const configSlice = createSlice({
  name: 'configV2',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<ConfigState>) => {
      return action.payload;
    },

    updateCameraStatus: (
      state,
      action: PayloadAction<{
        cameraID: string;
        status: 'uploading' | 'uploaded' | 'failed' | 'idle';
        progress?: number;
        video_path?: string;
      }>
    ) => {
      const { cameraID, status } = action.payload;
      const cam = state.cameras?.find(cam => cam.id === cameraID);
      if (!cam) return;
      cam.status = status;

      if (status === 'uploaded') {
        cam.upload_progress = 100;
      } else if (status === 'uploading') {
        cam.upload_progress = action.payload.progress;
      } else {
        cam.upload_progress = 0;
      }
    },

    setConfigFromApiResponse: (
      state,
      action: PayloadAction<{
        config: ConfigSchema;
      }>
    ) => {
      const colorValues = Object.values(colors);
      const { config } = action.payload;
      state.id = config.id;
      state.name = config.name;
      state.scene = config.scene;
      state.analytics = config.analytics;
      state.cameras = config.cameras.map(camera => ({
        id: camera.id,
        name: camera.name,
        crops: camera.crops,
        created_at: camera.created_at,
        roi: camera.roi.map((roi, idx) => {
          return {
            points: roi,
            isVisible: true,
            isLocked: true,
            isEditing: false,
            name: `ROI ${idx + 1}`,
            color:
              colorValues.length > idx
                ? `${colorValues[idx]}`
                : getRandomColor(),
            id: `${idx}`
          };
        }),
        video_path: camera.lowres_path,
        video_url: camera.video_url,
        lowres_url: camera.lowres_url,
        video_metadata: camera.video_metadata,
        boundingbox: [],
        clips: [],
        status: camera.video_path ? 'uploaded' : 'failed'
      }));

      if (config.cameras.length > 0) {
        state.currentCameraId = config.cameras[0].id;
      }
    },

    updateVideoData: (
      state,
      action: PayloadAction<{
        cameraId: string;
        video_metadata: VideoMetadata;
        filename: string;
        url: string;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.video_metadata = action.payload.video_metadata;
          camera.name = action.payload.filename;
          camera.video_url = action.payload.url;
        }
      }
    },

    // Camera related actions start
    updateVideoPath: (
      state,
      action: PayloadAction<{
        cameraId: string;
        video_url: string;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.video_url = action.payload.video_url;
        }
      }
    },

    setCurrentCameraId: (state, action: PayloadAction<string>) => {
      state.currentCameraId = action.payload;
    },

    addCamera: (
      state,
      action: PayloadAction<
        {
          cameraId: string;
          cameraName: string;
          metadata: VideoMetadata | null;
          created_at: string;
        }[]
      >
    ) => {
      // state.cameras = [
      //   ...(state.cameras || []),
      //   ...action.payload.map(camera => ({
      //     id: camera.cameraId,
      //     name: camera.cameraName,
      //     crops: [],
      //     roi: [],
      //     video_metadata: camera.metadata
      //   }))
      // ];

      action.payload.forEach(camera => {
        state.cameras &&
          state.cameras.push({
            id: camera.cameraId,
            name: camera.cameraName,
            crops: [],
            roi: [],
            boundingbox: [],
            clips: [],
            video_metadata: camera.metadata,
            created_at: camera.created_at
          });
      });
    },

    deleteCamera: (state, action: PayloadAction<string>) => {
      if (state.cameras) {
        state.cameras = state.cameras.filter(
          camera => camera.id !== action.payload
        );
      }
    },

    updateCameraName: (
      state,
      action: PayloadAction<{
        cameraId: string;
        name: string;
        roi?: any;
        crops?: any;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.name = action.payload.name;
          if (action.payload.roi !== undefined) {
            camera.roi = action.payload.roi;
          }
          if (action.payload.crops !== undefined) {
            camera.crops = action.payload.crops;
          }
        }
      }
    },

    // Camera related actions end
    setScene: (state, action: PayloadAction<string>) => {
      state.scene = action.payload;
    },

    // ROI related actions start
    addRoi: (
      state,
      action: PayloadAction<{
        cameraId: string;
        roi: ROI;
        idx: number;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        const { idx } = action.payload;
        if (camera) {
          if (idx === -1) {
            camera.roi.push(action.payload.roi);
          } else {
            camera.roi[idx] = action.payload.roi;
          }
        }
      }
    },
    addBondingBoxRoiItem: (
      state,
      action: PayloadAction<{
        cameraId: string;
        images: string;
        index?: number;
        id: string | void; // Add the 'id' property to the payload type
        frames: string;
        frame_number: number;
        bounding_box: number[] | null;
        frame_width: number;
        frame_height: number;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera && action.payload.index !== undefined) {
          const boundingbox = camera.boundingbox[action.payload.index];
          boundingbox.images = action.payload.images;
          boundingbox.frames = action.payload.frames;
          boundingbox.frame_number = action.payload.frame_number;
          boundingbox.bounding_box = action.payload.bounding_box ?? [];
          boundingbox.frame_width = action.payload.frame_width;
          boundingbox.frame_height = action.payload.frame_height;
        } else {
          camera &&
            camera.boundingbox.push({
              id: action.payload.id ?? '', // Assign the 'id' from the payload directly
              images: action.payload.images,
              frames: action.payload.frames,
              frame_number: action.payload.frame_number,
              bounding_box: action.payload.bounding_box ?? [],
              frame_width: action.payload.frame_width,
              frame_height: action.payload.frame_height
            });
        }
      }
    },

    deleteBoundingBoxItem: (
      state,
      action: PayloadAction<{
        cameraId: string;
        idx: number;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.boundingbox.splice(action.payload.idx, 1);
        }
      }
    },

    deleteAllBoundingBoxItems: (
      state,
      action: PayloadAction<{
        cameraId: string;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.boundingbox = [];
        }
      }
    },

    deleteRoi: (
      state,
      action: PayloadAction<{
        cameraId: string;
        roiId: string;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.roi = camera.roi.filter(
            roi => roi.id !== action.payload.roiId
          );
        }
      }
    },
    // ROI related actions end
    deleteAllClipItems: (
      state,
      action: PayloadAction<{
        cameraId: string;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.clips = [];
        }
      }
    },

    // Crop related actions start
    addCropItem: (
      state,
      action: PayloadAction<{
        cameraId: string;
        crop: VideoTrimSchema;
        idx: number;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          const { idx } = action.payload;
          if (idx === -1) {
            camera.crops.push(action.payload.crop);
          } else {
            camera.crops[idx] = action.payload.crop;
          }
        }
      }
    },
    applyToAll: (
      state,
      action: PayloadAction<{
        cameraId: string;
        cropIndexes: number[];
        roiIndexes: number[];
        checkedCameras: string[];
      }>
    ) => {
      if (state?.cameras) {
        const currentCamera = state?.cameras?.find(
          camera => camera?.id === action?.payload?.cameraId
        );

        if (currentCamera) {
          const cropItems = action?.payload?.cropIndexes?.map(
            idx => currentCamera?.crops[idx]
          );
          const roiItems = action?.payload?.roiIndexes?.map(
            idx => currentCamera?.roi[idx]
          );

          state?.cameras?.forEach(camera => {
            if (
              camera?.id !== currentCamera?.id &&
              action?.payload?.checkedCameras?.includes(camera?.id)
            ) {
              // Append unique crops
              cropItems?.forEach(cropItem => {
                if (
                  !camera.crops.some(existingCrop => existingCrop === cropItem)
                ) {
                  camera?.crops?.push(cropItem);
                }
              });

              // Append any new ROIs that don't already exist
              roiItems?.forEach(newRoi => {
                camera?.roi?.push(newRoi);
              });
            }
          });
        }
      }
    },

    deleteCropItem: (
      state,
      action: PayloadAction<{
        cameraId: string;
        idx: number;
      }>
    ) => {
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );
        if (camera) {
          camera.crops = camera.crops.filter(
            (_, index) => index !== action.payload.idx
          );
        }
      }
    },
    // Crop related actions end
    videoPath: (
      state,
      action: PayloadAction<{
        cameraId: string;
        path: string;
        url: string;
        status: 'uploading' | 'uploaded' | 'failed' | 'idle';
      }>
    ) => {
      const { path, url } = action.payload;
      if (state.cameras) {
        const camera = state.cameras.find(
          camera => camera.id === action.payload.cameraId
        );

        if (camera) {
          camera.video_path = path;
          camera.video_url = path;
        }
      }
    }
  }
});

export const {
  setConfig,
  setScene,
  setCurrentCameraId,
  setConfigFromApiResponse,
  updateVideoPath,
  deleteCamera,
  addCamera,
  addRoi,
  deleteRoi,
  addCropItem,
  applyToAll,
  deleteCropItem,
  videoPath,
  updateCameraName,
  addBondingBoxRoiItem,
  deleteBoundingBoxItem,
  deleteAllBoundingBoxItems,
  deleteAllClipItems,
  updateCameraStatus,
  updateVideoData
} = configSlice.actions;

export const updateCropsApiMiddleware =
  (store: any) => (next: any) => (action: any) => {
    const result = next(action);
    const state: RootState = store.getState();

    if (action?.type === applyToAll?.type) {
      // Handle the applyToAll action type
      const cameras = state?.configV2?.cameras;

      if (state?.configV2.id) {
        const updates =
          cameras?.map(camera => ({
            id: camera?.id || '',
            roi:
              camera?.roi?.map(roi =>
                roi?.points?.map(p => ({
                  x: parseInt(p?.x?.toString()),
                  y: parseInt(p?.y?.toString())
                }))
              ) || null,
            crops:
              camera?.crops?.map(crop => ({
                start: parseInt(crop?.start?.toString()),
                end: parseInt(crop?.end?.toString())
              })) || null,
            name: null,
            video_duration: null,
            video_path: null
          })) || [];

        updateCamera(state.configV2.id, updates);
      }
    } else if (
      action.type === addRoi.type ||
      action.type === deleteRoi.type ||
      action.type === addCropItem.type ||
      action.type === deleteCropItem.type
    ) {
      // Handle other specific action types
      const camera = selectCurrentCamera(state);

      if (state?.configV2?.id && camera) {
        const updates = [
          {
            id: camera.id,
            roi:
              camera?.roi?.map(roi =>
                roi?.points?.map(p => ({
                  x: parseInt(p?.x?.toString()),
                  y: parseInt(p?.y?.toString())
                }))
              ) || null,
            crops:
              camera?.crops?.map(crop => ({
                start: parseInt(crop?.start?.toString()),
                end: parseInt(crop?.end?.toString())
              })) || null,
            name: camera.name || null,
            video_duration: camera.video_duration || null,
            video_path: camera.video_path || null
          }
        ];

        updateCamera(state.configV2.id, updates);
      }
    } else if (action.type === videoPath.type) {
    }
    return result;
  };

export const selectConfig = (state: RootState) => state.configV2;

export const selectCameras = (state: RootState) => state.configV2.cameras;

export const selectCurrentCamera = (state: RootState) => {
  if (state.configV2.cameras) {
    return state.configV2.cameras.find(
      camera => camera.id === state.configV2.currentCameraId
    );
  } else {
    return undefined;
  }
};

export default configSlice.reducer;
