// Dependency imports
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// Local imports
import { useListCameraDetails } from 'api/sdk';
import SearchSelect from 'components/SearchSelect';
import SelectConfig from '../../assets/images/selectConfig.svg';
import { tempNotification } from './tempData';

const IpcamInference = () => {
  const [camera, setCamera] = useState('Select Camera');
  const { data } = useListCameraDetails();

  return (
    <Stack
      width="100%"
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography sx={{ fontSize: 22, fontWeight: 500 }}>
          Live Inference: Ip Cam
        </Typography>
        <SearchSelect
          data={[
            ...(data?.data.map((camera: any) => ({
              label: camera.name,
              value: camera.id
            })) || [])
          ]}
          onChange={(value: string) => setCamera(value)}
          label="Select Camera"
          selectValue={camera}
        />
      </Stack>
      {/* {camera === 'Select Camera' ? (
        <Stack
          sx={{ height: '650px' }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <img
            src={SelectConfig}
            alt="select_camera"
            style={{ width: '260px' }}
          />
          <Typography sx={{ fontSize: '24px', color: '#707070' }}>
            Select camera to start inference
          </Typography>
        </Stack>
      ) : (
        <iframe
          src={`https://wfmstage-api.gvap.dimaagai.net/configuration/cameras/2b201133-76ba-4600-99ff-d8baa07510b2/stream`}
          style={{ width: '80%', height: '600px' }}
        />
      )} */}
      <img
        src={`https://wfmstage-api.gvap.dimaagai.net/configuration/cameras/2b201133-76ba-4600-99ff-d8baa07510b2/stream`}
        style={{ width: '640px', height: '480px' }}
      />
    </Stack>
  );
};

export default IpcamInference;
