/* eslint-disable  */
import throughPutImg from '../../components/icons/throughput.svg';
import pieChartIMage from '../../components/icons/pieChart.svg';
import bargraphImage from '../../components/icons/BarGraph.svg';
import progressBarImage from '../../components/icons/progressBar.svg';
import workFlowManagementImage from '../../components/icons/workFlowManagement.svg';
import alertsImage from '../../components/icons/alert.svg';

import floorPlanImage from '../../components/icons/floorPlan.svg';
import liveInferenceImage from '../../components/icons/liveInference.svg';
import importVideoImage from '../../components/icons/importVideo.svg';
import startLiveImage from '../../components/icons/startLive.svg';
import heatMap from '../../components/icons/Heatmap.svg';
import boxPlot from '../../components/icons/BoxPlot.svg';
import inventoryMonitor from '../../components/icons/InventoryMonitor.svg';

import { WidgetCard, Widget } from './widget';
import React from 'react';

interface ComponentDash {
  description: string;
  onDelete: any;
}

export const ImportLiveStream: React.FC<ComponentDash> = ({
  description,
  onDelete
}) => {
  return (
    <WidgetCard>
      <div
        style={{
          width: '90%',
          textAlign: 'center',
          border: '1.5px solid black',
          fontFamily: 'sans-serif',
          fontSize: '1.5vh',
          padding: '6px',
          borderRadius: '4px',
          margin: '12px',
          boxShadow: '0px 2px 5px grey',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ marginRight: '10px' }}>Import Video</div>{' '}
        <img src={importVideoImage} />
      </div>
      <div
        style={{
          width: '90%',
          textAlign: 'center',
          border: '1.5px solid black',
          fontFamily: 'sans-serif',
          fontSize: '1.5vh',
          padding: '6px',
          borderRadius: '4px',
          margin: '12px',
          boxShadow: '0px 2px 5px grey',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ marginRight: '10px' }}>Start Live</div>
        <img src={startLiveImage} />
      </div>
    </WidgetCard>
  );
};

export const FloorPlan: React.FC<ComponentDash> = ({
  description,
  onDelete
}) => {
  return (
    <Widget
      deletable={true}
      onDelete={onDelete}
      image={floorPlanImage}
      title={'Floor Plan showing camera positions'}
      description={
        description ??
        'This gives an overview of the different camera positions across floor plan such that user can understand which part of the floor he is viewing.'
      }
    ></Widget>
  );
};

export const ProgressBar: React.FC<ComponentDash> = ({
  description,
  onDelete
}) => {
  return (
    <Widget
      image={progressBarImage}
      title={'Progress Bar'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Realtime counting with target number and progress bar to reach target.'
      }
    ></Widget>
  );
};

export const LiveInference: React.FC<ComponentDash> = ({
  description,
  onDelete
}) => {
  return (
    <Widget
      image={liveInferenceImage}
      title={'Live Inference'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Real time inference on live video stream with bounding box and label.'
      }
    ></Widget>
  );
};

export const Alerts: React.FC<ComponentDash> = ({ description, onDelete }) => {
  return (
    <Widget
      image={alertsImage}
      title={'Alerts'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ?? (
          <>
            <ol
              style={{
                textAlign: 'left',
                lineHeight: '1.5',
                fontSize: '1.2vh',
                paddingLeft: '10px'
              }}
            >
              <li>
                {' '}
                Shows workflow violation with time stamp and violation reson.
              </li>
              <li>
                {' '}
                Alert dashboard to show which safety gear is missing with the
                event time stamp.
              </li>
              <li>
                {' '}
                Give real time counting number of the inventory for each raw
                material / parts.
              </li>
              <li>
                {' '}
                Trigger alert when low inventory happen (less than pre-defined
                number).
              </li>
              <li>
                {' '}
                Give detail information about the violatin (location, time,
                violation, type).
              </li>
            </ol>
          </>
        )
      }
    ></Widget>
  );
};

export const ProductThroughPut: React.FC<ComponentDash> = ({
  description,
  onDelete
}) => {
  return (
    <Widget
      image={throughPutImg}
      title={'Product Throughput'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Hourly (or user defined interval) throughput plot along the time (bar and line combo plot), able to switch between different products.'
      }
    ></Widget>
  );
};

export const EmployeeProductivityBar: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={bargraphImage}
      title={'Employee Productivity'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ?? 'Bar plot time per workstation/camera, per person.'
      }
    ></Widget>
  );
};

export const EmployeeProductivityPie: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={pieChartIMage}
      title={'Employee Productivity'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Pie chart of each activity per workstation/camera, per person.'
      }
    ></Widget>
  );
};

export const EmployeeProductivityGantt: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={workFlowManagementImage}
      title={'Employee Productivity'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Real time all activities monitor with gantt chart plot along time.'
      }
    ></Widget>
  );
};

export const WorkFlowManagement: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={workFlowManagementImage}
      title={'Workflow Management'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Real time all activities monitor with gantt chart plot along time.'
      }
    ></Widget>
  );
};

export const WorkFLowPieChart: React.FC<ComponentDash> = ({
  description,
  onDelete
}) => {
  return (
    <Widget
      image={pieChartIMage}
      title={'Workflow Management'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Pie chart of each activity per workstation/camera, per person.'
      }
    ></Widget>
  );
};

export const ProductThroughPutHeatMap: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={heatMap}
      title={'Product Throughput'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ?? 'HeatMap to show detail and highlighted low throughput.'
      }
    ></Widget>
  );
};

export const ProductCycleGanttChart: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={workFlowManagementImage}
      title={'Product Throughput'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Use Gantt chart to show the detailed product entry-exit time at each workstation or stage.'
      }
    ></Widget>
  );
};

export const ProductCycleBoxPlot: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={boxPlot}
      title={'Product Throughput'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Use box plot to show the detailed product entry-exit time at each workstation or stage.'
      }
    ></Widget>
  );
};

export const SafetyGearMonitor: React.FC<ComponentDash> = ({
  description,
  onDelete
}) => {
  return (
    <Widget
      image={workFlowManagementImage}
      title={'Safety Gear Monitor'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ?? 'Gantt chart (X: time in one day, Y: camera).'
      }
    ></Widget>
  );
};

export const WorkFlowManagementPieChart: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={pieChartIMage}
      title={'Workflow Management'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Pie chart of each activity per workstation/camera, per person.'
      }
    ></Widget>
  );
};

export const WorkFLowManagementBarGraph: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={bargraphImage}
      title={'Workflow Management'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Option to change to bar plot if activity number is many.'
      }
    ></Widget>
  );
};

export const SafetyGearMonitor2: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={workFlowManagementImage}
      title={'Safety Gear Monitor'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ?? 'Gantt chart (X: time in one day, Y: camera).'
      }
    ></Widget>
  );
};

export const InventoryMonitor: React.FC<ComponentDash> = ({
  description,
  onDelete
}) => {
  return (
    <Widget
      image={inventoryMonitor}
      title={'Inventory Monitor'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ?? 'Line plot (X: time in one day, Y: inventory number).'
      }
    ></Widget>
  );
};

export const SecurityMonitor: React.FC<ComponentDash> = ({
  description,
  onDelete
}) => {
  return (
    <Widget
      image={workFlowManagementImage}
      title={'Security Monitor'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'X: time in one day, Y: camera and differentiate person or activity violation by different color.'
      }
    ></Widget>
  );
};

export const EmployeeProductivityLine: React.FC = ({
  description,
  onDelete
}: any) => {
  return (
    <Widget
      image={throughPutImg}
      title={'Employee Productivity'}
      deletable={true}
      onDelete={onDelete}
      description={
        description ??
        'Column plot along time per workstation/camera, per person.'
      }
    ></Widget>
  );
};

export const ProductThroughPutHistory: React.FC = ({ color }: any) => {
  return (
    <div
      style={{
        minWidth: '100px',
        padding: '0px 10px 0px 10px',
        minHeight: '170px',
        backgroundColor: color,
        display: 'flex',
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 5px grey',
        height: '100%'
      }}
    >
      <img src={throughPutImg} />
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        Product Throughput
      </div>
      <div
        style={{ fontSize: '0.9vh', fontFamily: 'sans-serif', color: 'grey' }}
      >
        Daily throughput plot along the date per workstation.
      </div>
    </div>
  );
};

export const WorkflowmanagementBar: React.FC = ({ color }: any) => {
  return (
    <div
      style={{
        minWidth: '100px',
        padding: '0px 10px 0px 10px',
        minHeight: '170px',
        backgroundColor: color,
        display: 'flex',
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 5px grey',
        height: '100%'
      }}
    >
      <img src={bargraphImage} />
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        Workflow management
      </div>
      <div
        style={{ fontSize: '0.9vh', fontFamily: 'sans-serif', color: 'grey' }}
      >
        Option to change the bar plot if activity number is many.
      </div>
    </div>
  );
};

export const SafetyGearMonitorHistory: React.FC = ({ color }: any) => {
  return (
    <div
      style={{
        minWidth: '100px',
        padding: '0px 10px 0px 10px',
        minHeight: '170px',
        backgroundColor: color,
        display: 'flex',
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 5px grey',
        height: '100%'
      }}
    >
      <img src={workFlowManagementImage} />
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        Safety Gear Monitor
      </div>
      <div
        style={{
          fontSize: '0.9vh',
          fontFamily: 'sans-serif',
          color: 'grey',
          textAlign: 'center'
        }}
      >
        Daily-based violation in line plot with ability to combine multiple
        camera in one plot.
      </div>
    </div>
  );
};

export const InventoryMonitorHistory: React.FC = ({ color }: any) => {
  return (
    <div
      style={{
        minWidth: '100px',
        padding: '0px 10px 0px 10px',
        minHeight: '170px',
        backgroundColor: color,
        display: 'flex',
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 5px grey',
        height: '100%'
      }}
    >
      <img src={boxPlot} />
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        Inventory Monitor
      </div>
      <div
        style={{
          fontSize: '0.9vh',
          fontFamily: 'sans-serif',
          color: 'grey',
          textAlign: 'center'
        }}
      >
        Daily-based violation in box plot(X: date, Y: inventory number).
      </div>
    </div>
  );
};

export const SecurityMonitorHistory: React.FC = ({ color }: any) => {
  return (
    <div
      style={{
        minWidth: '100px',
        padding: '0px 10px 0px 10px',
        minHeight: '170px',
        backgroundColor: color,
        display: 'flex',
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 5px grey',
        height: '100%'
      }}
    >
      <img src={inventoryMonitor} />
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        Security Monitor
      </div>
      <div
        style={{
          fontSize: '0.9vh',
          fontFamily: 'sans-serif',
          color: 'grey',
          textAlign: 'center'
        }}
      >
        X: time in one day, Y: camera and differentiate person or activity
        violation by different color.
      </div>
    </div>
  );
};
