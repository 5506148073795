/* eslint-disable  */
import React, { useState, MouseEvent, useEffect } from 'react';
import { Line, Circle, Group, KonvaNodeEvents } from 'react-konva';

import { minMax, Point, dragBoundFunc as dragBoundFuncUtil } from 'utils';

/**
 * Props for the PolygonAnnotation component.
 */
interface PolygonAnnotationProps {
  points: number[][];
  flattenedPoints: number[];
  isFinished: boolean;
  isEditing: boolean;
  color: string;
  scale: { x: number; y: number };
  handlePointDragMove: (newPos: Point) => void;
  handleGroupDragEnd: (e: any) => void;
  handleMouseOverStartPoint: KonvaNodeEvents['onMouseOver'];
  handleMouseOutStartPoint: KonvaNodeEvents['onMouseOut'];
}

/**
 * Component for rendering polygon annotations.
 */
const PolygonAnnotation: React.FC<PolygonAnnotationProps> = ({
  points,
  color,
  flattenedPoints,
  isFinished,
  isEditing,
  scale,
  handlePointDragMove,
  handleGroupDragEnd,
  handleMouseOverStartPoint,
  handleMouseOutStartPoint
}) => {
  const groupRef = React.useRef<any>();
  const vertexRadius = 6 / scale.x;

  const [stage, setStage] = useState<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  /**
   * Handles mouse over event on the annotation group.
   * Sets cursor to 'move' if annotation is finished.
   * @param e MouseEvent
   */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleGroupMouseOver = (e: any) => {
    if (!isFinished) return;
    if (!isEditing) return;
    e.target.getStage().container().style.cursor = 'move';
    setStage(e.target.getStage());
  };

  /**
   * Handles mouse out event on the annotation group.
   * Sets cursor to 'default'.
   * @param e MouseEvent
   */
  const handleGroupMouseOut = (e: any) => {
    if (!isEditing) return;
    e.target.getStage().container().style.cursor = 'default';
  };
  /* eslint-disable @typescript-eslint/no-explicit-any */

  const [minMaxX, setMinMaxX] = useState<number[]>([0, 0]); // min and max in x axis
  const [minMaxY, setMinMaxY] = useState<number[]>([0, 0]); // min and max in y axis

  /**
   * Handles drag start event on the annotation group.
   * Sets the minimum and maximum values of x and y coordinates.
   */
  const handleGroupDragStart = () => {
    if (!isEditing) return;
    const arrX = points?.map(p => p[0] * scale.x);
    const arrY = points?.map(p => p[1] * scale.y);
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
  };

  /**
   * Draggable bounds function for the annotation group.
   * Constrains the movement of the group within the stage boundaries.
   * @param pos Position of the group
   * @returns Adjusted position of the group within the stage
   */
  const groupDragBound = (pos: Point) => {
    if (!isEditing) return pos;
    if (!stage) return pos;
    let { x, y } = { x: pos.x, y: pos.y };
    const sw = stage.width();
    const sh = stage.height();
    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
    if (minMaxY[1] + y > sh) y = sh - minMaxY[1];
    if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
    return { x, y };
  };

  return (
    <Group
      name="polygon"
      ref={groupRef}
      draggable={isFinished && isEditing}
      onDragStart={handleGroupDragStart}
      onDragEnd={handleGroupDragEnd}
      dragBoundFunc={groupDragBound}
      onMouseOver={handleGroupMouseOver}
      onMouseOut={handleGroupMouseOut}
    >
      <Line
        points={flattenedPoints}
        stroke={color}
        strokeWidth={3}
        closed={isFinished}
        fill={isFinished ? `${color}99` : 'transparent'}
      />
      {points?.map((point, index) => {
        const x = point[0] - vertexRadius / 2;
        const y = point[1] - vertexRadius / 2;
        const startPointAttr =
          index === 0
            ? {
                hitStrokeWidth: 12,
                onMouseOver: handleMouseOverStartPoint,
                onMouseOut: handleMouseOutStartPoint
              }
            : null;
        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={vertexRadius}
            fill={color}
            stroke="#00F1FF"
            strokeWidth={2}
            draggable={isFinished && isEditing}
            onDragMove={(e: any) => handlePointDragMove(e)}
            dragBoundFunc={(pos: any) =>
              dragBoundFuncUtil(
                stage.width(),
                stage.height(),
                vertexRadius,
                pos
              )
            }
            {...startPointAttr}
          />
        );
      })}
    </Group>
  );
};

export default PolygonAnnotation;
